export default function (repository, filename) {
  repository.getReport().then((data) => {
    var fileURL = window.URL.createObjectURL(new Blob([data]));
    var fileLink = document.createElement("a");
    fileLink.href = fileURL;
    fileLink.setAttribute("download", filename + ".xlsx");
    document.body.appendChild(fileLink);
    fileLink.click();
  });
}
