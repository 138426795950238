var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"transparent",attrs:{"flat":""}},[_c('div',{staticClass:"card-wrapper"},[_c('v-card-title',{staticClass:"text-center"},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"d-none d-md-block"},[_c('span',{staticClass:"headline no-split-words"},[_vm._v(" "+_vm._s(_vm.$t(_vm.$route.meta.label))+" ")])])],1)],1),_c('v-card-text',[_c('v-form',{ref:"form"},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-row',[_c('v-text-field',{staticClass:"form-control",attrs:{"name":"currentPassword","prepend-inner-icon":"vpn_key","append-icon":_vm.hideCurrentPassword ? 'visibility' : 'visibility_off',"label":_vm.$t('account.currentPassword'),"rules":[
                  (v) => !!v || _vm.$t('account.passwordRequired'),
                  _vm.currentBetweenMinAndMax,
                ],"type":_vm.hideCurrentPassword ? 'password' : 'text',"required":""},on:{"click:append":() => (_vm.hideCurrentPassword = !_vm.hideCurrentPassword)},model:{value:(_vm.user.currentPassword),callback:function ($$v) {_vm.$set(_vm.user, "currentPassword", $$v)},expression:"user.currentPassword"}})],1),_c('v-row',{staticClass:"mt-12"},[_c('v-text-field',{staticClass:"form-control",attrs:{"name":"newPassword","prepend-inner-icon":"vpn_key","append-icon":_vm.hideNewPassword ? 'visibility' : 'visibility_off',"label":_vm.$t('account.newPassword'),"rules":[
                  (v) => !!v || _vm.$t('account.passwordRequired'),
                  _vm.newBetweenMinAndMax,
                ],"type":_vm.hideNewPassword ? 'password' : 'text',"required":""},on:{"click:append":() => (_vm.hideNewPassword = !_vm.hideNewPassword),"input":_vm.validatePasswordMatch},model:{value:(_vm.user.newPassword),callback:function ($$v) {_vm.$set(_vm.user, "newPassword", $$v)},expression:"user.newPassword"}})],1),_c('v-row',[_c('v-text-field',{ref:"newPassword2",staticClass:"form-control",attrs:{"name":"newPassword2","prepend-inner-icon":"vpn_key","append-icon":_vm.hideNewPassword2 ? 'visibility' : 'visibility_off',"label":_vm.$t('account.repeatPassword'),"rules":[
                  (v) => !!v || _vm.$t('account.passwordRequired'),
                  _vm.passwordConfirmationRule,
                ],"type":_vm.hideNewPassword2 ? 'password' : 'text',"required":""},on:{"click:append":() => (_vm.hideNewPassword2 = !_vm.hideNewPassword2),"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.submitNewPassword.apply(null, arguments)},"input":_vm.validatePasswordMatch},model:{value:(_vm.user.newPassword2),callback:function ($$v) {_vm.$set(_vm.user, "newPassword2", $$v)},expression:"user.newPassword2"}})],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-btn',{staticClass:"primary mt-4",attrs:{"loading":_vm.loading},on:{"click":_vm.submitNewPassword}},[_vm._v(" "+_vm._s(_vm.$t("account.resetPassword"))+" ")])],1)],1)],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }