<template>
  <v-card flat class="transparent">
    <v-card-title>
      <v-row align="center" justify="space-between" no-gutters>
        <v-col class="d-none d-md-block col-auto">
          <span class="headline no-split-words">
            {{ $t($route.meta.label) }}
          </span>
        </v-col>
        <v-col
          cols="12"
          order="2"
          order-md="1"
          class="d-md-inline-block text-right mt-4 mt-md-0 col-md"
        >
          <debounced-text-field
            v-model="search"
            append-icon="search"
            class="d-md-inline-block"
            dense
            hide-details
            :label="$t('search')"
            @input="redirect"
          ></debounced-text-field>
        </v-col>
        <v-col
          v-if="isAdmin || isEventManagement"
          cols="12"
          order="1"
          order-md="2"
          class="text-right col-md-auto"
        >
          <v-btn
            class="warning ml-2"
            @click="
              $router.push({
                name: 'Admin Evento List',
                query: { sort: 'fecha,desc' },
                backPrevious: true,
              })
            "
          >
            <v-icon class="material-icons details-close-button">edit</v-icon>
            <span class="d-none d-sm-block"> {{ $t("edit") }} </span>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <span v-if="!loading">
        <div v-if="eventos.length > 0">
          <v-card
            v-for="(item, idx) in eventos"
            :key="idx"
            class="my-4"
            @click="goToEventDetail(item)"
          >
            <div class="d-flex">
              <v-avatar v-if="item.imageUrl" class="ma-3" size="150" tile>
                <img :src="item.imageUrl" alt="" style="object-fit: contain" />
              </v-avatar>

              <div style="overflow: auto; width: 100%">
                <v-card-title>
                  {{ item.titulo }}
                </v-card-title>

                <v-card-subtitle>
                  <span v-if="item.lugar"> {{ item.lugar }} , </span>
                  <span v-if="item.fecha">
                    <!-- Remove hours if they are 00:00 -->
                    {{
                      formatDateWithHours(dateArrayToDate(item.fecha)).slice(
                        -5
                      ) === "00:00"
                        ? formatDateWithHours(
                            dateArrayToDate(item.fecha)
                          ).slice(0, -5)
                        : formatDateWithHours(dateArrayToDate(item.fecha))
                    }}
                  </span>
                </v-card-subtitle>

                <v-card-text>
                  <span v-html="item.descripcion" class="resume"></span>
                </v-card-text>

                <v-card-actions class="actions">
                  <v-btn
                    text
                    small
                    class="readMoreBtn"
                    @click.stop="goToEventDetail(item)"
                  >
                    {{ $t("readMore") }}
                  </v-btn>
                </v-card-actions>
              </div>
            </div>
          </v-card>
        </div>
        <div
          v-else
          class="text-h5"
          style="align-content: center; justify-content: center; display: flex"
        >
          {{ $t("t_evento.headers.no_eventos") }}
        </div>
      </span>
      <span v-else>
        <loading-page></loading-page>
      </span>
    </v-card-text>
  </v-card>
</template>

<script>
import LoadingPage from "@/components/loading-page/LoadingPage.vue";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import {
  dateArrayToDate,
  formatDateWithHours,
} from "@/common/conversion-utils";
import DebouncedTextField from "@/components/debouncing-inputs/DebouncedTextField.vue";
import { mapAuthGetter } from "@/common/mapAuthGetter";

const EventoEntityRepository = RepositoryFactory.get("EventoEntityRepository");

export default {
  name: "PublicEventoList",
  components: { DebouncedTextField, LoadingPage },
  data() {
    return {
      eventos: [],
      loading: false,
      search: null,
    };
  },
  watch: {
    "$route.name"(val) {
      this._fetchData();
    },
  },
  created() {
    this.search = this.$route.query.search ? this.$route.query.search : null;
    this._fetchData();
  },
  computed: {
    ...mapAuthGetter(["isAdmin", "isEventManagement"]),
  },
  methods: {
    formatDateWithHours,
    dateArrayToDate,
    _fetchData() {
      this.loading = true;
      const options = {
        params: {
          filters: "publico:false",
          search: this.search,
        },
      };
      const source =
        this.$route.name === "Partner Evento List"
          ? EventoEntityRepository.getAllSocios
          : EventoEntityRepository.getAllPublic;
      source(options)
        .then((res) => {
          const promises = res.content.map((item) =>
            this.getThumbnailUrl(item)
          );
          return Promise.all(promises).then(() => {
            this.eventos = res.content;
            this.eventos.forEach((el) => {
              this.clearHtml(el);
            });
          });
        })
        .finally(() => (this.loading = false));
    },
    goToEventDetail(item) {
      this.$router.push({
        name: "Public Evento Detail",
        params: { id: item.id, backPrevious: true },
      });
    },
    getThumbnailUrl(item) {
      if (!item.thumbnail) return Promise.resolve();

      return EventoEntityRepository.getThumbnail(item.id)
        .then((savedBlob) => {
          item.imageUrl = URL.createObjectURL(savedBlob);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    clearHtml(item) {
      // Delete images and tables for text only preview
      if (item.descripcion)
        item.descripcion = item.descripcion.replace(
          /<figure(.*?)\/figure>/g,
          ""
        );
    },
    redirect() {
      let query = JSON.parse(JSON.stringify(this.$route.query));
      query.search = this.search || undefined;
      if (JSON.stringify(this.$route.query) !== JSON.stringify(query)) {
        this.$router
          .replace({
            name: this.$route.name,
            query: query,
          })
          .then(() => this._fetchData());
      }
    },
  },
};
</script>
<style scoped>
.resume {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.actions {
  justify-content: end;
}
.readMoreBtn {
  text-transform: none;
}
</style>
