var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"transparent",attrs:{"flat":""}},[_c('div',{staticClass:"card-wrapper"},[_c('v-card-title',{staticClass:"pb-0 pt-0 pt-md-4 text-center"},[_c('v-row',{attrs:{"align":"center","no-gutters":""}},[_c('v-col',{staticClass:"d-none d-md-block"},[_c('span',{staticClass:"headline no-split-words"},[_vm._v(" "+_vm._s(_vm.$t(_vm.$route.meta.label))+" ")])])],1)],1),(!_vm.resolved)?_c('v-card-text',{staticClass:"mt-4"},[_c('v-form',{ref:"form"},[_c('v-row',{attrs:{"align":"center","justify":"center","dense":"","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-row',{attrs:{"justify":"center","dense":"","no-gutters":""}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("account.enterPassword"))+" ")])]),_c('v-row',[_c('v-text-field',{staticClass:"form-control mt-4",attrs:{"dense":"","name":"password","prepend-inner-icon":"vpn_key","append-icon":_vm.hidePassword1 ? 'visibility' : 'visibility_off',"label":_vm.$t('account.password.name'),"rules":[
                  (v) => !!v || _vm.$t('account.passwordRequired'),
                  _vm.betweenMinAndMax,
                ],"type":_vm.hidePassword1 ? 'password' : 'text',"required":""},on:{"click:append":() => (_vm.hidePassword1 = !_vm.hidePassword1),"input":_vm.validatePasswordMatch},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}})],1),_c('v-row',[_c('v-text-field',{ref:"password2",staticClass:"form-control",attrs:{"dense":"","name":"password2","prepend-inner-icon":"vpn_key","append-icon":_vm.hidePassword2 ? 'visibility' : 'visibility_off',"label":_vm.$t('account.repeatPassword'),"rules":[
                  (v) => !!v || _vm.$t('account.passwordRequired'),
                  _vm.passwordConfirmationRule,
                ],"type":_vm.hidePassword2 ? 'password' : 'text',"required":""},on:{"click:append":() => (_vm.hidePassword2 = !_vm.hidePassword2),"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.submitNewPassword.apply(null, arguments)},"input":_vm.validatePasswordMatch},model:{value:(_vm.user.password2),callback:function ($$v) {_vm.$set(_vm.user, "password2", $$v)},expression:"user.password2"}})],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-btn',{staticClass:"primary mt-4",on:{"click":_vm.submitNewPassword}},[_vm._v(" "+_vm._s(_vm.$t("account.resetPassword"))+" ")])],1)],1)],1)],1)],1):_vm._e(),(_vm.resolved)?_c('v-card-text',{staticClass:"mt-6"},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('div',[(_vm.success)?_c('v-icon',{attrs:{"color":"success","x-large":""}},[_vm._v("done")]):_vm._e(),(!_vm.success)?_c('v-icon',{attrs:{"color":"error","x-large":""}},[_vm._v("warning")]):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.response))])],1)]),(_vm.success)?_c('v-row',{staticClass:"mt-6",attrs:{"justify":"center"}},[_c('span',[_vm._v(_vm._s(_vm.$t("account.nowYouCan"))+" "),_c('router-link',{attrs:{"to":{ name: 'Login' }}},[_vm._v(_vm._s(_vm.$t("account.logIn")))]),_vm._v(" "+_vm._s(_vm.$t("account.normally"))+" ")],1)]):_vm._e()],1):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }