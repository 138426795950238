<template>
  <!-- Document dialog -->
  <v-dialog persistent v-model="documentDialog" max-width="1000">
    <div v-if="documentDialog">
      <v-card>
        <v-card-title>
          {{
            isEditingDocument
              ? $t("t_socio.actions.edit_document")
              : $t("t_socio.actions.add_document")
          }}
        </v-card-title>

        <v-card-text>
          <div v-if="currentDocument" class="mt-4">
            <v-form ref="documentForm">
              <v-row dense>
                <v-col>
                  <input
                    :ref="'documentFileLoader'"
                    type="file"
                    hidden
                    :accept="extensions.document"
                    required
                    @change="(e) => updateDocument(e)"
                  />
                  <v-btn
                    color="primary"
                    class="my-1"
                    small
                    @click="chooseDocument()"
                  >
                    <v-icon>mdi-upload</v-icon>
                    <span class="d-none d-sm-block">
                      {{ $t("select_file") }}
                    </span>
                  </v-btn>
                  <span v-if="currentDocument.fichero" class="ml-2">
                    <v-tooltip
                      v-if="
                        documentViewerTypes.includes(
                          currentDocument.fichero.type
                        )
                      "
                      top
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          @click="previewDocument(currentDocument.fichero)"
                          v-bind="attrs"
                          v-on="on"
                          ><v-icon color="primary">mdi-eye</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t("preview_file") }}</span>
                    </v-tooltip>
                    <span
                      v-if="
                        documentViewerTypes.includes(
                          currentDocument.fichero.type
                        )
                      "
                      class="link"
                      @click="previewDocument(currentDocument.fichero)"
                      >{{ currentDocument.fichero.name }}</span
                    >
                    <span v-else>{{ currentDocument.fichero.name }}</span>
                  </span>
                  <span v-else class="ml-2 error--text">
                    {{ $t("upload.file_required") }}
                  </span>
                </v-col>
              </v-row>
              <v-row dense class="mt-3">
                <v-col cols="6" md="4" class="pt-1">
                  <v-text-field
                    v-model="currentDocument.data.titulo"
                    dense
                    :label="$t('t_socio.documento.titulo')"
                    class="required"
                    :rules="[(v) => !!v || $t('error.required')]"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" md="4" class="pt-1">
                  <v-select
                    v-model="currentDocument.data.tipo"
                    class="ml-2 required"
                    dense
                    :items="tiposDocumento"
                    :item-text="(item) => item.nombre"
                    :label="$t('t_socio.documento.tipo')"
                    :loading="loadingDocumentTypes"
                    :rules="[(v) => !!v || $t('error.required')]"
                    return-object
                  >
                  </v-select>
                </v-col>

                <v-col cols="6" md="4" class="pt-1">
                  <date-and-hour-picker
                    class="ml-2 required"
                    :datePickerProp="{
                      data: currentDocument.data.fecha,
                      label: $t('t_socio.documento.fecha'),
                      mandatory: true,
                    }"
                    @update-time="setDocumentDate(...arguments)"
                    :rules="[(v) => !!v || $t('error.required')]"
                  ></date-and-hour-picker>
                </v-col>
              </v-row>
            </v-form>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="closeDocumentDialog()">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn color="success" @click="saveDocument()">
            <v-icon>save</v-icon>
            <span class="d-none d-sm-block">
              {{ $t("save") }}
            </span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
import extensions from "@/common/file-extensions";
import TipoDocumentoSocioEntityRepository from "@/repositories/entities/TipoDocumentoSocioEntityRepository";
import DateAndHourPicker from "@/components/calendar/DateAndHourPicker.vue";
import documentViewerTypes from "@/enumerates/documentviewertypes";

export default {
  name: "socioFormDocumentDialog",
  components: { DateAndHourPicker },
  props: {
    content: {
      type: Object,
      default: null,
    },
    isEditingDocument: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      documentDialog: false,
      currentDocument: null,
      tiposDocumento: null,
      loadingDocumentTypes: false,
      extensions,
      documentViewerTypes,
    };
  },
  created() {
    this.getDocumentTypes();
  },
  watch: {
    content(newValue) {
      if (newValue) {
        this.currentDocument = newValue;
        this.documentDialog = true;
      }
    },
  },
  methods: {
    getDocumentTypes() {
      this.loadingDocumentTypes = true;
      TipoDocumentoSocioEntityRepository.getAll()
        .then((res) => (this.tiposDocumento = res.content))
        .finally(() => (this.loadingDocumentTypes = false));
    },
    chooseDocument() {
      this.$refs["documentFileLoader"].click();
    },
    updateDocument(document) {
      if (!document.target.files[0]) return;
      this.currentDocument.fichero = document.target.files[0];

      // Fill title with file name without extension
      this.currentDocument.data.titulo = document.target.files[0].name.replace(
        /\.[^/.]+$/,
        ""
      );
    },
    setDocumentDate(data) {
      this.currentDocument.data.fecha = data.date;
    },
    closeDocumentDialog() {
      this.documentDialog = false;
    },
    saveDocument() {
      if (
        !this.$refs.documentForm.validate() ||
        !this.currentDocument.fichero
      ) {
        this.$notify({
          type: "error",
          text: this.$t("t_socio.error.form-errors"),
        });
        return;
      }
      this.$emit("save-document", this.currentDocument);
      this.closeDocumentDialog();
    },
    previewDocument() {
      this.$emit("preview-document", this.currentDocument.fichero);
    },
  },
};
</script>
