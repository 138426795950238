<template>
  <v-card flat class="transparent">
    <span v-if="!isLoading">
      <v-card-title>
        <v-row align="center" justify="space-between" no-gutters>
          <v-col class="d-none d-md-block">
            <span class="headline no-split-words">
              {{ entity.apellidos }}, {{ entity.nombre }}
            </span>
          </v-col>

          <v-col class="text-right">
            <v-btn @click="back()">
              <v-icon>arrow_back</v-icon>
              <span class="d-none d-sm-block"> {{ $t("back") }} </span>
            </v-btn>

            <v-btn
              class="warning ml-2"
              v-if="isAdmin || isPartnerManagement || userId === entity.userId"
              @click="edit(entity)"
            >
              <v-icon>edit</v-icon>
              <span class="d-none d-sm-block"> {{ $t("edit") }} </span>
            </v-btn>
            <v-btn
              v-if="
                (isAdmin || isPartnerManagement) &&
                userId !== entity.userId &&
                !entity.authorities.includes('ROLE_ADMIN')
              "
              class="ml-2"
              :class="entity.fechaBaja ? 'success' : 'error'"
              @click="openDeactivateDialog()"
            >
              <v-icon>
                {{
                  entity.fechaBaja ? "mdi-account-check" : "mdi-account-cancel"
                }}
              </v-icon>
              <span class="d-none d-sm-block">
                {{
                  entity.fechaBaja
                    ? $t("t_socio.actions.activate")
                    : $t("t_socio.actions.deactivate")
                }}
              </span>
            </v-btn>
            <v-btn
              v-if="(isAdmin || isPartnerManagement) && entity.fechaBaja"
              class="error ml-2"
              @click="deleteDialog = true"
            >
              <v-icon>delete</v-icon>
              <span class="d-none d-sm-block"> {{ $t("delete") }} </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col cols="12" md="auto">
            <div class="d-flex justify-center">
              <v-avatar class="mr-md-3 mb-2" size="150" tile>
                <img
                  v-if="entity.thumbnail"
                  :src="entity.thumbnail.src"
                  alt=""
                  style="object-fit: contain"
                />
                <img
                  v-else
                  :src="thumbnailUrl"
                  alt=""
                  style="object-fit: contain"
                />
              </v-avatar>
            </div>
          </v-col>
          <v-col>
            <v-row dense no-gutters>
              <v-col cols="12" md="4">
                <v-row>
                  <v-col
                    cols="6"
                    md="3"
                    class="text-left font-weight-bold"
                    style="white-space: nowrap"
                  >
                    {{ $t("t_socio.prop.numero") }}:
                  </v-col>
                  <v-col cols="6" md="9">
                    {{ entity.numero }}
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row dense no-gutters class="mt-0 mt-md-2">
              <v-col cols="12" md="4">
                <v-row>
                  <v-col cols="6" md="3" class="text-left font-weight-bold">
                    {{ $t("t_socio.prop.dni") }}:
                  </v-col>
                  <v-col cols="6" md="9">
                    {{ entity.dni }}
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="4">
                <v-row>
                  <v-col
                    cols="6"
                    md="3"
                    class="text-left font-weight-bold text-no-wrap"
                  >
                    {{ $t("t_socio.prop.telefono") }}:
                  </v-col>
                  <v-col cols="6" md="9">
                    {{ entity.telefono }}
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="4">
                <v-row>
                  <v-col cols="6" md="3" class="text-left font-weight-bold">
                    {{ $t("t_socio.prop.email") }}:
                  </v-col>
                  <v-col cols="6" md="9">
                    {{ entity.email }}
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row dense no-gutters class="mt-0 mt-md-2">
              <v-col cols="12" md="4">
                <v-row>
                  <v-col cols="6" md="6" class="text-left font-weight-bold">
                    {{ $t("t_socio.prop.fechaNac") }}:
                  </v-col>
                  <v-col cols="6" md="6">
                    {{ birthDate | dateFormat }}
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="4">
                <v-row>
                  <v-col
                    cols="6"
                    md="3"
                    class="text-left font-weight-bold text-no-wrap"
                  >
                    {{ $t("t_socio.prop.profesion") }}:
                  </v-col>
                  <v-col cols="6" md="9">
                    {{ entity.profesion }}
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="4">
                <v-row>
                  <v-col cols="6" md="3" class="text-left font-weight-bold">
                    {{ $t("t_socio.prop.sexo") }}:
                  </v-col>
                  <v-col cols="6" md="9">
                    <span v-if="entity.sexo">{{
                      $t(`sexo.${entity.sexo}`)
                    }}</span>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row dense no-gutters class="mt-0 mt-md-2">
              <v-col cols="12" md="4">
                <v-row>
                  <v-col
                    cols="6"
                    md="3"
                    class="text-left font-weight-bold text-no-wrap"
                  >
                    {{ $t("t_socio.prop.direccion.direccion") }}:
                  </v-col>
                  <v-col cols="6" md="9">
                    {{ entity.direccion.direccion }}
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row dense no-gutters class="mt-0 mt-md-2">
              <v-col cols="12" md="4">
                <v-row>
                  <v-col cols="6" md="6" class="text-left font-weight-bold">
                    {{ $t("t_socio.prop.direccion.cp") }}:
                  </v-col>
                  <v-col cols="6" md="6">
                    {{ entity.direccion.codigoPostal }}
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="4">
                <v-row>
                  <v-col
                    cols="6"
                    md="3"
                    class="text-left font-weight-bold text-no-wrap"
                  >
                    {{ $t("t_socio.prop.direccion.localidad") }}:
                  </v-col>
                  <v-col cols="6" md="9">
                    {{ entity.direccion.localidad }}
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="4">
                <v-row>
                  <v-col
                    cols="6"
                    md="3"
                    class="text-left font-weight-bold text-no-wrap"
                  >
                    {{ $t("t_socio.prop.direccion.provincia") }}:
                  </v-col>
                  <v-col cols="6" md="9">
                    {{ entity.direccion.provincia }}
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row class="mt-0 mt-md-2" dense no-gutters>
              <v-col cols="12" md="4">
                <v-row>
                  <v-col cols="6" md="3" class="text-left font-weight-bold">
                    {{
                      entity.fechaBaja
                        ? $t("t_socio.prop.fechaBaja")
                        : $t("t_socio.prop.fechaAlta")
                    }}:
                  </v-col>
                  <v-col cols="6" md="9">
                    <span v-if="entity.fechaBaja">
                      {{ entity.fechaBaja | dateFormat }}
                    </span>
                    <span v-else>
                      {{ entity.fechaAlta | dateFormat }}
                    </span>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row
              v-if="entity.causaBaja"
              class="mt-0 mt-md-2"
              dense
              no-gutters
            >
              <v-col cols="6" md="1" class="text-left font-weight-bold">
                {{ $t("t_socio.prop.causaBaja") }}:
              </v-col>
              <v-col cols="6" md="11">
                {{ entity.causaBaja }}
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-tabs class="mt-4" grow background-color="transparent">
          <v-tab>{{ $t("t_socio.prop.documentos") }}</v-tab>
          <v-tab>{{ $t("t_socio.prop.pagos") }}</v-tab>
          <v-tab-item class="pa-4">
            <span>
              <div v-if="entity.documentos && entity.documentos.length > 0">
                <v-row
                  v-for="(item, i) in entity.documentos"
                  :key="i"
                  align="center"
                  dense
                  no-gutters
                >
                  <v-col cols="12" md="6">
                    <span style="display: inline-block; width: 80px">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            @click="downloadDocument(item)"
                            v-bind="attrs"
                            v-on="on"
                            ><v-icon color="primary">mdi-download</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t("download_file") }}</span>
                      </v-tooltip>
                      <v-tooltip
                        v-if="documentViewerTypes.includes(item.fichero.type)"
                        top
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            @click="previewDocument(item)"
                            v-bind="attrs"
                            v-on="on"
                            ><v-icon color="primary">mdi-eye</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t("preview_file") }}</span>
                      </v-tooltip>
                    </span>
                    <span
                      v-if="documentViewerTypes.includes(item.fichero.type)"
                      class="link"
                      @click="previewDocument(item)"
                      >{{ item.titulo }}</span
                    >
                    <span v-else class="link" @click="downloadDocument(item)">{{
                      item.titulo
                    }}</span>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-row>
                      <v-col cols="6" md="3" class="text-left font-weight-bold">
                        {{ $t("t_socio.documento.tipo") }}:
                      </v-col>
                      <v-col cols="6" md="9">
                        {{ item.tipo.nombre }}
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-row>
                      <v-col cols="6" md="3" class="text-left font-weight-bold">
                        {{ $t("t_socio.documento.fecha") }}:
                      </v-col>
                      <v-col cols="6" md="9">
                        {{ item.fecha | dateFormat }}
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
              <span v-else>{{ $t("t_socio.headers.no_documentos") }}</span>
            </span>
          </v-tab-item>
          <v-tab-item class="pa-4">
            <div v-if="entity.pagos && entity.pagos.length > 0" class="mt-2">
              <v-expansion-panels accordion>
                <v-expansion-panel v-for="(pago, i) in entity.pagos" :key="i">
                  <v-expansion-panel-header class="text--secondary">
                    <v-row dense no-gutters>
                      <v-col cols="12" md="4">
                        <v-row>
                          <v-col
                            cols="6"
                            md="3"
                            class="text-left font-weight-bold"
                          >
                            {{ $t("t_pago.prop.tipo") }}:
                          </v-col>
                          <v-col cols="6" md="9">
                            {{ $t(`tipopago.${pago.tipo}`) }}
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" md="4" class="mt-2 mt-md-0">
                        <v-row>
                          <v-col
                            cols="6"
                            md="3"
                            class="text-left font-weight-bold"
                          >
                            {{ $t("t_pago.prop.importe") }}:
                          </v-col>
                          <v-col cols="6" md="9"> {{ pago.importe }}€ </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" md="4" class="mt-2 mt-md-0">
                        <v-row>
                          <v-col
                            cols="6"
                            md="3"
                            class="text-left font-weight-bold"
                          >
                            {{ $t("t_pago.prop.fecha") }}:
                          </v-col>
                          <v-col cols="6" md="9">
                            {{ pago.fecha | dateFormat }}
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="text--secondary">
                    <span v-if="pago.documentos && pago.documentos.length > 0">
                      <div v-for="(doc, i) in pago.documentos" :key="i">
                        <span style="display: inline-block; width: 80px">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                icon
                                @click="downloadPaymentDocument(pago.id, doc)"
                                v-bind="attrs"
                                v-on="on"
                                ><v-icon color="primary">mdi-download</v-icon>
                              </v-btn>
                            </template>
                            <span>{{ $t("download_file") }}</span>
                          </v-tooltip>
                          <v-tooltip
                            v-if="documentViewerTypes.includes(doc.type)"
                            top
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                icon
                                @click="previewPaymentDocument(pago.id, doc)"
                                v-bind="attrs"
                                v-on="on"
                                ><v-icon color="primary">mdi-eye</v-icon>
                              </v-btn>
                            </template>
                            <span>{{ $t("preview_file") }}</span>
                          </v-tooltip>
                        </span>
                        <span
                          v-if="documentViewerTypes.includes(doc.type)"
                          class="link"
                          @click="previewPaymentDocument(pago.id, doc)"
                          >{{ doc.fileName }}</span
                        >
                        <span
                          v-else
                          class="link"
                          @click="downloadPaymentDocument(pago.id, doc)"
                          >{{ doc.fileName }}</span
                        >
                      </div>
                    </span>
                    <span v-else>{{ $t("t_pago.headers.no_documentos") }}</span>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
            <span v-else>{{ $t("t_socio.headers.no_pagos") }}</span>
          </v-tab-item>
        </v-tabs>
      </v-card-text>
    </span>
    <loading-page v-else></loading-page>
    <v-dialog persistent width="500" :value="deactivateDialog">
      <v-card flat>
        <v-card-title primary-title class="headline error white--text">
          <v-icon class="mr-4 error white--text"> mdi-account-cancel </v-icon>
          {{ $t("t_socio.actions.deactivate") }}
        </v-card-title>
        <v-card-text class="mt-4">
          <span class="subtitle-1">
            {{ $t("t_socio.deactivateDialog.content") }}
          </span>
          <v-textarea v-model="cause" class="mt-2"></v-textarea>
        </v-card-text>
        <v-card-actions class="pb-4">
          <v-spacer></v-spacer>
          <v-btn @click="closeDeactivateDialog()">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn class="mx-4 error" @click="updateStatus()">
            {{ $t("confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <document-dialog
      :content="currentDocument"
      :title="currentDocumentTitle"
    ></document-dialog>

    <delete-dialog
      :dialog="deleteDialog"
      @cancel="deleteDialog = false"
      @submit="deleteEntity"
    ></delete-dialog>

    <a ref="hiddenDownloader" class="d-none" />
  </v-card>
</template>

<script>
import checkInvalidID from "@/common/checkInvalidID";
import DeleteDialog from "@/components/modal_dialog/DeleteDialog.vue";
import LoadingPage from "@/components/loading-page/LoadingPage.vue";
import { downloadFile } from "@/common/file-utils";
import { mapAuthGetter } from "@/common/mapAuthGetter";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import DocumentDialog from "@/components/modal_dialog/DocumentDialog.vue";
import documentViewerTypes from "@/enumerates/documentviewertypes";

const SocioEntityRepository = RepositoryFactory.get("SocioEntityRepository");

export default {
  name: "socioFormDetail",
  components: { DocumentDialog, LoadingPage, DeleteDialog },
  data() {
    return {
      cause: null,
      deactivateDialog: false,
      deleteDialog: false,
      entity: null,
      loading: false,
      documentViewerTypes,
      currentDocument: null,
      currentDocumentTitle: "",
      thumbnailUrl: require("@/assets/profile-placeholder.webp"),
    };
  },
  computed: {
    ...mapAuthGetter(["isAdmin", "isPartnerManagement", "userId"]),
    birthDate() {
      return this.entity.anoNac !== null &&
        this.entity.mesNac !== null &&
        this.entity.diaNac !== null
        ? [this.entity.anoNac, this.entity.mesNac, this.entity.diaNac]
        : null;
    },
    isLoading() {
      return this.loading;
    },
  },
  beforeRouteUpdate(to, from, next) {
    this._fetchData(to.params.id);
    next();
  },
  created() {
    this._fetchData(this.$route.params.id);
  },
  methods: {
    _fetchData(id) {
      this.loading = true;
      SocioEntityRepository.get(id)
        .then((response) => {
          return this.getThumbnail(response).then(() => {
            this.entity = response;
            if (!this.entity.direccion) this.entity.direccion = {};
          });
        })
        .catch((err) => checkInvalidID(err))
        .finally(() => (this.loading = false));
    },
    back() {
      this.$router.push({
        name: this.isAdmin ? "Socio List" : "Public Socio List",
        params: { backAction: true },
      });
    },
    edit() {
      this.$router.push({
        name: "Socio FormForm",
        params: { id: this.entity.id, backPrevious: true },
      });
    },
    deleteEntity() {
      SocioEntityRepository.delete(this.entity.id)
        .then(() => this.back())
        .finally(() => (this.deleteDialog = false));
    },
    openDeactivateDialog() {
      if (!this.entity.fechaBaja) {
        this.deactivateDialog = true;
      } else {
        this.updateStatus();
      }
    },
    closeDeactivateDialog() {
      this.deactivateDialog = false;
      this.cause = null;
    },
    updateStatus() {
      this.loading = true;
      const method = this.entity.fechaBaja ? "activate" : "deactivate";
      SocioEntityRepository[method](this.entity.id, this.cause)
        .then((res) => (this.entity = res))
        .finally(() => {
          this.closeDeactivateDialog();
          this.loading = false;
        });
    },
    previewDocument(item) {
      console.log(item);
      this.currentDocument = SocioEntityRepository.getDocumentFile(
        this.entity.id,
        item.id
      );
      this.currentDocumentTitle = item.fichero.fileName;
    },
    downloadDocument(item) {
      SocioEntityRepository.getDocumentFile(this.entity.id, item.id).then(
        (response) => {
          downloadFile(
            response,
            item.fichero.fileName,
            this.$refs.hiddenDownloader
          );
        }
      );
    },
    previewPaymentDocument(paymentId, item) {
      this.currentDocument = SocioEntityRepository.getPaymentFile(
        this.entity.id,
        paymentId,
        item.uuid
      );
      this.currentDocumentTitle = item.fileName;
    },
    downloadPaymentDocument(paymentId, item) {
      SocioEntityRepository.getPaymentFile(
        this.entity.id,
        paymentId,
        item.uuid
      ).then((response) => {
        downloadFile(response, item.fileName, this.$refs.hiddenDownloader);
      });
    },
    getThumbnail(socio) {
      if (!socio.thumbnail) return Promise.resolve();
      return SocioEntityRepository.getThumbnail(socio.id)
        .then((savedBlob) => {
          socio.thumbnail.src = URL.createObjectURL(savedBlob);
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>
