<template>
  <v-card v-if="items" flat class="transparent">
    <v-card-title>
      <v-row align="center" justify="space-between" no-gutters>
        <v-col class="d-none d-md-block">
          <span class="headline no-split-words">
            {{ $t($route.meta.label) }}
          </span>
        </v-col>
        <v-col
          cols="12"
          md="4"
          lg="6"
          xl="8"
          order="2"
          order-md="1"
          class="d-md-inline-block text-right mt-4 mt-md-0"
        >
          <debounced-text-field
            v-model="search"
            append-icon="search"
            class="d-md-inline-block"
            dense
            hide-details
            :label="$t('search')"
            @input="redirect"
          ></debounced-text-field>
        </v-col>
      </v-row>
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col cols="12" md="10" offset-md="1">
          <v-row dense align="stretch">
            <v-col
              v-for="(item, index) in items"
              :key="index"
              cols="12"
              sm="6"
              md="4"
              lg="2"
            >
              <v-card outlined height="100%">
                <v-img
                  contain
                  :src="
                    item.thumbnail
                      ? item.thumbnail
                      : require('@/assets/profile-placeholder.webp')
                  "
                  width="150px"
                  height="150px"
                  :class="{
                    'align-center': true,
                    'mx-auto': true,
                    'row-pointer': item.thumbnail,
                  }"
                  alt="Perfil"
                  @click.stop="previewImage(item)"
                ></v-img>
                <v-card-title
                  class="text-body-1 px-2 py-1"
                  style="word-break: break-word"
                >
                  <v-row dense align="center">
                    <v-col>
                      <span>{{ item.apellidos }}, {{ item.nombre }}</span>
                    </v-col>
                    <v-col cols="auto" v-if="userId === item.id">
                      <v-btn
                        color="primary"
                        icon
                        @click.stop="entityDetail(item)"
                      >
                        <v-icon>description</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-title>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>

    <v-dialog :value="previewDialog" persistent fullscreen>
      <v-card>
        <div v-if="previewDialog">
          <v-card-title>
            <v-row dense no-gutters justify="end">
              <v-btn icon @click="closePreviewDialog"
                ><v-icon>close</v-icon></v-btn
              >
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-img :src="selectedImage" max-height="90vh" contain></v-img>
          </v-card-text>
        </div>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapAuthGetter } from "@/common/mapAuthGetter";

import DebouncedTextField from "@/components/debouncing-inputs/DebouncedTextField.vue";

import RepositoryFactory from "@/repositories/RepositoryFactory";

const SocioEntityRepository = RepositoryFactory.get("SocioEntityRepository");

export default {
  name: "PublicSocioList",
  components: {
    DebouncedTextField,
  },
  data() {
    return {
      items: [],
      loading: false,
      search: null,
      totalItems: 0,
      selectedImage: null,
      previewDialog: false,
    };
  },
  computed: {
    ...mapAuthGetter(["userId"]),
    headers() {
      return [
        {
          text: this.$t("t_socio.prop.apellidos"),
          value: "apellidos",
          sortable: false,
          class: "custom-header",
        },
        {
          text: this.$t("t_socio.prop.nombre"),
          value: "nombre",
          sortable: false,
          class: "custom-header",
        },
        { text: "", sortable: false, value: "action" },
      ];
    },
  },
  created() {
    this.search = this.$route.query.search ? this.$route.query.search : null;
    this.getItems();
  },
  methods: {
    getItems() {
      this.loading = true;
      const options = {
        params: {
          search: this.search,
          sort: "apellidos,asc",
          filters: "fechaBaja:NULL",
        },
      };
      SocioEntityRepository.getAllPublic(options)
        .then((response) => {
          const promises = response.content.map((item) =>
            this.getThumbnail(item)
          );
          return Promise.all(promises).then(() => {
            this.items = response.content;
            this.totalItems = response.totalElements;
          });
        })
        .finally(() => (this.loading = false));
    },
    redirect() {
      let query = JSON.parse(JSON.stringify(this.$route.query));
      query.search = this.search || undefined;
      if (JSON.stringify(this.$route.query) !== JSON.stringify(query)) {
        this.$router
          .replace({
            name: this.$route.name,
            query: query,
          })
          .then(() => this.getItems());
      }
    },
    entityDetail(entity) {
      const selection = window.getSelection().toString();
      if (this.userId === entity.id && selection.length === 0) {
        this.$router.push({
          name: "Socio FormDetail",
          params: { id: entity.id, backPrevious: true },
        });
      }
    },
    getThumbnail(socio) {
      if (!socio.thumbnail) return Promise.resolve();
      return SocioEntityRepository.getThumbnail(socio.id)
        .then((savedBlob) => {
          socio.thumbnail.src = URL.createObjectURL(savedBlob);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    previewImage(item) {
      if (item.thumbnail) {
        this.selectedImage = item.thumbnail.src;
        this.previewDialog = true;
      }
    },
    closePreviewDialog() {
      this.previewDialog = false;
      this.selectedImage = null;
    },
  },
};
</script>
<style scoped>
.row-pointer:hover {
  cursor: pointer;
}
.custom-header {
  color: #5f4770;
}
</style>
