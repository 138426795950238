<template>
  <v-dialog :value="showDialog" max-width="800px" @click:outside="closeDialog">
    <v-card>
      <div v-if="showDialog">
        <v-card-title>
          <span>{{ grupo.name }}</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeDialog"><v-icon>close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col cols="3" md="2" class="text-left font-weight-bold">
              {{ $t("t_grupo.prop.socios") }}:
            </v-col>
            <v-col cols="9" md="10">
              <span v-for="(socio, index) in grupo.socios" :key="socio.id"
                >{{ socio.nombre + " " + socio.apellidos
                }}<span v-if="index < grupo.socios.length - 1">, </span>
              </span>
            </v-col>
          </v-row>
        </v-card-text>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "groupDialog",
  props: {
    grupo: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      showDialog: false,
    };
  },
  watch: {
    grupo(newValue) {
      if (newValue) {
        this.showDialog = true;
      }
    },
  },
  methods: {
    closeDialog() {
      this.showDialog = false;
    },
  },
};
</script>
