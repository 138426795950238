const store = {
  state: {
    user: {
      id: null,
      authorities: [],
      login: "",
      logged: false,
      langKey: "",
      token: localStorage.getItem("token"),
    },
  },
};

export default store;
