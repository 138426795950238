<template>
  <v-container fluid>
    <v-card v-if="!isLoading" flat class="transparent">
      <v-card-title>
        <v-row align="center" justify="space-between" no-gutters>
          <v-col class="d-none d-md-block">
            <span class="headline no-split-words">
              {{ $t($route.meta.label) }}
            </span>
          </v-col>

          <v-col class="text-right">
            <v-btn @click="back()">
              <v-icon>arrow_back</v-icon>
              <span class="d-none d-sm-block"> {{ $t("back") }} </span>
            </v-btn>

            <v-btn class="warning ml-2" v-if="isLogged" @click="edit(entity)">
              <v-icon>edit</v-icon>
              <span class="d-none d-sm-block"> {{ $t("edit") }} </span>
            </v-btn>

            <v-btn class="error ml-2" v-if="isLogged" @click="dialog = true">
              <v-icon>delete</v-icon>
              <span class="d-none d-sm-block"> {{ $t("remove") }} </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col cols="3" md="2" class="text-left font-weight-bold">
            {{ $t("t_grupo.prop.name") }}:
          </v-col>
          <v-col cols="9" md="10">
            {{ entity.name }}
          </v-col>

          <v-col cols="3" md="2" class="text-left font-weight-bold">
            {{ $t("t_grupo.prop.socios") }}:
          </v-col>
          <v-col cols="9" md="10">
            <div
              class="float-left mr-2 mb-2"
              v-for="socio in entity.socios"
              :key="socio.id"
            >
              <v-chip
                link
                :to="{
                  name: 'Socio FormDetail',
                  params: { id: socio.id },
                }"
              >
                {{ socio.nombre + " " + socio.apellidos }}
              </v-chip>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <loading-page v-if="isLoading"></loading-page>
    <delete-dialog
      :dialog="dialog"
      @cancel="dialog = false"
      @submit="deleteEntity(entity)"
    ></delete-dialog>
  </v-container>
</template>

<script>
import checkInvalidID from "@/common/checkInvalidID";
import DeleteDialog from "@/components/modal_dialog/DeleteDialog";

import { mapAuthGetter } from "@/common/mapAuthGetter";
import LoadingPage from "@/components/loading-page/LoadingPage.vue";

import RepositoryFactory from "@/repositories/RepositoryFactory";
const GrupoEntityRepository = RepositoryFactory.get("GrupoEntityRepository");

export default {
  name: "grupoFormDetail",
  components: { LoadingPage, DeleteDialog },
  data() {
    return {
      loading: false,
      entity: null,
      dialog: false,
    };
  },
  computed: {
    ...mapAuthGetter(["isLogged"]),
    isLoading() {
      return this.loading;
    },
  },
  beforeRouteUpdate(to, from, next) {
    this._fetchData(to.params.id);
    next();
  },
  created() {
    this._fetchData(this.$route.params.id);
  },
  methods: {
    _fetchData(id) {
      this.loading = true;

      return GrupoEntityRepository.get(id)
        .then((res) => (this.entity = res))
        .catch((err) => checkInvalidID(err))
        .finally(() => (this.loading = false));
    },
    back() {
      this.$router.push({ name: "Grupo List", params: { backAction: true } });
    },
    edit() {
      this.$router.push({
        name: "Grupo FormForm",
        params: { id: this.entity.id, backPrevious: true },
      });
    },
    deleteEntity() {
      this.loading = true;

      return GrupoEntityRepository.delete(this.entity.id)
        .then(() => {
          this.dialog = false;
          this.$router.push({ name: "Grupo List" });
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>
