<template>
  <v-container fluid>
    <v-card v-if="!isLoading" flat class="transparent">
      <v-card-title>
        <v-row align="center" justify="space-between" no-gutters>
          <v-col class="d-none d-md-block">
            <span class="headline no-split-words">
              {{ $t($route.meta.label) }}
            </span>
          </v-col>
          <v-col class="text-right">
            <v-btn @click="back()">
              <v-icon>mdi-arrow-left</v-icon>
              <span class="d-none d-sm-block"> {{ $t("cancel") }} </span>
            </v-btn>
            <v-btn class="success ml-2" @click="save(entity)">
              <v-icon>save</v-icon>
              <span class="d-none d-sm-block"> {{ $t("save") }} </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-row dense>
            <v-col cols="12" md="3" offset-md="2">
              <v-text-field
                dense
                v-model="entity.name"
                type="text"
                :rules="[]"
                :label="$t('t_grupo.prop.name')"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" offset-md="1">
              <v-row dense>
                <v-col>
                  <span>
                    {{ $t("t_grupo.prop.socios") }}
                  </span>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-data-table
                    height="calc(100vh - 350px)"
                    class="transparent"
                    dense
                    show-select
                    hide-default-footer
                    disable-sort
                    v-model="entity.socios"
                    :headers="headers"
                    :items="socios"
                    :items-per-page="-1"
                    :loading="sociosLoading"
                    :search="search"
                  >
                    <template v-slot:top>
                      <v-text-field
                        v-model="search"
                        append-icon="search"
                        dense
                        :label="$t('search')"
                      ></v-text-field>
                    </template>
                    <template v-slot:[`item.nombre`]="{ item }">
                      <span>{{ item.nombre + " " + item.apellidos }}</span>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <loading-page v-if="isLoading"></loading-page>
  </v-container>
</template>

<script>
import LoadingPage from "@/components/loading-page/LoadingPage.vue";
import checkInvalidID from "@/common/checkInvalidID";

import Autocomplete from "@/components/debouncing-inputs/Autocomplete";

import RepositoryFactory from "@/repositories/RepositoryFactory";
const GrupoEntityRepository = RepositoryFactory.get("GrupoEntityRepository");

const SocioEntityRepository = RepositoryFactory.get("SocioEntityRepository");

export default {
  name: "grupoFormForm",
  components: {
    LoadingPage,
  },
  data() {
    return {
      search: "",
      loading: false,
      entity: {},
      socios: [],
      sociosSearch: null,
      sociosLoading: false,
      selectAllSocios: false,
    };
  },
  beforeRouteUpdate(to, from, next) {
    // si se accede a la misma ruta con diferentes parámetros, se cargará el nuevo objeto
    if (to.params.id) this._fetchData(to.params.id);
    next();
  },
  computed: {
    isLoading() {
      return this.loading;
    },
    headers() {
      return [
        {
          text: this.$t("t_socio.prop.nombreCompleto"),
          value: "nombre",
        },
      ];
    },
  },
  watch: {
    selectAllSocios() {
      if (this.selectAllSocios) {
        this.entity.socios = JSON.parse(JSON.stringify(this.socios));
      } else {
        this.entity.socios = [];
      }
    },
  },
  created() {
    this.fetchSocios();
    if (this.$route.params.id) this._fetchData(this.$route.params.id);
  },
  methods: {
    _fetchData(id) {
      this.loading = true;
      return GrupoEntityRepository.get(id)
        .then((response) => {
          this.entity = response;
        })
        .catch((err) => checkInvalidID(err))
        .finally(() => (this.loading = false));
    },
    back() {
      this.$router.push({
        name: "Grupo List",
        params: { backAction: true },
      });
    },
    fetchSocios() {
      this.sociosLoading = true;
      const options = {
        params: {
          sort: "nombre,asc",
        },
      };
      SocioEntityRepository.getAllPublic(options)
        .then((res) => {
          res.content.sort((a, b) => a.apellidos.localeCompare(b.apellidos));
          this.socios = res.content;
        })
        .finally(() => (this.sociosLoading = false));
    },
    async save() {
      if (!this.$refs.form.validate()) {
        this.$notify({
          type: "error",
          text: this.$t("t_grupo.error.form-errors"),
        });
      } else if (this.$route.params.id) {
        this.loading = true;
        GrupoEntityRepository.save(this.entity)
          .then(() =>
            this.$router.push({
              name: "Grupo FormDetail",
              params: {
                id: this.entity.id,
                backAction: this.$route.params.backPrevious,
              },
            })
          )
          .finally(() => (this.loading = false));
      } else {
        this.loading = true;
        GrupoEntityRepository.save(this.entity)
          .then(() =>
            this.$router.push({
              name: "Grupo List",
            })
          )
          .finally(() => (this.loading = false));
      }
    },
  },
};
</script>
