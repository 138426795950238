<template>
  <v-dialog :value="showDialog" persistent fullscreen>
    <v-card>
      <div if="showDialog">
        <v-card-title>
          <span>{{ $t("t_email.preview-email") }}</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeDialog"><v-icon>close</v-icon></v-btn>
        </v-card-title>
        <v-card-text v-if="!isLoading">
          <v-row dense>
            <v-col cols="3" md="2" lg="1" class="text-left font-weight-bold">
              {{ $t("t_email.field.recipients") }}:
            </v-col>
            <v-col cols="9" md="10" lg="11">
              <span v-for="(recipient, index) in recipients" :key="recipient.id"
                >{{ recipient.email
                }}<span v-if="index < recipients.length - 1">, </span>
              </span>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="3" md="2" lg="1" class="text-left font-weight-bold">
              {{ $t("t_email.field.subject") }}:
            </v-col>
            <v-col cols="9" md="10" lg="11">
              {{ subject }}
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" class="text-left font-weight-bold">
              {{ $t("t_email.field.message") }}:
            </v-col>
            <v-col cols="12">
              <iframe
                :srcdoc="email"
                style="
                  width: 100%;
                  min-height: calc(100vh - 180px);
                  display: block;
                  border: none;
                "
              ></iframe>
            </v-col>
          </v-row>
        </v-card-text>
        <loading-page v-else></loading-page>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import LoadingPage from "@/components/loading-page/LoadingPage.vue";

export default {
  name: "emailPreview",
  components: { LoadingPage },
  props: {
    recipients: {
      type: Array,
      default: () => [],
    },
    subject: {
      type: String,
      default: null,
    },
    content: {
      type: Promise,
      default: null,
    },
  },
  data() {
    return {
      showDialog: false,
      isLoading: true,
      email: null,
    };
  },
  watch: {
    content(newValue) {
      if (newValue) {
        this.showDialog = true;
        this.isLoading = true;
        this.content
          .then((response) => {
            this.email = response;
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
  },
  methods: {
    closeDialog() {
      this.showDialog = false;
    },
  },
};
</script>
