export default function replaceOembeds(htmlContent) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlContent, "text/html");
  const oembeds = doc.querySelectorAll("oembed");

  oembeds.forEach((oembed) => {
    const div = doc.createElement("div");
    div.setAttribute(
      "style",
      "display: inline-block; " +
        "background-color: #efe4f2; " +
        "padding: 20px; " +
        "border-radius: 5px; " +
        "text-align: center; " +
        "float: left; " +
        "margin: 1em 1.5em 1em 0; " +
        "width: 250px; "
    );

    let thumbnailUrl;

    if (oembed.getAttribute("url").includes("youtube.com")) {
      const videoId = getParameterByName("v", oembed.getAttribute("url"));
      thumbnailUrl = `https://img.youtube.com/vi/${videoId}/0.jpg`;
    }

    const link = doc.createElement("a");

    if (thumbnailUrl) {
      const thumbnail = doc.createElement("img");
      thumbnail.setAttribute("src", thumbnailUrl);
      thumbnail.setAttribute("style", "width: 200px;");
      link.appendChild(thumbnail);
    } else {
      const h2 = doc.createElement("h2");
      h2.textContent = "🔗";
      link.appendChild(h2);
    }

    link.setAttribute("href", oembed.getAttribute("url"));
    link.setAttribute("target", "_blank");
    link.setAttribute("style", "color: #666; font-style: italic;");

    div.appendChild(link);

    oembed.parentNode.replaceChild(div, oembed);
  });

  return doc.documentElement.innerHTML;
}

function getParameterByName(name, url) {
  name = name.replace(/[\[\]]/g, "\\$&");
  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}
