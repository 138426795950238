<template>
  <v-card v-if="items" flat class="transparent">
    <v-card-title>
      <v-row align="center" justify="space-between" no-gutters>
        <v-col class="d-none d-md-block col-auto">
          <span class="headline no-split-words">
            {{ $t($route.meta.label) }}
          </span>
        </v-col>
        <v-col
          cols="12"
          order="2"
          order-md="1"
          class="d-md-inline-block text-right mt-4 mt-md-0 col-md"
        >
          <debounced-text-field
            v-model="search"
            append-icon="search"
            class="d-md-inline-block"
            dense
            hide-details
            :label="$t('search')"
            @input="redirectOnFilterChange"
          ></debounced-text-field>
        </v-col>
        <v-col cols="12" order="1" order-md="2" class="text-right col-md-auto">
          <v-btn color="success ml-2" @click="openCloseDialog(null)">
            <v-icon>add</v-icon>
            <span class="d-none d-md-block"> {{ $t("assign") }} </span>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row align="center" justify="end" dense no-gutters>
        <v-col cols="12">
          <v-row align="center" justify="end" class="my-2">
            <v-col cols="12" md="3">
              <v-select
                v-model="authoritiesFilter"
                small-chips
                dense
                prepend-icon="mdi-filter"
                :items="authoritiesConstants"
                :item-text="(item) => $t(item.text)"
                :label="$t('t_socio.authorities_filter.filter')"
                :menu-props="{ offsetY: true }"
                multiple
                @change="redirectOnFilterChange"
              >
              </v-select>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-data-table
            class="transparent"
            disable-pagination
            :headers="headers"
            :items="items"
            :items-per-page="-1"
            :loading="loading"
          >
            <template v-slot:[`item.nombreCompleto`]="{ item }">
              <span> {{ item.apellidos + ", " + item.nombre }}</span>
            </template>
            <template v-slot:[`item.authorities`]="{ item }">
              <template
                v-if="
                  item.authorities.length === 1 &&
                  item.authorities[0] === 'ROLE_PARTNER'
                "
              >
                <!-- Mostrar guión si hay un solo rol que es ROLE_PARTNER -->
                <span> — </span>
              </template>
              <template v-else>
                <!-- Mostrar los roles diferentes a ROLE_PARTNER -->
                <v-chip
                  v-for="authority in item.authorities.filter(
                    (auth) => auth !== 'ROLE_PARTNER'
                  )"
                  :key="authority"
                  class="mr-2"
                >
                  {{ $t(`account.authorities.${authority}`) }}
                </v-chip>
              </template>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-icon
                v-if="userId !== item.id"
                color="warning"
                @click="openCloseDialog(item)"
              >
                edit
              </v-icon>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>

    <permiso-dialog
      v-if="showDialog"
      :selected-entity="selectedEntity"
      :show-dialog="showDialog"
      @save="onSave"
      @cancel="openCloseDialog(null, false)"
    ></permiso-dialog>
  </v-card>
</template>

<script>
import DebouncedTextField from "@/components/debouncing-inputs/DebouncedTextField.vue";

import RepositoryFactory from "@/repositories/RepositoryFactory";
import authoritiesConstants from "@/common/authorities-constants";
import PermisoDialog from "@/modules/permiso/components/permisoDialog.vue";
import { mapAuthGetter } from "@/common/mapAuthGetter";

const SocioEntityRepository = RepositoryFactory.get("SocioEntityRepository");

export default {
  name: "permisoList",
  components: {
    DebouncedTextField,
    PermisoDialog,
  },
  data() {
    return {
      authoritiesConstants,
      authoritiesFilter: [],
      items: [],
      loading: false,
      search: null,
      selectedEntity: null,
      showDialog: false,
    };
  },
  computed: {
    ...mapAuthGetter(["userId"]),
    headers() {
      return [
        {
          text: this.$t("t_socio.prop.dni"),
          value: "dni",
          align: "left",
        },
        {
          text: this.$t("t_socio.prop.nombreCompleto"),
          value: "nombreCompleto",
          align: "left",
        },
        {
          text: this.$t("t_socio.prop.authorities"),
          value: "authorities",
          align: "left",
          sortable: false,
        },
        { text: "", sortable: false, value: "action", width: "80px" },
      ];
    },
  },
  created() {
    this.search = this.$route.query.search;
    this.authoritiesFilter = this.$route.query.authorities || [];
    this.getItems();
  },
  methods: {
    getItems() {
      this.loading = true;
      const options = {
        params: {
          search: this.search,
          authorities: this.$route.query.authorities,
        },
      };
      SocioEntityRepository.getAllWithAuthorities(options)
        .then((response) => (this.items = response))
        .finally(() => (this.loading = false));
    },
    redirect(query) {
      if (JSON.stringify(this.$route.query) !== JSON.stringify(query)) {
        this.$router
          .replace({
            name: "Permiso List",
            query: query,
          })
          .then(() => this.getItems());
      }
    },
    onSave() {
      this.openCloseDialog(null, false);
      this.getItems();
    },
    openCloseDialog(entity, open = true) {
      this.selectedEntity = entity;
      this.showDialog = open;
    },
    redirectOnFilterChange() {
      let query = JSON.parse(JSON.stringify(this.$route.query));
      query.search = this.search || undefined;
      // It is important to not send an empty list to the server
      query.authorities =
        this.authoritiesFilter.length > 0 ? this.authoritiesFilter : null;
      this.redirect(query);
    },
  },
};
</script>
