import { render, staticRenderFns } from "./PublicJuntaDirectivaList.vue?vue&type=template&id=12ec9059&scoped=true"
import script from "./PublicJuntaDirectivaList.vue?vue&type=script&lang=js"
export * from "./PublicJuntaDirectivaList.vue?vue&type=script&lang=js"
import style0 from "./PublicJuntaDirectivaList.vue?vue&type=style&index=0&id=12ec9059&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12ec9059",
  null
  
)

export default component.exports