export default {
  image: [".jpg", ".jpeg", ".gif", ".png", ".apng", ".webp", ".bmp", ".ico"],
  document: [
    ".pdf",
    ".doc",
    ".docx",
    ".xls",
    ".xlsx",
    ".ppt",
    ".pptx",
    ".odt",
    ".ods",
    ".odp",
    ".txt",
    ".rtf",
  ],
};
