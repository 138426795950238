import tipoDocumentoList from "@/modules/tipoDocumento/components/tipoDocumentoList.vue";
import tipoDocumentoForm from "@/modules/tipoDocumento/components/tipoDocumentoForm.vue";
import tipoDocumentoSocioForm from "@/modules/tipoDocumento/components/tipoDocumentoSocioForm.vue";
import tipoDocumentoSocioList from "@/modules/tipoDocumento/components/tipoDocumentoSocioList.vue";

const routes = [
  {
    path: "/tipos-documento",
    name: "TipoDocumento List",
    component: tipoDocumentoList,
    meta: {
      authority: ["ROLE_ADMIN"],
      label: "t_tipoDocumento.headers.tipoDocumentoList",
    },
  },
  {
    path: "/tipos-documento/:id/editar",
    name: "TipoDocumento Form",
    component: tipoDocumentoForm,
    meta: {
      authority: ["ROLE_ADMIN"],
      label: "t_tipoDocumento.headers.update",
    },
  },
  {
    path: "/tipos-documento/novo",
    name: "TipoDocumento FormCreate",
    component: tipoDocumentoForm,
    meta: {
      authority: ["ROLE_ADMIN"],
      label: "t_tipoDocumento.headers.create",
    },
  },
  {
    path: "/tipos-documento-socio",
    name: "TipoDocumentoSocio List",
    component: tipoDocumentoSocioList,
    meta: {
      authority: ["ROLE_ADMIN"],
      label: "t_tipoDocumentoSocio.headers.tipoDocumentoSocioList",
    },
  },
  {
    path: "/tipos-documento-socio/:id/editar",
    name: "TipoDocumentoSocio Form",
    component: tipoDocumentoSocioForm,
    meta: {
      authority: ["ROLE_ADMIN"],
      label: "t_tipoDocumentoSocio.headers.update",
    },
  },
  {
    path: "/tipos-documento-socio/novo",
    name: "TipoDocumentoSocio FormCreate",
    component: tipoDocumentoSocioForm,
    meta: {
      authority: ["ROLE_ADMIN"],
      label: "t_tipoDocumentoSocio.headers.create",
    },
  },
];

export default routes;
