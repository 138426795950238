import { HTTP } from "@/common/http-common";
import Logger from "js-logger";

const logger = Logger.get("logger");
const RESOURCE_NAME = "entities/socios";

export default {
  async getReport() {
    try {
      return (
        await HTTP.get(`${RESOURCE_NAME}/report`, {
          responseType: "blob",
        })
      ).data;
    } catch (err) {
      logger.error("Error getting report");
      throw err;
    }
  },
  async getAll(options = {}) {
    try {
      return (await HTTP.get(`${RESOURCE_NAME}`, options)).data;
    } catch (err) {
      logger.error("Error fetching all", options);
      throw err;
    }
  },

  async getAllPublic(options = {}) {
    try {
      return (await HTTP.get(`${RESOURCE_NAME}/public`, options)).data;
    } catch (err) {
      logger.error("Error fetching all public", options);
      throw err;
    }
  },

  async getAllWithAuthorities(options = {}) {
    try {
      return (await HTTP.get(`${RESOURCE_NAME}/authorities`, options)).data;
    } catch (err) {
      logger.error("Error fetching all", options);
      throw err;
    }
  },

  async get(id) {
    try {
      return (await HTTP.get(`${RESOURCE_NAME}/${id}`)).data;
    } catch (err) {
      logger.error("Error fetching entity with id " + id);
      throw err;
    }
  },

  async getThumbnail(id) {
    return (
      await HTTP.get(`${RESOURCE_NAME}/${id}/thumbnail`, {
        responseType: "blob",
      })
    ).data;
  },

  async getDocumentFile(id, documentId) {
    return (
      await HTTP.get(`${RESOURCE_NAME}/${id}/documents/${documentId}/file`, {
        responseType: "blob",
      })
    ).data;
  },

  async getPaymentFile(id, paymentId, uuid) {
    return (
      await HTTP.get(
        `${RESOURCE_NAME}/${id}/payments/${paymentId}/file/${uuid}`,
        {
          responseType: "blob",
        }
      )
    ).data;
  },

  async save(entity) {
    if (entity.id) {
      try {
        return (await HTTP.put(`${RESOURCE_NAME}/${entity.id}`, entity)).data;
      } catch (err) {
        logger.error("Error updating entity", entity);
        throw err;
      }
    } else {
      try {
        return (await HTTP.post(`${RESOURCE_NAME}`, entity)).data;
      } catch (err) {
        logger.error("Error saving entity", entity);
        throw err;
      }
    }
  },

  async updateAuthorities(entity) {
    try {
      return (
        await HTTP.put(`${RESOURCE_NAME}/${entity.id}/authorities`, entity)
      ).data;
    } catch (err) {
      logger.error("Error updating authorities of entity", entity);
      throw err;
    }
  },

  async activate(id) {
    try {
      return (await HTTP.put(`${RESOURCE_NAME}/${id}/activate`)).data;
    } catch (err) {
      logger.error("Error activating entity with id " + id);
      throw err;
    }
  },

  async deactivate(id, cause) {
    const options = {
      params: {
        cause: cause,
      },
    };
    try {
      return (await HTTP.put(`${RESOURCE_NAME}/${id}/deactivate`, {}, options))
        .data;
    } catch (err) {
      logger.error("Error deactivating entity with id " + id);
      throw err;
    }
  },

  async delete(id) {
    try {
      return await HTTP.delete(`${RESOURCE_NAME}/${id}`);
    } catch (err) {
      logger.error("Error deleting entity with id " + id);
      throw err;
    }
  },

  async getPaymentsReport() {
    try {
      return (
        await HTTP.get(`${RESOURCE_NAME}/payments-report`, {
          responseType: "blob",
        })
      ).data;
    } catch (err) {
      logger.error("Error getting payments report");
      throw err;
    }
  },
};
