<template>
  <v-card v-if="items" flat class="transparent">
    <v-card-title>
      <v-row align="center" justify="space-between" no-gutters>
        <v-col class="d-none d-md-block col-auto">
          <span class="headline no-split-words">
            {{ $t($route.meta.label) }}
          </span>
        </v-col>
        <v-col
          cols="12"
          order="2"
          order-md="1"
          class="d-md-inline-block text-right mt-4 mt-md-0 col-md"
        >
          <debounced-text-field
            v-model="search"
            append-icon="search"
            class="d-md-inline-block"
            dense
            hide-details
            :label="$t('search')"
            @input="redirectOnFilterChange"
          ></debounced-text-field>
        </v-col>
        <v-col cols="12" order="1" order-md="2" class="text-right col-md-auto">
          <v-btn
            class="ml-2"
            @click="
              $router.push({
                name: 'Public Evento List',
                params: { backAction: true },
              })
            "
          >
            <v-icon>mdi-arrow-left</v-icon>
            <span class="d-none d-sm-block"> {{ $t("cancel") }} </span>
          </v-btn>
          <v-btn color="success ml-2" :to="{ name: 'Evento FormCreate' }">
            <v-icon>add</v-icon>
            <span class="d-none d-sm-block"> {{ $t("new") }} </span>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>

    <v-card-text>
      <v-data-table
        class="transparent"
        :headers="headers"
        :items="items"
        :search="search"
        :options="entitiesPage"
        :server-items-length="totalItems"
        :loading="loading"
        :footer-props="tableFooterProps"
        @click:row="entityDetail"
        @update:options="redirectOnTableChange"
      >
        <template v-slot:[`item.fecha`]="{ item }">
          {{ formatDateWithHours(dateArrayToDate(item.fecha)) }}
        </template>

        <template v-slot:[`item.publico`]="{ item }">
          {{ item.publico ? $t("yes") : $t("no") }}
        </template>

        <template v-slot:[`item.action`]="{ item }">
          <v-icon color="primary" @click.stop="entityDetail(item)">
            description
          </v-icon>
          <v-icon
            color="warning"
            v-if="isLogged"
            @click.stop="editEntity(item)"
          >
            edit
          </v-icon>
          <v-icon
            color="error"
            v-if="isLogged"
            @click.stop="showDeleteDialog(item)"
          >
            delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card-text>
    <delete-dialog
      :dialog="dialog"
      @cancel="dialog = false"
      @submit="deleteEntity()"
    ></delete-dialog>
  </v-card>
</template>

<script>
import { mapAuthGetter } from "@/common/mapAuthGetter";
import DeleteDialog from "@/components/modal_dialog/DeleteDialog";
import DebouncedTextField from "@/components/debouncing-inputs/DebouncedTextField.vue";
import defaultPaginationSettings from "@/common/default-pagination-settings";
import tableFooterProps from "@/common/table-footer-props";
import RepositoryFactory from "@/repositories/RepositoryFactory";

import {
  generateSort,
  parseStringToSortBy,
  parseStringToSortDesc,
} from "@/common/pagination-utils";

import {
  dateArrayToDate,
  formatDateWithHours,
} from "@/common/conversion-utils";

const EventoEntityRepository = RepositoryFactory.get("EventoEntityRepository");

export default {
  name: "eventoListList",
  components: {
    DeleteDialog,
    DebouncedTextField,
  },
  data() {
    return {
      name: "evento",
      items: [],
      search: null,
      dialog: false,
      selected: null,
      entitiesPage: {
        page:
          parseInt(this.$route.query.page) || defaultPaginationSettings.page,
        itemsPerPage:
          parseInt(this.$route.query.pageSize) ||
          defaultPaginationSettings.itemsPerPage,
        sortBy: parseStringToSortBy(this.$route.query.sort),
        sortDesc: parseStringToSortDesc(this.$route.query.sort),
      },
      totalItems: 0,
      loading: false,
      tableFooterProps,
    };
  },
  computed: {
    ...mapAuthGetter(["isLogged"]),
    geomProps() {
      return [];
    },
    headers() {
      return [
        {
          text: this.$t("t_evento.prop.titulo"),
          value: "titulo",
        },
        {
          text: this.$t("t_evento.prop.lugar"),
          value: "lugar",
        },
        {
          text: this.$t("t_evento.prop.fecha"),
          value: "fecha",
        },
        {
          text: this.$t("t_evento.prop.publico"),
          value: "publico",
        },
        { text: "", sortable: false, value: "action" },
      ];
    },
  },
  watch: {},
  created() {
    this.search = this.$route.query.search ? this.$route.query.search : null;
    if (JSON.stringify(this.$route.query) != "{}") this.getItems();
  },
  methods: {
    formatDateWithHours,
    dateArrayToDate,
    getItems() {
      this.loading = true;
      const options = {
        params: {
          page: this.entitiesPage.page - 1,
          search: this.search,
          sort: this.$route.query.sort,
          size: this.entitiesPage.itemsPerPage,
        },
      };
      EventoEntityRepository.getAllAdmin(options)
        .then((response) => {
          this.items = response.content;
          this.totalItems = response.totalElements;
        })
        .finally(() => (this.loading = false));
    },
    redirect(query) {
      if (JSON.stringify(this.$route.query) !== JSON.stringify(query)) {
        this.$router
          .replace({
            name: "Admin Evento List",
            query: query,
          })
          .then(() => this.getItems());
      }
    },
    entityDetail(entity) {
      const selection = window.getSelection().toString();
      if (selection.length === 0) {
        this.$router.push({
          name: "Public Evento Detail",
          params: { id: entity.id, backPrevious: true },
        });
      }
    },
    editEntity(entity) {
      const selection = window.getSelection().toString();
      if (selection.length === 0) {
        this.$router.push({
          name: "Evento FormForm",
          params: { id: entity.id, backPrevious: true },
        });
      }
    },
    redirectOnTableChange(pagination = this.entitiesPage) {
      this.entitiesPage = pagination;
      let query = JSON.parse(JSON.stringify(this.$route.query));
      query.page = this.entitiesPage.page.toString();
      query.pageSize = this.entitiesPage.itemsPerPage.toString();
      query.sort = generateSort(this.entitiesPage);
      query.search = this.search || undefined;
      this.redirect(query);
    },
    redirectOnFilterChange() {
      if (this.entitiesPage.page !== 1) {
        this.entitiesPage.page = 1;
      } else {
        this.redirectOnTableChange();
      }
    },
    showDeleteDialog(entity) {
      this.selected = entity;
      this.dialog = true;
    },
    closeDeleteDialog() {
      this.selected = null;
      this.dialog = false;
    },
    deleteEntity() {
      EventoEntityRepository.delete(this.selected.id)
        .then(() => this.getItems())
        .finally(() => this.closeDeleteDialog());
    },
  },
};
</script>
