import permisoList from "@/modules/permiso/components/permisoList.vue";

const routes = [
  {
    path: "/permisos",
    name: "Permiso List",
    component: permisoList,
    meta: {
      authority: ["ROLE_ADMIN"],
      label: "t_permiso.headers.permisoList",
    },
  },
];

export default routes;
