export const editorConfig = {
  language: "gl",
  toolbar: {
    items: [
      "heading",
      "|",
      "fontSize",
      "fontFamily",
      "fontColor",
      "fontBackgroundColor",
      "bold",
      "italic",
      "underline",
      "highlight",
      "alignment",
      "link",
      "bulletedList",
      "numberedList",
      "|",
      "outdent",
      "indent",
      "|",
      "imageInsert",
      "horizontalLine",
      "blockQuote",
      "specialCharacters",
      "insertTable",
      "mediaEmbed",
      "undo",
      "redo",
      "selectAll",
    ],
  },
  image: {
    toolbar: [
      "imageTextAlternative",
      "|",
      "imageStyle:full",
      "imageStyle:alignCenter",
      "imageStyle:alignLeft",
      "imageStyle:alignRight",
    ],
    styles: ["full", "alignCenter", "alignLeft", "alignRight"],
  },
  table: {
    contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
  },
  mediaEmbed: {
    removeProviders: [
      "dailymotion",
      "spotify",
      "vimeo",
      "instagram",
      "twitter",
      "googleMaps",
      "flickr",
      "facebook",
    ],
  },
};
