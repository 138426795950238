export default [
  {
    text: "account.authorities.ROLE_ADMIN",
    value: "ROLE_ADMIN",
  },
  {
    text: "account.authorities.SP_PROJECT_ASSOCIATION",
    value: "SP_PROJECT_ASSOCIATION",
  },
  { text: "account.authorities.PARTNER_READONLY", value: "PARTNER_READONLY" },
  {
    text: "account.authorities.PARTNER_MANAGEMENT",
    value: "PARTNER_MANAGEMENT",
  },
  { text: "account.authorities.SP_FINANCES", value: "SP_FINANCES" },
  { text: "account.authorities.EVENT_MANAGEMENT", value: "EVENT_MANAGEMENT" },
  {
    text: "account.authorities.ASSEMBLY_MANAGEMENT",
    value: "ASSEMBLY_MANAGEMENT",
  },
  {
    text: "account.authorities.BOARD_OF_DIRECTORS_READONLY",
    value: "BOARD_OF_DIRECTORS_READONLY",
  },
  {
    text: "account.authorities.BOARD_OF_DIRECTORS_MANAGEMENT",
    value: "BOARD_OF_DIRECTORS_MANAGEMENT",
  },
];
