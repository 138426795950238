import managePassword from "@/modules/contrasena/components/ManagePassword.vue";

const routes = [
  {
    path: "/contrasinais",
    name: "Manage Password",
    component: managePassword,
    meta: {
      authority: ["ROLE_ADMIN"],
      label: "t_socio.headers.managePassword",
    },
  },
];

export default routes;
