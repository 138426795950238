import { HTTP } from "@/common/http-common";
import Logger from "js-logger";

const logger = Logger.get("logger");
const RESOURCE_NAME = "user-management/users";

export default {
  async getAll(options) {
    try {
      return (await HTTP.get(RESOURCE_NAME, options)).data;
    } catch (err) {
      logger.error("Error fetching all users");
      throw err;
    }
  },

  async get(login) {
    try {
      return (await HTTP.get(`${RESOURCE_NAME}/${login}`)).data;
    } catch (err) {
      logger.error("Error fetching user with login " + login);
      throw err;
    }
  },

  async save(user) {
    if (user.id) {
      try {
        return (await HTTP.put(RESOURCE_NAME, user)).data;
      } catch (err) {
        logger.error("Error updating user", user);
        throw err;
      }
    } else {
      try {
        return (await HTTP.post(RESOURCE_NAME, user)).data;
      } catch (err) {
        logger.error("Error saving user", user);
        throw err;
      }
    }
  },

  async delete(user) {
    try {
      return await HTTP.delete(`${RESOURCE_NAME}/${user.login}`);
    } catch (err) {
      logger.error("Error deleting user", user);
      throw err;
    }
  },

  async changePassword(user) {
    try {
      return (
        await HTTP.post(`${RESOURCE_NAME}/${user.login}/change_password`, user)
      ).data;
    } catch (err) {
      logger.error("Error changing password for user", user);
      throw err;
    }
  },
};
