import Vue from "vue";
import Router from "vue-router";
import NotFound from "@/views/NotFound.vue";
import Login from "./account/login/Login.vue";
import Unauthorized from "./views/Unauthorized";
import Account_Router from "../src/account/router";
import auth from "@/common/auth";
import store from "@/common/store";
const user = store.state.user;

import Static_PagesRouter from "./components/static_editor/router";

import User_ManagementRouter from "./components/user-management/router";

import socioRouter from "./modules/socio/router";
import asambleaRouter from "./modules/asamblea/router";
import presupuestoRouter from "./modules/presupuesto/router";
import eventoRouter from "./modules/evento/router";
import tipoDocumentoRouter from "./modules/tipoDocumento/router";
import documentoRouter from "./modules/documento/router";
import permisoRouter from "./modules/permiso/router";
import contrasenaRouter from "./modules/contrasena/router";
import emailRouter from "./modules/email/router";
import grupoRouter from "./modules/grupo/router";
import juntaDirectivaRouter from "./modules/juntaDirectiva/router";
import i18n from "@/plugins/i18n";
import properties from "@/properties";

Vue.use(Router);

const routes = [
  {
    path: "/about",
    name: "about",
    meta: {
      label: "menu.about",
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("./views/About.vue"),
  },
  {
    path: "/account/login",
    name: "Login",
    component: Login,
    meta: {
      public: true,
      isLoginPage: true,
      label: "menu.logIn",
    },
  },
  {
    path: "/unauthorized",
    name: "Unauthorized",
    component: Unauthorized,
    meta: {
      public: true,
      label: "errors.unauthorized.title",
    },
  },
  {
    path: "*",
    name: "NotFound",
    component: NotFound,
    props: true,
    meta: {
      public: true,
      label: "page_not_found.title",
    },
  },
];

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: routes
    .concat(Static_PagesRouter)

    .concat(User_ManagementRouter)
    .concat(Account_Router)

    .concat(
      socioRouter,
      asambleaRouter,
      presupuestoRouter,
      eventoRouter,
      tipoDocumentoRouter,
      documentoRouter,
      permisoRouter,
      contrasenaRouter,
      emailRouter,
      grupoRouter,
      juntaDirectivaRouter
    ),
});

const backPreviousStack = [];

const checkRedirectToPrevious = (to, from, next) => {
  // if backPrevious prop, add from route to stack and go next
  if (to.params.backPrevious) {
    if (to.params.backAction) {
      // eslint-disable-next-line
      console.warn(
        "Set route with backAction and backPrevious flags is not allowed"
      );
    } else {
      backPreviousStack.push(from);
    }
    next();
  } else if (to.params.backAction) {
    // if backAction prop (should only be present when route from back button)
    // get last item from stack if not empty
    let length = backPreviousStack.length;
    if (length < 1) next();
    else {
      let stackTop = {};
      Object.assign(stackTop, backPreviousStack[length - 1]);
      // check if redirecting to stack top route and if so,
      // remove it from the stack
      if (to.fullPath == stackTop.fullPath) {
        backPreviousStack.pop();
        next();
      } else {
        // call router.push() will trigger again this function
        // so is necessary set backAction and backPrevious params
        // in order to reach this block again
        stackTop.params.backAction = true;
        stackTop.params.backPrevious = false;
        router.push(stackTop); // calling router.push() instead of next() to prevent console error
      }
    }
  } else {
    // clean stack if no backPrevious neither backAction props are present and route next
    // only when the next page is not the login page
    if (to.meta.isLoginPage)
      backPreviousStack.splice(0, backPreviousStack.length);
    next();
  }
};
function checkAuthentication(to, from, next) {
  auth.isAuthenticationChecked().finally(() => {
    // por defecto las rutas restringen el acceso a usuario autenticados
    const requiresAuth = !to.meta.public;
    const requiredAuthorities = to.meta.authority;
    const allowSelf = to.meta.allowSelf; // si la ruta permite que el usuario acceda a sus propios datos
    const userIsLogged = user.logged;
    const loggedUserAuthorities = user.authorities;

    if (requiresAuth) {
      if (userIsLogged) {
        const isNotAuthorized =
          requiredAuthorities &&
          !loggedUserAuthorities.some((item) =>
            requiredAuthorities.includes(item)
          );
        const userIsNotMatch = to.params.id !== user.id;
        if (isNotAuthorized && (userIsNotMatch || !allowSelf)) {
          // usuario logueado pero sin permisos o intentando acceder a un recurso que no corresponde a su usuario
          Vue.notify({
            title: i18n.t("errors.unauthorized.title"),
            text: i18n.t("errors.unauthorized.subtitle"),
            type: "error",
          });
          auth.logout().then(() => next("/account/login"));
        } else {
          // usuario logueado y con permisos adecuados
          next();
        }
      } else {
        // usuario no está logueado
        Vue.notify({
          title: i18n.t("errors.auth_required.title"),
          text: i18n.t("errors.auth_required.body"),
          type: "error",
        });
        backPreviousStack.push(to);
        next("/account/login");
      }
    } else {
      // página pública
      if (userIsLogged && to.meta.isLoginPage) {
        // si estamos logueados no hace falta volver a mostrar el login
        next({ name: "Presentación", replace: true });
      } else {
        next();
      }
    }
  });
}

router.beforeEach(checkAuthentication);
router.beforeEach(checkRedirectToPrevious);
router.beforeEach((to, from, next) => {
  const { meta } = to;
  document.title = meta.label
    ? (document.title =
        i18n.t(
          meta.label,
          meta.labelArguments ? meta.labelArguments(to) : null
        ) +
        " - " +
        properties.APP_NAME)
    : properties.APP_NAME;
  next();
});

export default router;
