import grupoListList from "./components/grupoListList";

import grupoFormForm from "./components/grupoFormForm";
import grupoFormDetail from "./components/grupoFormDetail";

const routes = [
  {
    path: "/grupo-list",
    name: "Grupo List",
    component: grupoListList,
    meta: {
      userIsLogged: true,
      public: true,
      label: "t_grupo.headers.grupoList",
    },
  },
  {
    path: "/grupo-form/:id/edit",
    name: "Grupo FormForm",
    component: grupoFormForm,
    meta: {
      userIsLogged: true,
      label: "t_grupo.headers.grupoForm",
    },
  },
  {
    path: "/grupo-form/new",
    name: "Grupo FormCreate",
    component: grupoFormForm,
    meta: {
      userIsLogged: true,
      label: "t_grupo.headers.grupoForm",
    },
  },
  {
    path: "/grupo-form/:id(\\d+)",
    name: "Grupo FormDetail",
    component: grupoFormDetail,
    meta: {
      userIsLogged: true,
      public: true,
      label: "t_grupo.headers.grupoFormDetail",
    },
  },
];

export default routes;
