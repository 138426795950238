<template>
  <div>
    <v-app-bar-nav-icon
      :class="getDisplayForLargeScreen() + ' nav-bar-icon'"
      variant="text"
      @click.stop="app.drawer = !app.drawer"
    ></v-app-bar-nav-icon>

    <LateralMenuBar
      :drawer="app.drawer"
      @drawer-changed="updateDrawer"
      :class="getDisplayForLargeScreen()"
    ></LateralMenuBar>
    <menu-bar-items
      :collapsed="false"
      :class="getDisplayForSmallScreen()"
    ></menu-bar-items>
  </div>
</template>

<script>
import LateralMenuBar from "./LateralMenuBar";
import MenuBarItems from "./MenuBarItems";

export default {
  name: "MenuBar",
  components: {
    LateralMenuBar,
    MenuBarItems,
  },
  data() {
    return {
      app: {
        drawer: false,
      },
    };
  },
  methods: {
    updateDrawer(newVal) {
      this.app.drawer = newVal;
    },
    getDisplayForLargeScreen() {
      if (
        this.$vuetify.breakpoint.name === "md" ||
        this.$vuetify.breakpoint.name === "lg" ||
        this.$vuetify.breakpoint.name === "xl"
      )
        return "display-none";
    },
    getDisplayForSmallScreen() {
      if (
        this.$vuetify.breakpoint.name === "xs" ||
        this.$vuetify.breakpoint.name === "sm"
      )
        return "display-none";
    },
  },
};
</script>
<style scoped>
.nav-bar-icon {
  position: absolute;
  top: 10px;
  left: 10px;
}
.display-none {
  display: none;
}
.container {
  display: contents;
}
</style>
