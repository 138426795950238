<template>
  <!-- Menú lateral -->
  <v-navigation-drawer v-model="localDrawer" app color="#f6f2f6">
    <template v-slot:prepend>
      <v-list-item two-line v-if="isLogged">
        <v-list-item-avatar>
          <v-icon size="50">account_circle</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ user.login }}</v-list-item-title>
          <v-list-item-subtitle>{{ loggedAs }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>

    <v-divider v-if="isLogged" />

    <v-list>
      <v-list-item to="/account/login" v-if="!isLogged">
        <v-list-item-icon>
          <v-icon>mdi-login</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t("menu.logIn") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-list-group no-action>
      <template v-slot:activator>
        <v-list-item-content>
          <v-list-item-title>{{ $t("menu.project.title") }}</v-list-item-title>
        </v-list-item-content>
      </template>
      <v-list-item :to="{ name: 'Presentación' }" exact>
        <v-list-item-content>
          <v-list-item-title>{{
            $t("menu.project.presentation")
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item :to="{ name: 'Instalacións' }" exact>
        <v-list-item-content>
          <v-list-item-title>{{
            $t("menu.project.facilities")
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item :to="{ name: 'Servizos' }" exact>
        <v-list-item-content>
          <v-list-item-title>{{
            $t("menu.project.services")
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item :to="{ name: 'Ideario' }" exact>
        <v-list-item-content>
          <v-list-item-title>{{
            $t("menu.project.ideology")
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-group>

    <v-list-group no-action>
      <template v-slot:activator>
        <v-list-item-content>
          <v-list-item-title>{{
            $t("menu.association.title")
          }}</v-list-item-title>
        </v-list-item-content>
      </template>
      <v-list-item :to="{ name: 'Xestión de idades' }" exact>
        <v-list-item-content>
          <v-list-item-title>{{
            $t("menu.association.age_management")
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item :to="{ name: 'Como asociarse' }" exact>
        <v-list-item-content>
          <v-list-item-title>{{
            $t("menu.association.associate")
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item :to="{ name: 'Organización' }" exact>
        <v-list-item-content>
          <v-list-item-title>{{
            $t("menu.association.organization")
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item :to="{ name: 'Documento List' }" exact>
        <v-list-item-content>
          <v-list-item-title :class="isLogged ? '' : 'disabled'">{{
            $t("menu.association.documentation")
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        :to="{
          name:
            isAdmin || isPartnerManagement || isPartnerReadonly
              ? 'Socio List'
              : 'Public Socio List',
        }"
        exact
      >
        <v-list-item-content>
          <v-list-item-title :class="isLogged ? '' : 'disabled'">{{
            $t("menu.association.partners")
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item :to="{ name: 'Contactar' }" exact>
        <v-list-item-content>
          <v-list-item-title>{{
            $t("menu.association.contact")
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-group>

    <v-list-group no-action>
      <template v-slot:activator>
        <v-list-item-content>
          <v-list-item-title>{{ $t("menu.finances.title") }}</v-list-item-title>
        </v-list-item-content>
      </template>
      <v-list-item :to="{ name: 'Prezos' }" exact>
        <v-list-item-content>
          <v-list-item-title>{{
            $t("menu.finances.prices")
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item :to="{ name: 'Presupuesto List' }" exact>
        <v-list-item-content>
          <v-list-item-title :class="isLogged ? '' : 'disabled'">{{
            $t("menu.finances.budget")
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item :to="{ name: 'Contabilidade' }" exact>
        <v-list-item-content>
          <v-list-item-title :class="isLogged ? '' : 'disabled'">{{
            $t("menu.finances.accounting")
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-group>

    <v-list-group no-action>
      <template v-slot:activator>
        <v-list-item-content>
          <v-list-item-title>{{
            $t("menu.activities.title")
          }}</v-list-item-title>
        </v-list-item-content>
      </template>
      <v-list-item :to="{ name: 'Public Evento List' }" exact>
        <v-list-item-content>
          <v-list-item-title>{{
            $t("menu.activities.public_events")
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item :to="{ name: 'Partner Evento List' }" exact>
        <v-list-item-content>
          <v-list-item-title :class="isLogged ? '' : 'disabled'">{{
            $t("menu.activities.private_events")
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item :to="{ name: 'Public Asamblea List' }" exact>
        <v-list-item-content>
          <v-list-item-title :class="isLogged ? '' : 'disabled'">{{
            $t("menu.activities.assemblies")
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-group>

    <v-list>
      <v-list-group v-if="isAdmin" no-action>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>{{
              $t("menu.management.title")
            }}</v-list-item-title>
          </v-list-item-content>
        </template>
        <v-list-item :to="{ name: 'Admin Evento List' }" exact>
          <v-list-item-content>
            <v-list-item-title>{{
              $t("menu.activities.events")
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'TipoDocumentoSocio List' }" exact>
          <v-list-item-content>
            <v-list-item-title>{{
              $t("menu.management.tipo_documento_socio")
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </v-list>
    <template v-slot:append v-if="isLogged">
      <div class="pa-2">
        <v-btn block @click="logout()">{{ $t("menu.logout") }}</v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import auth from "@/common/auth";
import { mapAuthGetter } from "@/common/mapAuthGetter";

export default {
  name: "LateralMenuBar",
  props: {
    drawer: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      user: auth.getUser(),
    };
  },
  computed: {
    ...mapAuthGetter([
      "isAdmin",
      "isLogged",
      "isPartnerManagement",
      "isPartnerReadonly",
    ]),
    loggedAs() {
      return this.isLogged
        ? this.$t("menu.loggedAs") +
            (this.isAdmin
              ? this.$t("menu.admin")
              : this.$t("menu.registeredUser"))
        : "";
    },
    localDrawer: {
      get() {
        return this.drawer;
      },
      set(val) {
        this.$emit("drawer-changed", val);
      },
    },
  },
  methods: {
    logout() {
      auth.logout().then(
        this.$router.push({ name: "Presentación" }).catch((err) => {
          //Do nothing if navigating to current route, otherwise throw error
          if (!err.name === "NavigationDuplicated") {
            throw err;
          }
        })
      );
    },
  },
};
</script>
<style scoped>
.v-list-item__title {
  font-weight: bold;
  font-size: large !important;
  color: #5f4770;
}
.v-list-item__subtitle {
  white-space: normal;
}
</style>
