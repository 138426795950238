<template>
  <v-card flat class="transparent">
    <span v-if="!isLoading">
      <v-card-title>
        <v-row align="center" justify="space-between" no-gutters>
          <v-col class="d-none d-md-block">
            <span class="headline no-split-words">
              {{ $t($route.meta.label) }}
            </span>
          </v-col>
          <v-col class="text-right">
            <v-btn @click="back()">
              <v-icon>mdi-arrow-left</v-icon>
              <span class="d-none d-sm-block"> {{ $t("cancel") }} </span>
            </v-btn>
            <v-btn class="success ml-2" @click="save(entity)">
              <v-icon>save</v-icon>
              <span class="d-none d-sm-block"> {{ $t("save") }} </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-row dense no-gutters>
            <v-col cols="12">
              <v-text-field
                class="required"
                dense
                v-model="entity.nombre"
                type="text"
                :rules="[(v) => (v && v.trim() !== '') || $t('error.required')]"
                :label="$t('t_tipoDocumento.prop.nombre')"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form> </v-card-text
    ></span>
    <loading-page v-else></loading-page>
  </v-card>
</template>

<script>
import LoadingPage from "@/components/loading-page/LoadingPage.vue";
import checkInvalidID from "@/common/checkInvalidID";

import RepositoryFactory from "@/repositories/RepositoryFactory";
const TipoDocumentoEntityRepository = RepositoryFactory.get(
  "TipoDocumentoEntityRepository"
);

export default {
  name: "tipoDocumentoForm",
  components: {
    LoadingPage,
  },
  data() {
    return {
      loading: false,
      entity: {},
    };
  },
  beforeRouteUpdate(to, from, next) {
    // si se accede a la misma ruta con diferentes parámetros, se cargará el nuevo objeto
    if (to.params.id) this._fetchData(to.params.id);
    next();
  },
  created() {
    if (this.$route.params.id) this._fetchData(this.$route.params.id);
  },
  computed: {
    isLoading() {
      return this.loading;
    },
  },
  watch: {},
  methods: {
    _fetchData(id) {
      this.loading = true;
      return TipoDocumentoEntityRepository.get(id)
        .then((response) => {
          this.entity = response;
        })
        .catch((err) => checkInvalidID(err))
        .finally(() => (this.loading = false));
    },
    back() {
      this.$router.push({
        name: "TipoDocumento List",
        params: { backAction: true },
      });
    },
    async save() {
      if (!this.$refs.form.validate()) {
        this.$notify({
          type: "error",
          text: this.$t("t_tipoDocumento.error.form-errors"),
        });
        return;
      }
      this.loading = true;
      TipoDocumentoEntityRepository.save(this.entity)
        .then(() => this.$router.push({ name: "TipoDocumento List" }))
        .finally(() => (this.loading = false));
    },
  },
};
</script>
