import Vue from "vue";
import i18n from "@/plugins/i18n";
import properties from "@/properties";
import RepositoryFactory from "@/repositories/RepositoryFactory";

const FileEntityRepository = RepositoryFactory.get("FileEntityRepository");

async function resizeThumbnail(thumbnail) {
  return new Promise((resolve) => {
    const MAX_WIDTH = 150;
    const MAX_HEIGHT = 150;

    const reader = new FileReader();

    reader.onload = function (event) {
      const img = new Image();
      img.onload = function () {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }

        canvas.width = width;
        canvas.height = height;

        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob((blob) => {
          resolve(new File([blob], thumbnail.name, { type: "image/jpeg" }));
        }, "image/jpeg");
      };

      img.src = event.target.result;
    };

    reader.readAsDataURL(thumbnail);
  });
}

async function uploadFile(item) {
  if (!item) return;
  if (item.size > properties.MAX_FILE_SIZE) {
    Vue.notify({
      type: "error",
      text: i18n.t("file.error.max-file-size-exceeded", {
        value: properties.MAX_FILE_SIZE,
      }),
      duration: 30000,
    });
    throw "Max file exceeded";
  } else if (item.size === 0) {
    Vue.notify({
      type: "error",
      text: i18n.t("file.error.empty-file"),
      duration: 30000,
    });
    throw "Empty file";
  }
  const file = new FormData();
  file.append("fileName", item.name);
  const extension = item.name.substring(item.name.lastIndexOf(".") + 1);
  file.append("type", extension);
  file.append("content", item);

  try {
    return await FileEntityRepository.save(file);
  } catch (err) {
    console.log(err);
    // If there is not a defined error, we show a generic one
    if (!err.response.headers["x-app-error"]) {
      Vue.notify({
        type: "error",
        text: i18n.t("file.error.uploading"),
        duration: 30000,
      });
    }
    throw "Error updating file";
  }
}

function downloadFile(blob, fileName, downloadInput) {
  const url = window.URL.createObjectURL(blob);
  const downloadLink = downloadInput;
  downloadLink.href = url;
  downloadLink.download = fileName;
  downloadLink.click();
  window.URL.revokeObjectURL(url);
}

export { uploadFile, downloadFile, resizeThumbnail };
