<template>
  <v-card flat class="transparent">
    <span v-if="!isLoading">
      <v-card-title>
        <v-row align="center" justify="space-between" no-gutters>
          <v-col class="d-none d-md-block">
            <span class="headline no-split-words">
              {{ $t($route.meta.label) }}
            </span>
          </v-col>
          <v-col class="text-right">
            <v-btn @click="back()">
              <v-icon>mdi-arrow-left</v-icon>
              <span class="d-none d-sm-block"> {{ $t("cancel") }} </span>
            </v-btn>
            <v-btn class="success ml-2" @click="save(entity)">
              <v-icon>save</v-icon>
              <span class="d-none d-sm-block"> {{ $t("save") }} </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-row dense>
            <v-col cols="12" md="3">
              <input
                :ref="'documentFileLoader'"
                type="file"
                hidden
                :accept="extensions.document"
                required
                @change="(e) => updateDocument(e)"
              />
              <v-btn
                color="primary"
                class="my-1"
                small
                @click="chooseDocument()"
              >
                <v-icon>mdi-upload</v-icon>
                <span class="d-none d-sm-block">
                  {{ $t("select_file") }}
                </span>
              </v-btn>
              <span v-if="selectedFile" class="ml-2">
                <v-tooltip
                  v-if="documentViewerTypes.includes(selectedFile.type)"
                  top
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      @click="previewDocument(selectedFile)"
                      v-bind="attrs"
                      v-on="on"
                      ><v-icon color="primary">mdi-eye</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("preview_file") }}</span>
                </v-tooltip>
                <span
                  v-if="documentViewerTypes.includes(selectedFile.type)"
                  class="link"
                  @click="previewDocument(selectedFile)"
                  >{{ selectedFile.name }}</span
                >
                <span v-else>{{ selectedFile.name }}</span>
              </span>
              <span v-else class="ml-2 error--text">
                {{ $t("upload.file_required") }}
              </span>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                v-model="entity.descripcion"
                dense
                type="text"
                :label="$t('t_documento.prop.descripcion')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <dateAndHourPicker
                class="required"
                :datePickerProp="{
                  data: entity.fecha,
                  label: $t('t_documento.prop.fecha'),
                  mandatory: true,
                }"
                @update-time="updateDateTime('fecha', false, ...arguments)"
                :rules="[(v) => !!v || $t('error.required')]"
              ></dateAndHourPicker>
            </v-col>
            <v-col cols="6" md="3" class="pt-1">
              <v-select
                v-model="entity.tipo"
                class="required"
                dense
                :items="tiposDocumento"
                :item-text="(item) => item.nombre"
                :label="$t('t_documento.prop.tipo')"
                :loading="loadingDocumentTypes"
                :rules="[(v) => !!v || $t('error.required')]"
                return-object
              >
              </v-select>
            </v-col>
          </v-row>
        </v-form> </v-card-text
    ></span>
    <loading-page v-else></loading-page>

    <document-dialog
      :content="currentDocument"
      :title="currentDocumentTitle"
    ></document-dialog>
  </v-card>
</template>

<script>
import LoadingPage from "@/components/loading-page/LoadingPage.vue";
import checkInvalidID from "@/common/checkInvalidID";

import DateAndHourPicker from "@/components/calendar/DateAndHourPicker.vue";

import RepositoryFactory from "@/repositories/RepositoryFactory";
import extensions from "@/common/file-extensions";
import regex from "@/common/regex-validation";
import { uploadFile } from "@/common/file-utils";
import { getTodayDateAsArray } from "@/common/conversion-utils";
import DocumentDialog from "@/components/modal_dialog/DocumentDialog.vue";
import documentViewerTypes from "@/enumerates/documentviewertypes";
const DocumentoEntityRepository = RepositoryFactory.get(
  "DocumentoEntityRepository"
);
const TipoDocumentoEntityRepository = RepositoryFactory.get(
  "TipoDocumentoEntityRepository"
);

export default {
  name: "documentoFormForm",
  components: {
    DocumentDialog,
    LoadingPage,
    DateAndHourPicker,
  },
  data() {
    return {
      loading: false,
      entity: {
        titulo: null,
        fecha: getTodayDateAsArray(),
        fichero: null,
        tipo: null,
      },
      selectedFile: null,
      currentDocument: null,
      currentDocumentTitle: null,
      extensions: extensions,
      regex: regex,
      tiposDocumento: null,
      loadingDocumentTypes: false,
      documentViewerTypes,
    };
  },
  beforeRouteUpdate(to, from, next) {
    // si se accede a la misma ruta con diferentes parámetros, se cargará el nuevo objeto
    if (to.params.id) this._fetchData(to.params.id);
    next();
  },
  created() {
    this.getDocumentTypes();
    if (this.$route.params.id) this._fetchData(this.$route.params.id);
  },
  computed: {
    isLoading() {
      return this.loading;
    },
  },
  watch: {},
  methods: {
    _fetchData(id) {
      this.loading = true;
      return DocumentoEntityRepository.get(id)
        .then((response) => {
          this.entity = response;
          if (this.entity.fichero) {
            DocumentoEntityRepository.getFile(id).then((savedBlob) => {
              this.selectedFile = new File(
                [savedBlob],
                this.entity.fichero.fileName,
                {
                  type: savedBlob.type,
                }
              );
            });
          }
        })
        .catch((err) => checkInvalidID(err))
        .finally(() => (this.loading = false));
    },
    back() {
      this.$router.push({
        name: "Documento List",
        params: { backAction: true },
      });
    },
    getDocumentTypes() {
      this.loadingDocumentTypes = true;
      TipoDocumentoEntityRepository.getAll()
        .then((res) => (this.tiposDocumento = res.content))
        .finally(() => (this.loadingDocumentTypes = false));
    },
    updateDateTime(name, hasTime, data) {
      this.entity[name] = data.date
        ? hasTime
          ? data.time
            ? data.date.concat(data.time)
            : data.date.concat([0, 0])
          : data.date
        : null;
    },
    async save() {
      if (!this.$refs.form.validate() || !this.selectedFile) {
        this.$notify({
          type: "error",
          text: this.$t("t_documento.error.form-errors"),
        });
        return;
      }
      try {
        if (this.selectedFile) {
          this.entity.fichero = await uploadFile(this.selectedFile);
        }
      } catch (e) {
        console.error(e);
        this.selectedFile = null;
      }

      this.loading = true;
      DocumentoEntityRepository.save(this.entity)
        .then(() => this.$router.push({ name: "Documento List" }))
        .finally(() => (this.loading = false));
    },
    chooseDocument() {
      this.$refs.documentFileLoader.click();
    },
    updateDocument(file) {
      if (!file.target.files[0]) return;
      this.selectedFile = file.target.files[0];

      // Remove file extension from title
      this.entity.titulo = this.selectedFile.name.replace(/\.[^/.]+$/, "");
    },
    previewDocument(item) {
      this.currentDocument = new Promise((resolve) => {
        resolve(new Blob([item], { type: item.type }));
      });
      this.currentDocumentTitle = item.name;
    },
  },
};
</script>
