import ResetPasswordRequest from "../password/reset/ResetPasswordRequest.vue";

import ResetPasswordFinish from "../password/reset/ResetPasswordFinish.vue";

import Profile from "../profile/Profile.vue";
import ProfileEdit from "../profile/ProfileEdit.vue";

import Activate from "../activate/Activate.vue";

import ChangePassword from "@/account/profile/ChangePassword";

const routes = [
  {
    path: "/account/reset_password",
    name: "ResetPasswordRequest",
    component: ResetPasswordRequest,
    meta: {
      public: true,
      label: "account.resetPassword",
    },
  },
  {
    path: "/account/reset/finish",
    name: "ResetPasswordFinish",
    component: ResetPasswordFinish,
    meta: {
      public: true,
      label: "account.resetPassword",
    },
  },
  {
    path: "/account/set/finish",
    name: "UserCreatedByAdminNewPassword",
    component: ResetPasswordFinish,
    meta: {
      public: true,
      label: "account.setPassword",
    },
  },
  {
    path: "/account/activate",
    name: "Activate",
    component: Activate,
    meta: {
      public: true,
      label: "account.userActivation",
    },
  },
  {
    path: "/account/profile",
    name: "Profile",
    component: Profile,
    meta: {
      public: false,
      userIsLogged: true,
      label: "account.yourProfile",
    },
  },
  {
    path: "/account/edit",
    name: "EditProfile",
    component: ProfileEdit,
    meta: {
      public: false,
      userIsLogged: true,
      label: "account.editProfile",
    },
  },
  {
    path: "/account/change_password",
    name: "ChangePassword",
    component: ChangePassword,
    meta: {
      public: false,
      userIsLogged: true,
      label: "account.changePassword",
    },
  },
];

export default routes;
