var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"persistent":"","max-width":"1000"},model:{value:(_vm.documentDialog),callback:function ($$v) {_vm.documentDialog=$$v},expression:"documentDialog"}},[(_vm.documentDialog)?_c('div',[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.isEditingDocument ? _vm.$t("t_socio.actions.edit_document") : _vm.$t("t_socio.actions.add_document"))+" ")]),_c('v-card-text',[(_vm.currentDocument)?_c('div',{staticClass:"mt-4"},[_c('v-form',{ref:"documentForm"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('input',{ref:'documentFileLoader',attrs:{"type":"file","hidden":"","accept":_vm.extensions.document,"required":""},on:{"change":(e) => _vm.updateDocument(e)}}),_c('v-btn',{staticClass:"my-1",attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.chooseDocument()}}},[_c('v-icon',[_vm._v("mdi-upload")]),_c('span',{staticClass:"d-none d-sm-block"},[_vm._v(" "+_vm._s(_vm.$t("select_file"))+" ")])],1),(_vm.currentDocument.fichero)?_c('span',{staticClass:"ml-2"},[(
                      _vm.documentViewerTypes.includes(
                        _vm.currentDocument.fichero.type
                      )
                    )?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.previewDocument(_vm.currentDocument.fichero)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-eye")])],1)]}}],null,false,2231763987)},[_c('span',[_vm._v(_vm._s(_vm.$t("preview_file")))])]):_vm._e(),(
                      _vm.documentViewerTypes.includes(
                        _vm.currentDocument.fichero.type
                      )
                    )?_c('span',{staticClass:"link",on:{"click":function($event){return _vm.previewDocument(_vm.currentDocument.fichero)}}},[_vm._v(_vm._s(_vm.currentDocument.fichero.name))]):_c('span',[_vm._v(_vm._s(_vm.currentDocument.fichero.name))])],1):_c('span',{staticClass:"ml-2 error--text"},[_vm._v(" "+_vm._s(_vm.$t("upload.file_required"))+" ")])],1)],1),_c('v-row',{staticClass:"mt-3",attrs:{"dense":""}},[_c('v-col',{staticClass:"pt-1",attrs:{"cols":"6","md":"4"}},[_c('v-text-field',{staticClass:"required",attrs:{"dense":"","label":_vm.$t('t_socio.documento.titulo'),"rules":[(v) => !!v || _vm.$t('error.required')]},model:{value:(_vm.currentDocument.data.titulo),callback:function ($$v) {_vm.$set(_vm.currentDocument.data, "titulo", $$v)},expression:"currentDocument.data.titulo"}})],1),_c('v-col',{staticClass:"pt-1",attrs:{"cols":"6","md":"4"}},[_c('v-select',{staticClass:"ml-2 required",attrs:{"dense":"","items":_vm.tiposDocumento,"item-text":(item) => item.nombre,"label":_vm.$t('t_socio.documento.tipo'),"loading":_vm.loadingDocumentTypes,"rules":[(v) => !!v || _vm.$t('error.required')],"return-object":""},model:{value:(_vm.currentDocument.data.tipo),callback:function ($$v) {_vm.$set(_vm.currentDocument.data, "tipo", $$v)},expression:"currentDocument.data.tipo"}})],1),_c('v-col',{staticClass:"pt-1",attrs:{"cols":"6","md":"4"}},[_c('date-and-hour-picker',{staticClass:"ml-2 required",attrs:{"datePickerProp":{
                    data: _vm.currentDocument.data.fecha,
                    label: _vm.$t('t_socio.documento.fecha'),
                    mandatory: true,
                  },"rules":[(v) => !!v || _vm.$t('error.required')]},on:{"update-time":function($event){return _vm.setDocumentDate(...arguments)}}})],1)],1)],1)],1):_vm._e()]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{on:{"click":function($event){return _vm.closeDocumentDialog()}}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")]),_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){return _vm.saveDocument()}}},[_c('v-icon',[_vm._v("save")]),_c('span',{staticClass:"d-none d-sm-block"},[_vm._v(" "+_vm._s(_vm.$t("save"))+" ")])],1)],1)],1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }