<template>
  <v-card class="mr-6 pa-4" outlined>
    <v-file-input
      ref="thumbnailLoader"
      class="d-none"
      :value="thumbnailFile"
      :accept="extensions.image"
      @change="uploadThumbnail"
    ></v-file-input>

    <div
      class="pa-3 drop-zone d-flex flex-column align-center justify-center text-center"
      @drop.prevent="handleDrop"
      @dragover.prevent
    >
      <v-img
        v-if="thumbnailUrl"
        :src="thumbnailUrl"
        max-width="170"
        max-height="170"
        contain
        width="100%"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>

      <div v-else>
        <v-icon size="40">photo</v-icon>
        <p class="ma-0">{{ $t("upload.drag_and_drop_image") }}</p>
      </div>
    </div>

    <div class="mt-2 d-flex justify-center">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            color="warning"
            @click="chooseThumbnail"
            v-bind="attrs"
            v-on="on"
            ><v-icon>edit</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("edit") }}</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            color="error"
            @click="clearThumbnail"
            :disabled="!thumbnailUrl"
            v-bind="attrs"
            v-on="on"
            ><v-icon>delete</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("delete") }}</span>
      </v-tooltip>
    </div>
  </v-card>
</template>

<script>
import extensions from "@/common/file-extensions";
import { resizeThumbnail, uploadFile } from "@/common/file-utils";

export default {
  name: "DragAndDropImage",
  props: {
    thumbnailFile: {
      type: Object,
      default: null,
    },
    thumbnailUrl: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      extensions: extensions,
    };
  },
  methods: {
    chooseThumbnail() {
      this.$refs.thumbnailLoader.$refs.input.click();
    },
    clearThumbnail() {
      this.$emit("thumbnail-file", null);
      this.$emit("thumbnail-url", null);
    },
    uploadThumbnail(file) {
      try {
        if (
          !extensions.image.includes(
            file.name.substring(file.name.lastIndexOf(".")).toLowerCase()
          )
        ) {
          this.$notify({
            type: "error",
            text: this.$t("upload.invalid_extension", {
              param: file.name.substring(file.name.lastIndexOf(".")),
            }),
          });
          return;
        }
        resizeThumbnail(file).then((resizedThumbnail) => {
          uploadFile(resizedThumbnail).then((res) => {
            this.$emit("thumbnail-file", res);
            this.$emit("thumbnail-url", URL.createObjectURL(resizedThumbnail));
          });
        });
      } catch (e) {
        console.error(e);
        this.clearThumbnail();
      }
    },
    handleDrop(event) {
      const files = event.dataTransfer.files;
      if (files.length > 0) {
        const file = files[0];
        this.uploadThumbnail(file);
      }
    },
  },
};
</script>
<style scoped>
.drop-zone {
  min-height: 200px;
  min-width: 200px;
  border: 1px dashed #e0e0e0;
  border-radius: 5px;
}
</style>
