import emailForm from "@/modules/email/components/emailForm.vue";

const routes = [
  {
    path: "/correos-electronicos",
    name: "Email Form",
    component: emailForm,
    meta: {
      authority: ["ROLE_ADMIN"],
      label: "t_email.headers.emailForm",
    },
  },
];

export default routes;
