<template>
  <div>
    <v-dialog persistent :value="showDialog" max-width="800">
      <div v-if="showDialog">
        <v-card>
          <v-card-title>{{ $t("t_permiso.headers.assign") }}</v-card-title>

          <v-card-text>
            <v-form ref="form">
              <v-row dense>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="newEntity"
                    class="required"
                    dense
                    :disabled="selectedEntity != null"
                    prepend-inner-icon="mdi-id-card"
                    :loading="partnersLoading"
                    :items="partnersFullName"
                    item-text="fullName"
                    :label="$t('t_socio.prop.nombre')"
                    :menu-props="{ offsetY: true }"
                    return-object
                    :rules="[(v) => !!v || $t('error.required')]"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row
                v-if="newEntity && newEntity.dni"
                class="mt-4 mt-md-0"
                dense
              >
                <v-col>
                  <v-select
                    v-model="newEntity.authorities"
                    deletable-chips
                    dense
                    :items="availableAuthorities"
                    :item-text="(item) => $t(item.text)"
                    item-value="value"
                    :label="$t('t_socio.prop.authorities')"
                    :menu-props="{ offsetY: true }"
                    multiple
                    small-chips
                    @change="onAuthoritiesChange"
                  ></v-select>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn
              v-if="selectedEntity"
              color="error"
              :disabled="!canSaveIsNotSameUser"
              :loading="loading"
              @click="removeAuthoritiesDialog = true"
            >
              {{ $t("t_permiso.dialog.removeAllAuthorities.title") }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn :loading="loading" @click="() => $emit('cancel')">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn
              color="success"
              :disabled="!canSaveEnoughtAuthorities"
              @click="saveEntity"
            >
              <v-icon>save</v-icon>
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </v-dialog>
    <v-dialog persistent width="500" :value="roleAdminDialog">
      <v-card flat>
        <v-card-title primary-title class="headline warning white--text">
          <v-icon class="mr-4 warning white--text"> mdi-alert </v-icon>
          {{ $t("t_permiso.dialog.roleAdminDialog.title") }}
        </v-card-title>
        <v-card-text class="mt-4">
          <span class="subtitle-1">
            {{ $t("t_permiso.dialog.roleAdminDialog.content") }}
          </span>
        </v-card-text>
        <v-card-actions class="pb-4">
          <v-spacer></v-spacer>
          <v-btn @click="closeRoleAdminDialog(false)">
            {{ $t("t_permiso.dialog.roleAdminDialog.cancel") }}
          </v-btn>
          <v-btn class="mx-4 warning" @click="closeRoleAdminDialog(true)">
            {{ $t("t_permiso.dialog.roleAdminDialog.confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <delete-dialog
      :dialog="removeAuthoritiesDialog"
      :title="$t('t_permiso.dialog.removeAllAuthorities.title')"
      :content="$t('t_permiso.dialog.removeAllAuthorities.content')"
      @cancel="removeAuthoritiesDialog = false"
      @submit="removeAllAuthorities"
    ></delete-dialog>
  </div>
</template>

<script>
import authoritiesConstants from "@/common/authorities-constants";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import DeleteDialog from "@/components/modal_dialog/DeleteDialog.vue";
import { mapAuthGetter } from "@/common/mapAuthGetter";

const SocioEntityRepository = RepositoryFactory.get("SocioEntityRepository");

export default {
  components: { DeleteDialog },
  props: {
    showDialog: {
      type: Boolean,
      required: true,
    },
    selectedEntity: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      removeAuthoritiesDialog: false,
      loading: false,
      newEntity: { dni: null, authorities: null },
      partners: [],
      partnersLoading: false,
      roleAdminDialog: false,
      tempAuthorities: null,
    };
  },
  computed: {
    ...mapAuthGetter(["userId"]),
    availableAuthorities() {
      if (
        this.newEntity &&
        this.newEntity.authorities &&
        this.newEntity.authorities.indexOf("ROLE_ADMIN") !== -1
      ) {
        return authoritiesConstants.filter((a) => a.value === "ROLE_ADMIN");
      }
      return authoritiesConstants;
    },
    canSaveEnoughtAuthorities() {
      /* We check that authorities contains more than 1 because the ROLE_PARTNER is not taking into account,
       * except in the case that the assigned authority is ROLE_ADMIN */
      return (
        this.newEntity &&
        this.newEntity.dni &&
        ((this.selectedEntity == null &&
          (this.newEntity.authorities.indexOf("ROLE_ADMIN") !== -1 ||
            this.newEntity.authorities.length > 1)) ||
          this.selectedEntity != null)
      );
    },
    canSaveIsNotSameUser() {
      // We do not allow to remove authorities to the current logged user
      return (
        this.selectedEntity &&
        this.selectedEntity.authorities.length > 0 &&
        this.userId !== this.newEntity.id
      );
    },
    // The full name is added to the partners so that it is possible to search by this field
    partnersFullName() {
      let auxPartners = JSON.parse(JSON.stringify(this.partners));
      if (this.selectedEntity === null) {
        auxPartners = auxPartners.filter((p) => p.authorities.length === 1);
      }
      return auxPartners
        .map((p) => {
          return { fullName: p.nombre + " " + p.apellidos, ...p };
        })
        .sort((a, b) => a.fullName.localeCompare(b.fullName));
    },
  },
  created() {
    if (this.selectedEntity != null) {
      this.newEntity = JSON.parse(JSON.stringify(this.selectedEntity));
      this.newEntity.fullName =
        this.selectedEntity.nombre + " " + this.selectedEntity.apellidos;
    }
    this.getPartners();
  },
  methods: {
    closeRoleAdminDialog(accept = false) {
      this.newEntity.authorities = accept
        ? ["ROLE_ADMIN"]
        : this.tempAuthorities;
      this.tempAuthorities = null;
      this.roleAdminDialog = false;
    },
    getPartners() {
      this.partnersLoading = true;
      SocioEntityRepository.getAllWithAuthorities({
        params: { getPartners: true },
      })
        .then((res) => (this.partners = res))
        .finally(() => (this.partnersLoading = false));
    },
    onAuthoritiesChange(newAuthorities) {
      if (newAuthorities.includes("ROLE_ADMIN")) {
        this.tempAuthorities = newAuthorities.filter((a) => a !== "ROLE_ADMIN");
        this.roleAdminDialog = true;
      } else {
        this.newEntity.authorities = newAuthorities;
      }
    },
    removeAllAuthorities() {
      this.loading = true;
      this.newEntity.authorities = [];
      SocioEntityRepository.updateAuthorities(this.newEntity)
        .then(() => this.$emit("save"))
        .finally(() => (this.loading = false));
      this.loading = false;
    },
    saveEntity() {
      if (!this.$refs.form.validate()) {
        this.$notify({
          type: "error",
          text: this.$t("t_socio.error.form-errors"),
        });
        return;
      }
      this.loading = true;
      SocioEntityRepository.updateAuthorities(this.newEntity)
        .then(() => this.$emit("save"))
        .finally(() => (this.loading = false));
    },
  },
};
</script>
