import store from "@/common/store";

const isAdmin = () => {
  return store.state.user.authorities.indexOf("ROLE_ADMIN") !== -1;
};

const isLogged = () => {
  return store.state.user.logged;
};

const isPartner = () => {
  return store.state.user.authorities.indexOf("ROLE_PARTNER") !== -1;
};

const isSpProjectAssociation = () => {
  return store.state.user.authorities.indexOf("SP_PROJECT_ASSOCIATION") !== -1;
};

const isPartnerReadonly = () => {
  return store.state.user.authorities.indexOf("PARTNER_READONLY") !== -1;
};

const isPartnerManagement = () => {
  return store.state.user.authorities.indexOf("PARTNER_MANAGEMENT") !== -1;
};

const isSpFinances = () => {
  return store.state.user.authorities.indexOf("SP_FINANCES") !== -1;
};

const isEventManagement = () => {
  return store.state.user.authorities.indexOf("EVENT_MANAGEMENT") !== -1;
};

const isAssemblyManagement = () => {
  return store.state.user.authorities.indexOf("ASSEMBLY_MANAGEMENT") !== -1;
};

const isBoardOfDirectorsReadonly = () => {
  return (
    store.state.user.authorities.indexOf("BOARD_OF_DIRECTORS_READONLY") !== -1
  );
};

const isBoardOfDirectorsManagement = () => {
  return (
    store.state.user.authorities.indexOf("BOARD_OF_DIRECTORS_MANAGEMENT") !== -1
  );
};

const userId = () => {
  return store.state.user.id;
};

export function mapAuthGetter(options) {
  const object = {};
  options.forEach((option) => {
    object[option] = eval(option);
  });
  return object;
}
