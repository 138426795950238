<template>
  <v-card flat class="transparent">
    <v-card-title>
      <v-row align="center" justify="space-between" no-gutters>
        <v-col class="d-none d-md-block col-auto">
          <span class="headline no-split-words">
            {{ $t($route.meta.label) }}
          </span>
        </v-col>
        <v-col
          cols="12"
          order="2"
          order-md="1"
          class="d-md-inline-block text-right mt-4 mt-md-0 col-md"
        >
          <debounced-text-field
            v-model="search"
            append-icon="search"
            class="d-md-inline-block"
            dense
            hide-details
            :label="$t('search')"
            @input="redirectOnFilterChange"
          ></debounced-text-field>
        </v-col>
        <v-col
          v-if="isAdmin || isAssemblyManagement"
          cols="12"
          order="1"
          order-md="2"
          class="text-right col-md-auto"
        >
          <v-btn color="success ml-2" :to="{ name: 'Documento FormCreate' }">
            <v-icon>add</v-icon>
            <span class="d-none d-sm-block"> {{ $t("new") }} </span>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text class="mt-4">
      <a ref="hiddenDownloader" class="d-none" />
      <v-col cols="12">
        <v-data-table
          class="transparent"
          multi-sort
          :headers="headers"
          :items="documentos"
          :search="search"
          :options="entitiesPage"
          :server-items-length="totalItems"
          :loading="loading"
          :footer-props="tableFooterProps"
          @update:options="redirectOnTableChange"
        >
          <template v-slot:[`item.titulo`]="{ item }">
            <span style="display: inline-block; width: 80px">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    @click="downloadDocument(item)"
                    v-bind="attrs"
                    v-on="on"
                    ><v-icon color="primary">mdi-download</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("download_file") }}</span>
              </v-tooltip>
              <v-tooltip
                v-if="documentViewerTypes.includes(item.fichero.type)"
                top
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    @click="previewDocument(item)"
                    v-bind="attrs"
                    v-on="on"
                    ><v-icon color="primary">mdi-eye</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("preview_file") }}</span>
              </v-tooltip>
            </span>
            <span
              v-if="documentViewerTypes.includes(item.fichero.type)"
              class="link"
              @click="previewDocument(item)"
              >{{ item.titulo }}</span
            >
            <span v-else class="link" @click="downloadDocument(item)">{{
              item.titulo
            }}</span>
          </template>

          <template v-slot:[`item.fecha`]="{ item }">
            <span v-if="item.fecha">
              {{ item.fecha | dateFormat }}
            </span>
          </template>

          <template
            v-if="isAdmin || isAssemblyManagement"
            v-slot:[`item.action`]="{ item }"
          >
            <v-icon color="warning" @click.stop="editEntity(item)">
              edit
            </v-icon>
            <v-icon color="error" @click.stop="showDeleteDialog(item)">
              delete
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-card-text>

    <document-dialog
      :content="currentDocument"
      :title="currentDocumentTitle"
    ></document-dialog>

    <delete-dialog
      :dialog="deleteDialog"
      @cancel="deleteDialog = false"
      @submit="deleteEntity()"
    ></delete-dialog>
  </v-card>
</template>
<script>
import checkInvalidID from "@/common/checkInvalidID";

import { mapAuthGetter } from "@/common/mapAuthGetter";
import LoadingPage from "@/components/loading-page/LoadingPage.vue";
import DeleteDialog from "@/components/modal_dialog/DeleteDialog";

import RepositoryFactory from "@/repositories/RepositoryFactory";
import { downloadFile, previewFile } from "@/common/file-utils";
import DebouncedTextField from "@/components/debouncing-inputs/DebouncedTextField.vue";
import DocumentDialog from "@/components/modal_dialog/DocumentDialog.vue";
import defaultPaginationSettings from "@/common/default-pagination-settings";
import {
  generateSort,
  parseStringToSortBy,
  parseStringToSortDesc,
} from "@/common/pagination-utils";
import tableFooterProps from "@/common/table-footer-props";
import documentViewerTypes from "@/enumerates/documentviewertypes";
const DocumentoEntityRepository = RepositoryFactory.get(
  "DocumentoEntityRepository"
);

export default {
  name: "DocumentoList",
  components: { DocumentDialog, DebouncedTextField, DeleteDialog },
  data() {
    return {
      loading: false,
      documentos: [],
      search: null,
      totalItems: 0,
      selected: null,
      entitiesPage: {
        page:
          parseInt(this.$route.query.page) || defaultPaginationSettings.page,
        itemsPerPage:
          parseInt(this.$route.query.pageSize) ||
          defaultPaginationSettings.itemsPerPage,
        sortBy: parseStringToSortBy(this.$route.query.sort),
        sortDesc: parseStringToSortDesc(this.$route.query.sort),
      },
      tableFooterProps,
      deleteDialog: false,
      documentViewerTypes,
      currentDocument: null,
      currentDocumentTitle: "",
    };
  },
  computed: {
    ...mapAuthGetter(["isAdmin", "isAssemblyManagement"]),
    isLoading() {
      return this.loading;
    },
    headers() {
      return [
        {
          text: this.$t("t_documento.prop.tipo"),
          value: "tipo.nombre",
        },
        {
          text: this.$t("t_documento.prop.titulo"),
          value: "titulo",
          sortable: false,
        },
        {
          text: this.$t("t_documento.prop.descripcion"),
          value: "descripcion",
          sortable: false,
        },
        {
          text: this.$t("t_documento.prop.fecha"),
          value: "fecha",
        },
        { text: "", sortable: false, value: "action" },
      ];
    },
  },
  created() {
    this.search = this.$route.query.search ? this.$route.query.search : null;
    this.getItems();
  },
  methods: {
    getItems() {
      this.loading = true;
      const options = {
        params: {
          page: this.entitiesPage.page - 1,
          search: this.search,
          sort: this.$route.query.sort,
          size: this.entitiesPage.itemsPerPage,
        },
      };
      return DocumentoEntityRepository.getAll(options)
        .then((res) => {
          this.documentos = res.content;
          this.totalItems = res.totalElements;
        })
        .catch((err) => checkInvalidID(err))
        .finally(() => (this.loading = false));
    },
    redirect(query) {
      if (JSON.stringify(this.$route.query) !== JSON.stringify(query)) {
        this.$router
          .replace({
            name: "Documento List",
            query: query,
          })
          .then(() => this.getItems());
      }
    },
    editEntity(entity) {
      const selection = window.getSelection().toString();
      if (selection.length === 0) {
        this.$router.push({
          name: "Documento FormForm",
          params: { id: entity.id, backPrevious: true },
        });
      }
    },
    redirectOnTableChange(pagination = this.entitiesPage) {
      this.entitiesPage = pagination;
      let query = JSON.parse(JSON.stringify(this.$route.query));
      query.page = this.entitiesPage.page.toString();
      query.pageSize = this.entitiesPage.itemsPerPage.toString();
      query.sort = generateSort(this.entitiesPage);
      query.search = this.search || undefined;
      this.redirect(query);
    },
    redirectOnFilterChange() {
      if (this.entitiesPage.page !== 1) {
        this.entitiesPage.page = 1;
      } else {
        this.redirectOnTableChange();
      }
    },
    showDeleteDialog(entity) {
      this.selected = entity;
      this.deleteDialog = true;
    },
    closeDeleteDialog() {
      this.selected = null;
      this.deleteDialog = false;
    },
    deleteEntity() {
      DocumentoEntityRepository.delete(this.selected.id)
        .then(() => this.getItems())
        .finally(() => this.closeDeleteDialog());
    },
    previewDocument(item) {
      console.log(item);
      this.currentDocument = DocumentoEntityRepository.getFile(item.id);
      this.currentDocumentTitle = item.fichero.fileName;
    },
    downloadDocument(item) {
      DocumentoEntityRepository.getFile(item.id).then((response) => {
        downloadFile(
          response,
          item.fichero.fileName,
          this.$refs.hiddenDownloader
        );
      });
    },
  },
};
</script>
