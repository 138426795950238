import PublicJuntaDirectivaList from "@/modules/juntaDirectiva/components/PublicJuntaDirectivaList.vue";
import juntaDirectivaListList from "@/modules/juntaDirectiva/components/juntaDirectivaListList.vue";
import juntaDirectivaFormForm from "@/modules/juntaDirectiva/components/juntaDirectivaFormForm.vue";
import PublicJuntaDirectivaDetail from "@/modules/juntaDirectiva/components/PublicJuntaDirectivaDetail.vue";

const routes = [
  {
    path: "/xuntas-directivas",
    name: "Public JuntaDirectiva List",
    component: PublicJuntaDirectivaList,
    meta: {
      authority: [
        "ROLE_ADMIN",
        "BOARD_OF_DIRECTORS_READONLY",
        "BOARD_OF_DIRECTORS_MANAGEMENT",
      ],
      label: "t_juntaDirectiva.headers.juntaDirectivaList",
    },
  },
  {
    path: "/xuntas-directivas-admin",
    name: "JuntaDirectiva List",
    component: juntaDirectivaListList,
    meta: {
      authority: ["ROLE_ADMIN", "BOARD_OF_DIRECTORS_MANAGEMENT"],
      label: "t_juntaDirectiva.headers.juntaDirectivaList",
    },
  },
  {
    path: "/xuntas-directivas/:id/editar",
    name: "JuntaDirectiva FormForm",
    component: juntaDirectivaFormForm,
    meta: {
      authority: ["ROLE_ADMIN", "BOARD_OF_DIRECTORS_MANAGEMENT"],
      label: "t_juntaDirectiva.headers.update",
    },
  },
  {
    path: "/xuntas-directivas/nova",
    name: "JuntaDirectiva FormCreate",
    component: juntaDirectivaFormForm,
    meta: {
      authority: ["ROLE_ADMIN", "BOARD_OF_DIRECTORS_MANAGEMENT"],
      label: "t_juntaDirectiva.headers.create",
    },
  },
  {
    path: "/xuntas-directivas/:id",
    name: "Public JuntaDirectiva Detail",
    component: PublicJuntaDirectivaDetail,
    meta: {
      authority: [
        "ROLE_ADMIN",
        "BOARD_OF_DIRECTORS_READONLY",
        "BOARD_OF_DIRECTORS_MANAGEMENT",
      ],
    },
  },
];

export default routes;
