import asambleaListList from "./components/asambleaListList";
import asambleaFormForm from "./components/asambleaFormForm";
import PublicAsambleaList from "./components/PublicAsambleaList.vue";
import PublicAsambleaDetail from "./components/PublicAsambleaDetail.vue";

const routes = [
  {
    path: "/asembleas",
    name: "Public Asamblea List",
    component: PublicAsambleaList,
    meta: {
      userIsLogged: true,
      label: "t_asamblea.headers.asambleaList",
    },
  },
  {
    path: "/asembleas-admin",
    name: "Asamblea List",
    component: asambleaListList,
    meta: {
      authority: ["ROLE_ADMIN", "ASSEMBLY_MANAGEMENT"],
      label: "t_asamblea.headers.asambleaList",
    },
  },
  {
    path: "/asembleas/:id/editar",
    name: "Asamblea FormForm",
    component: asambleaFormForm,
    meta: {
      authority: ["ROLE_ADMIN", "ASSEMBLY_MANAGEMENT"],
      label: "t_asamblea.headers.update",
    },
  },
  {
    path: "/asembleas/nova",
    name: "Asamblea FormCreate",
    component: asambleaFormForm,
    meta: {
      authority: ["ROLE_ADMIN", "ASSEMBLY_MANAGEMENT"],
      label: "t_asamblea.headers.create",
    },
  },
  {
    path: "/asembleas/:id",
    name: "Public Asamblea Detail",
    component: PublicAsambleaDetail,
    meta: { userIsLogged: true },
  },
];

export default routes;
