<template>
  <v-card flat class="transparent">
    <span v-if="!isLoading">
      <v-card-title>
        <v-row align="center" justify="space-between" no-gutters>
          <v-col class="d-none d-md-block">
            <span class="headline no-split-words">
              {{ $t($route.meta.label) }}
            </span>
          </v-col>
          <v-col class="text-right">
            <v-btn @click="back()">
              <v-icon>mdi-arrow-left</v-icon>
              <span class="d-none d-sm-block"> {{ $t("cancel") }} </span>
            </v-btn>
            <v-btn class="success ml-2" @click="save(entity)">
              <v-icon>save</v-icon>
              <span class="d-none d-sm-block"> {{ $t("save") }} </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-row dense>
            <v-col cols="12" md="6">
              <v-select
                class="required"
                dense
                :items="tipoasambleaProperty"
                :item-text="(item) => $t(item.text)"
                item-value="value"
                :menu-props="{ offsetY: true }"
                :rules="[(v) => !!v || $t('error.required')]"
                v-model="entity.tipo"
                :label="$t('t_asamblea.prop.tipo')"
              >
              </v-select>
            </v-col>
            <v-col cols="12" md="6">
              <number-field
                v-model="entity.numero"
                class="required"
                :rules="[(v) => !!v || $t('error.required')]"
                type="integer"
                :label="$t('t_asamblea.prop.numero')"
              ></number-field>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12" md="6">
              <v-text-field
                dense
                v-model="entity.lugar"
                type="text"
                :rules="[]"
                :label="$t('t_asamblea.prop.lugar')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <dateAndHourPicker
                :datePickerProp="{
                  data: entity.fecha,
                  label: $t('t_asamblea.prop.fecha'),
                }"
                :timePickerProp="{
                  data: entity.fecha
                    ? entity.fecha.map((el) => ('0' + el).slice(-2))
                    : entity.fecha,
                  label: $t('t_asamblea.prop.fecha'),
                }"
                @update-time="updateDateTime('fecha', true, ...arguments)"
              >
              </dateAndHourPicker>
            </v-col>
          </v-row>

          <v-row dense no-gutters class="mt-8">
            <v-col cols="12" md="6" class="my-2 pr-md-6">
              <v-row>
                <v-col>
                  <v-btn
                    class="mb-4"
                    color="primary"
                    :disabled="!areLinkRowsComplete"
                    @click="addLink"
                  >
                    <v-icon>mdi-link-plus</v-icon>
                    <span class="d-none d-sm-block">
                      {{ $t("t_asamblea.actions.add_link_to_photos") }}
                    </span>
                  </v-btn>

                  <div v-for="(enlace, index) in enlaces" :key="index">
                    <v-row align="center" no-gutters dense>
                      <v-col cols="5">
                        <v-text-field
                          v-model="enlace.nombre"
                          dense
                          :label="$t('t_asamblea.prop.nombre')"
                          class="ml-2 required"
                          :rules="[(v) => !!v || $t('error.required')]"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          v-model="enlace.url"
                          dense
                          type="url"
                          :label="$t('t_asamblea.prop.url')"
                          class="ml-2 required"
                          :rules="[
                            (v) => !!v || $t('error.required'),
                            (v) =>
                              regex.URL_REGEX.test(v) ||
                              $t('error.regex.urlPattern'),
                          ]"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="1" class="d-flex justify-end">
                        <v-btn
                          icon
                          color="error"
                          @click="deleteDigitalResource('enlaces', index)"
                          ><v-icon>delete</v-icon></v-btn
                        >
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-btn
                    class="mb-4"
                    color="primary"
                    :disabled="!arePhotoRowsComplete"
                    @click="addPhoto"
                  >
                    <v-icon>mdi-camera-plus</v-icon>
                    <span class="d-none d-sm-block">
                      {{ $t("t_asamblea.actions.add_photo") }}
                    </span>
                  </v-btn>

                  <input
                    ref="photoLoader"
                    type="file"
                    hidden
                    :accept="extensions.image"
                    @change="(e) => uploadPhoto(e)"
                  />

                  <div v-for="(foto, index) in fotos" :key="index">
                    <v-row align="center" no-gutters dense>
                      <v-col cols="10">
                        {{ foto.fileName }}
                      </v-col>
                      <v-col cols="2" class="d-flex justify-end">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              color="primary"
                              @click="downloadImage(index)"
                              v-bind="attrs"
                              v-on="on"
                              ><v-icon>mdi-download</v-icon></v-btn
                            >
                          </template>
                          <span>{{ $t("download_image") }}</span>
                        </v-tooltip>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              color="primary"
                              @click="previewImage(index)"
                              v-bind="attrs"
                              v-on="on"
                              ><v-icon>mdi-eye</v-icon></v-btn
                            >
                          </template>
                          <span>{{ $t("preview_image") }}</span>
                        </v-tooltip>
                        <v-btn
                          icon
                          color="error"
                          @click="deleteDigitalResource('fotos', index)"
                          ><v-icon>delete</v-icon></v-btn
                        >
                      </v-col>
                    </v-row>
                    <v-divider v-if="index < fotos.length - 1"></v-divider>
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-divider v-if="$vuetify.breakpoint.mdAndUp" vertical></v-divider>
            <v-col cols="12" md="6" class="my-2 pl-md-6">
              <v-btn
                color="primary"
                :disabled="!areDocumentRowsComplete"
                @click="
                  () => {
                    addDocument();
                    $nextTick(() => {
                      $refs.documentFileLoader[documentos.length - 1].click();
                    });
                  }
                "
              >
                <v-icon>mdi-file-document-plus</v-icon>
                <span class="d-none d-sm-block">
                  {{ $t("t_asamblea.actions.add_document") }}
                </span>
              </v-btn>
              <div
                v-for="(documento, index) in documentos"
                :key="index"
                class="mt-4"
              >
                <v-row dense>
                  <v-col cols="12" md="4" style="overflow-x: hidden">
                    <input
                      :ref="'documentFileLoader'"
                      type="file"
                      hidden
                      :accept="extensions.document"
                      required
                      @change="(e) => updateDocument(index, e)"
                      @cancel="() => deleteDigitalResource('documentos', index)"
                    />
                    <v-btn
                      color="primary"
                      class="my-1"
                      small
                      @click="chooseDocument(index)"
                    >
                      <v-icon>mdi-upload</v-icon>
                      <span class="d-none d-sm-block">
                        {{ $t("select_file") }}
                      </span>
                    </v-btn>
                    <br />
                    <span v-if="documentos[index].fichero" class="ml-2">
                      <v-tooltip
                        v-if="
                          documentViewerTypes.includes(
                            documentos[index].fichero.type
                          )
                        "
                        top
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            @click="previewDocument(documentos[index].fichero)"
                            v-bind="attrs"
                            v-on="on"
                            ><v-icon color="primary">mdi-eye</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t("preview_file") }}</span>
                      </v-tooltip>
                      <span
                        v-if="
                          documentViewerTypes.includes(
                            documentos[index].fichero.type
                          )
                        "
                        class="link"
                        @click="previewDocument(documentos[index].fichero)"
                        >{{ documentos[index].fichero.name }}</span
                      >
                      <span v-else>{{ documentos[index].fichero.name }}</span>
                    </span>
                    <span v-else class="ml-2 error--text">
                      {{ $t("upload.file_required") }}
                    </span>
                  </v-col>
                  <v-col cols="10" md="6" class="pt-1">
                    <v-text-field
                      v-model="documento.data.titulo"
                      dense
                      :label="$t('t_asamblea.prop.nombre')"
                      class="ml-2 required"
                      :rules="[(v) => !!v || $t('error.required')]"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="2" class="pt-1">
                    <v-btn
                      icon
                      color="error"
                      @click="deleteDigitalResource('documentos', index)"
                      ><v-icon>delete</v-icon></v-btn
                    >
                  </v-col>
                </v-row>
                <v-divider v-if="index < documentos.length - 1"></v-divider>
              </div>
            </v-col>
          </v-row>
        </v-form> </v-card-text
    ></span>
    <loading-page v-else></loading-page>

    <v-dialog v-if="previewDialog" persistent fullscreen :value="previewDialog">
      <v-card>
        <v-card-title>
          <v-row dense no-gutters justify="end">
            <v-btn icon @click="closePreviewDialog"
              ><v-icon>close</v-icon></v-btn
            >
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-img :src="selectedImage" max-height="90vh" contain></v-img>
        </v-card-text>
      </v-card>
    </v-dialog>

    <document-dialog
      :content="currentDocument"
      :title="currentDocumentTitle"
    ></document-dialog>

    <a ref="hiddenDownloader" class="d-none" />
  </v-card>
</template>

<script>
import LoadingPage from "@/components/loading-page/LoadingPage.vue";
import checkInvalidID from "@/common/checkInvalidID";

import NumberField from "@/components/number-field/NumberField.vue";

import DateAndHourPicker from "@/components/calendar/DateAndHourPicker.vue";

import tipoasambleaProperty from "@/enumerates/tipoasamblea";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import extensions from "@/common/file-extensions";
import regex from "@/common/regex-validation";
import { downloadFile, uploadFile } from "@/common/file-utils";
import DocumentDialog from "@/components/modal_dialog/DocumentDialog.vue";
import documentViewerTypes from "@/enumerates/documentviewertypes";

const AsambleaEntityRepository = RepositoryFactory.get(
  "AsambleaEntityRepository"
);

export default {
  name: "asambleaFormForm",
  components: {
    DocumentDialog,
    LoadingPage,
    DateAndHourPicker,
    NumberField,
  },
  data() {
    return {
      loading: false,
      entity: {},
      tipoasambleaProperty: tipoasambleaProperty,
      documentos: [],
      fotos: [],
      enlaces: [],
      extensions: extensions,
      regex: regex,
      selectedImage: null,
      previewDialog: false,
      documentViewerTypes,
      currentDocument: null,
      currentDocumentTitle: null,
    };
  },
  beforeRouteUpdate(to, from, next) {
    // si se accede a la misma ruta con diferentes parámetros, se cargará el nuevo objeto
    if (to.params.id) this._fetchData(to.params.id);
    next();
  },
  created() {
    if (this.$route.params.id) this._fetchData(this.$route.params.id);
  },
  computed: {
    isLoading() {
      return this.loading;
    },
    areDocumentRowsComplete() {
      return this.documentos.every((documento) => {
        return documento.data.titulo && documento.fichero;
      });
    },
    arePhotoRowsComplete() {
      return this.fotos.every((foto) => {
        return foto;
      });
    },
    areLinkRowsComplete() {
      return this.enlaces.every((enlace) => {
        return enlace;
      });
    },
  },
  methods: {
    _fetchData(id) {
      this.loading = true;
      return AsambleaEntityRepository.get(id)
        .then((response) => {
          this.entity = response;
          if (this.entity.documentos) {
            this.entity.documentos.forEach((value, i) => {
              this.addDocument();
              AsambleaEntityRepository.getDocumentFile(
                id,
                this.entity.documentos[i].id
              ).then((savedBlob) => {
                this.documentos[i].fichero = new File(
                  [savedBlob],
                  this.entity.documentos[i].fichero.fileName,
                  {
                    type: savedBlob.type,
                  }
                );
                this.documentos[i].data = value;
              });
            });
          }
          this.fotos = this.entity.fotos;
          this.enlaces = this.entity.enlaces;
        })
        .catch((err) => checkInvalidID(err))
        .finally(() => (this.loading = false));
    },
    back() {
      this.$router.push({
        name: "Asamblea List",
        params: { backAction: true },
      });
    },
    updateDateTime(name, hasTime, data) {
      this.entity[name] = data.date
        ? hasTime
          ? data.time
            ? data.date.concat(data.time)
            : data.date.concat([0, 0])
          : data.date
        : null;
    },
    async save() {
      if (!this.$refs.form.validate() || !this.areDocumentRowsComplete) {
        this.$notify({
          type: "error",
          text: this.$t("t_asamblea.error.form-errors"),
        });
        return;
      }

      // Rename original documents
      for (const documento of this.documentos) {
        documento.fichero = new File(
          [documento.fichero],
          documento.data.titulo + documento.fichero.name.match(/\.[^/.]+$/)[0]
        );

        try {
          if (documento) {
            documento.data.fichero = await uploadFile(documento.fichero);
          }
        } catch (e) {
          console.error(e);
          documento.data.fichero = null;
        }
      }

      this.loading = true;
      this.entity.documentos = this.documentos.map((el) => el.data);
      this.entity.fotos = this.fotos;
      this.entity.enlaces = this.enlaces;
      AsambleaEntityRepository.save(this.entity)
        .then(() => {
          if (this.$route.params.id) {
            this.$router.push({
              name: "Public Asamblea Detail",
              params: {
                id: this.entity.id,
                backAction: this.$route.params.backPrevious,
              },
            });
          } else {
            this.$router.push({
              name: "Asamblea List",
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    addDocument() {
      this.documentos.push({
        fichero: null,
        data: {
          titulo: "",
          fichero: null,
        },
      });
    },
    chooseDocument(index) {
      this.$refs["documentFileLoader"][index].click();
    },
    async updateDocument(index, document) {
      if (!document.target.files[0]) return;
      this.documentos[index].fichero = document.target.files[0];

      // Fill title with file name without extension
      this.documentos[index].data.titulo =
        document.target.files[0].name.replace(/\.[^/.]+$/, "");
    },
    addPhoto() {
      this.$refs.photoLoader.click();
    },
    async uploadPhoto(photo) {
      if (!photo.target.files[0]) return;
      try {
        this.fotos.push(await uploadFile(photo.target.files[0]));
        this.fotos[this.fotos.length - 1].content = photo.target.files[0];
      } catch (e) {
        console.log(e);
      }
    },
    previewImage(index) {
      if (!this.entity.id) {
        this.selectedImage = URL.createObjectURL(this.fotos[index].content);
        this.previewDialog = true;
      } else {
        AsambleaEntityRepository.getImage(
          this.entity.id,
          this.fotos[index].uuid
        ).then((savedBlob) => {
          this.selectedImage = URL.createObjectURL(savedBlob);
          this.previewDialog = true;
        });
      }
    },
    downloadImage(index) {
      if (!this.entity.id) {
        this.selectedImage = URL.createObjectURL(this.fotos[index].content);
        downloadFile(
          this.selectedImage,
          this.fotos[index].name,
          this.$refs.hiddenDownloader
        );
      } else {
        AsambleaEntityRepository.getImage(
          this.entity.id,
          this.fotos[index].uuid
        ).then((savedBlob) => {
          downloadFile(
            savedBlob,
            this.fotos[index].fileName,
            this.$refs.hiddenDownloader
          );
        });
      }
    },
    closePreviewDialog() {
      this.previewDialog = false;
      this.selectedImage = null;
    },
    deleteDigitalResource(colection, index) {
      this[colection].splice(index, 1);
    },
    previewDocument(item) {
      this.currentDocument = new Promise((resolve) => {
        resolve(new Blob([item], { type: item.type }));
      });
      this.currentDocumentTitle = item.name;
    },
    addLink() {
      this.enlaces.push({
        nombre: "",
        url: "",
      });
    },
  },
};
</script>
