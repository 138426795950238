export default {
  SP_PROJECT_ASSOCIATION_PAGES: [
    "Presentación",
    "Instalacións",
    "Servizos",
    "Ideario",
    "Xestión de idades",
    "Como asociarse",
    "Organización",
    "Contactar",
  ],
  SP_FINANCES_PAGES: ["Prezos", "Contabilidade"],
};
