import presupuestoListList from "./components/presupuestoListList";

import presupuestoFormForm from "./components/presupuestoFormForm";

const routes = [
  {
    path: "/orzamentos",
    name: "Presupuesto List",
    component: presupuestoListList,
    meta: {
      userIsLogged: true,
      label: "t_presupuesto.headers.presupuestoList",
    },
  },
  {
    path: "/orzamentos/:id/editar",
    name: "Presupuesto FormForm",
    component: presupuestoFormForm,
    meta: {
      authority: ["ROLE_ADMIN", "SP_FINANCES"],
      label: "t_presupuesto.headers.update",
    },
  },
  {
    path: "/orzamentos/novo",
    name: "Presupuesto FormCreate",
    component: presupuestoFormForm,
    meta: {
      authority: ["ROLE_ADMIN", "SP_FINANCES"],
      label: "t_presupuesto.headers.create",
    },
  },
];

export default routes;
