import { HTTP } from "@/common/http-common";
import Logger from "js-logger";

const logger = Logger.get("logger");
const RESOURCE_NAME = "entities/emails";

export default {
  async previewEmail(entity) {
    try {
      return (await HTTP.post(`${RESOURCE_NAME}/preview`, entity)).data;
    } catch (err) {
      logger.error("Error sending email", entity);
      throw err;
    }
  },
  async sendEmail(entity) {
    try {
      return (await HTTP.post(`${RESOURCE_NAME}/send`, entity)).data;
    } catch (err) {
      logger.error("Error sending email", entity);
      throw err;
    }
  },
};
