<template>
  <v-card flat class="transparent">
    <div class="card-wrapper">
      <v-card-title class="text-center">
        <v-row align="center">
          <v-col class="d-none d-md-block">
            <span class="headline no-split-words">
              {{ $t($route.meta.label) }}
            </span>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="mt-4">
        <v-form v-model="validForm">
          <v-row align="center" justify="center">
            <v-col cols="12" sm="6">
              <v-row>
                <v-text-field
                  v-model="username"
                  autocomplete="username"
                  autofocus
                  dense
                  name="username"
                  type="text"
                  prepend-inner-icon="person"
                  :label="$t('account.DNI')"
                  :rules="[(v) => !!v || $t('account.DNIRequired')]"
                  required
                ></v-text-field>
              </v-row>
              <v-row>
                <v-text-field
                  v-model="password"
                  autocomplete="password"
                  dense
                  name="password"
                  prepend-inner-icon="vpn_key"
                  :label="$t('account.password.name')"
                  :rules="[(v) => !!v || $t('account.passwordRequired')]"
                  :type="hidePassword ? 'password' : 'text'"
                  @keydown.enter="userLogin()"
                  required
                >
                  <template v-slot:append>
                    <v-icon
                      v-if="hidePassword"
                      tabindex="-1"
                      @click="hidePassword = !hidePassword"
                    >
                      visibility
                    </v-icon>
                    <v-icon
                      v-else
                      tabindex="-1"
                      @click="hidePassword = !hidePassword"
                    >
                      visibility_off
                    </v-icon>
                  </template>
                </v-text-field>
              </v-row>
              <v-row align="center" justify="end">
                <span
                  class="link font-weight-bold"
                  @click="$router.push({ name: 'ResetPasswordRequest' })"
                >
                  {{ $t("account.forgotPassword") }}</span
                >
              </v-row>
              <v-row>
                <v-checkbox
                  v-model="remember_me"
                  :label="$t('account.rememberMe')"
                ></v-checkbox>
              </v-row>
              <v-row justify="center">
                <v-btn class="primary" :loading="loading" @click="userLogin">
                  {{ $t("account.logIn") }}
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </div>
  </v-card>
</template>

<script>
import auth from "@/common/auth";

export default {
  data() {
    return {
      hidePassword: true,
      loading: false,
      password: "",
      remember_me: false,
      username: "",
      validForm: true,
    };
  },
  methods: {
    userLogin() {
      if (this.validForm) {
        this.loading = true;
        auth
          .login({
            login: this.username,
            password: this.password,
            remember_me: this.remember_me,
          })
          .then(() => {
            return this.$router.push({
              name: "Presentación",
              params: { backAction: true },
            });
          })
          .finally(() => (this.loading = false));
      }
    },
  },
};
</script>
