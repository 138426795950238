<template>
  <v-card flat class="transparent">
    <span v-if="!isLoading">
      <v-card-title class="text-h4">
        {{ $t("t_asamblea.name") }} {{ asamblea.tipo.toLowerCase() }}
        {{ asamblea.numero }}
        <v-row align="center" justify="end" class="my-4">
          <v-btn @click="back()">
            <v-icon>arrow_back</v-icon>
            <span class="d-none d-sm-block"> {{ $t("back") }} </span>
          </v-btn>

          <v-btn
            class="warning ml-2"
            v-if="isAdmin || isAssemblyManagement"
            @click="edit"
          >
            <v-icon>edit</v-icon>
            <span class="d-none d-sm-block"> {{ $t("edit") }} </span>
          </v-btn>

          <v-btn
            class="error ml-2"
            v-if="isAdmin || isAssemblyManagement"
            @click="deleteDialog = true"
          >
            <v-icon>delete</v-icon>
            <span class="d-none d-sm-block"> {{ $t("remove") }} </span>
          </v-btn>
        </v-row>
      </v-card-title>
      <v-card-subtitle class="text-h6 font-weight-light">
        <span v-if="asamblea.lugar"> {{ asamblea.lugar }} , </span>
        <span v-if="asamblea.fecha">
          <!-- Remove hours if they are 00:00 -->
          {{
            formatDateWithHours(dateArrayToDate(asamblea.fecha)).slice(-5) ===
            "00:00"
              ? formatDateWithHours(dateArrayToDate(asamblea.fecha)).slice(
                  0,
                  -5
                )
              : formatDateWithHours(dateArrayToDate(asamblea.fecha))
          }}
        </span>
      </v-card-subtitle>

      <v-card-text>
        <v-tabs class="mt-4" grow background-color="transparent">
          <v-tab>{{ $t("t_asamblea.prop.imagenes") }} </v-tab>
          <v-tab>
            {{ $t("t_asamblea.prop.documentos") }}
          </v-tab>
          <v-tab-item class="pa-4">
            <div v-if="asamblea.enlaces.length > 0">
              <h2>{{ $t("t_asamblea.prop.enlaces") }}</h2>
              <p
                class="my-2"
                v-for="enlace in asamblea.enlaces"
                :key="enlace.url"
              >
                <b>{{ enlace.nombre }}</b> -
                <a :href="enlace.url" target="_blank">{{ enlace.url }}</a>
              </p>
              <v-divider></v-divider>
            </div>
            <v-carousel
              class="mt-3"
              v-if="asamblea.fotos && asamblea.fotos.length > 0"
            >
              <v-carousel-item
                v-for="(item, i) in asamblea.fotos"
                :key="i"
                reverse-transition="fade-transition"
                transition="fade-transition"
              >
                <p class="text-center">{{ item.fileName }}</p>
                <div class="img-container">
                  <img class="img" :src="item.src" alt="" />
                </div>
              </v-carousel-item>
            </v-carousel>
            <div class="mt-3" v-else>
              {{ $t("t_asamblea.headers.no_fotos") }}
            </div></v-tab-item
          >
          <v-tab-item class="pa-4">
            <div v-if="asamblea.documentos && asamblea.documentos.length > 0">
              <a ref="hiddenDownloader" class="d-none" />
              <v-row
                v-for="(item, i) in asamblea.documentos"
                :key="i"
                align="center"
                dense
                no-gutters
              >
                <span style="display: inline-block; width: 80px">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        @click="downloadDocument(item)"
                        v-bind="attrs"
                        v-on="on"
                        ><v-icon color="primary">mdi-download</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t("download_file") }}</span>
                  </v-tooltip>
                  <v-tooltip
                    v-if="documentViewerTypes.includes(item.fichero.type)"
                    top
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        @click="previewDocument(item)"
                        v-bind="attrs"
                        v-on="on"
                        ><v-icon color="primary">mdi-eye</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t("preview_file") }}</span>
                  </v-tooltip>
                </span>
                <span
                  v-if="documentViewerTypes.includes(item.fichero.type)"
                  class="link"
                  @click="previewDocument(item)"
                  >{{ item.titulo }}</span
                >
                <span v-else class="link" @click="downloadDocument(item)">{{
                  item.titulo
                }}</span>
              </v-row>
            </div>
            <span v-else>{{
              $t("t_asamblea.headers.no_documentos")
            }}</span></v-tab-item
          >
        </v-tabs>
      </v-card-text>
    </span>
    <loading-page v-else></loading-page>

    <document-dialog
      :content="currentDocument"
      :title="currentDocumentTitle"
    ></document-dialog>

    <delete-dialog
      :dialog="deleteDialog"
      @cancel="deleteDialog = false"
      @submit="deleteEntity"
    ></delete-dialog>
  </v-card>
</template>

<script>
import checkInvalidID from "@/common/checkInvalidID";
import LoadingPage from "@/components/loading-page/LoadingPage";
import DeleteDialog from "@/components/modal_dialog/DeleteDialog.vue";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import {
  dateArrayToDate,
  formatDateWithHours,
} from "@/common/conversion-utils";
import { downloadFile } from "@/common/file-utils";
import { mapAuthGetter } from "@/common/mapAuthGetter";
import DocumentDialog from "@/components/modal_dialog/DocumentDialog.vue";
import documentViewerTypes from "@/enumerates/documentviewertypes";

const AsambleaEntityRepository = RepositoryFactory.get(
  "AsambleaEntityRepository"
);

export default {
  components: { DocumentDialog, LoadingPage, DeleteDialog },
  data() {
    return {
      loading: false,
      asamblea: {},
      deleteDialog: false,
      documentViewerTypes,
      currentDocument: null,
      currentDocumentTitle: "",
    };
  },
  computed: {
    ...mapAuthGetter(["isAdmin", "isAssemblyManagement"]),
    isLoading() {
      return this.loading;
    },
  },
  created() {
    this._fetchData();
  },
  methods: {
    formatDateWithHours,
    dateArrayToDate,
    _fetchData() {
      this.loading = true;
      AsambleaEntityRepository.get(this.$route.params.id)
        .then((response) => {
          const promises = response.fotos.map((item) => this.getImageUrl(item));
          return Promise.all(promises).then(() => {
            this.asamblea = response;
          });
        })
        .catch((err) => checkInvalidID(err))
        .finally(() => (this.loading = false));
    },
    previewDocument(item) {
      this.currentDocument = AsambleaEntityRepository.getDocumentFile(
        this.asamblea.id,
        item.id
      );
      this.currentDocumentTitle = item.fichero.fileName;
    },
    downloadDocument(item) {
      AsambleaEntityRepository.getDocumentFile(this.asamblea.id, item.id).then(
        (response) => {
          downloadFile(
            response,
            item.fichero.fileName,
            this.$refs.hiddenDownloader
          );
        }
      );
    },
    getImageUrl(item) {
      if (!item.uuid) return Promise.resolve();

      return AsambleaEntityRepository.getImage(this.$route.params.id, item.uuid)
        .then((savedBlob) => {
          item.src = URL.createObjectURL(savedBlob);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    back() {
      this.$router.push({
        name:
          this.isAdmin || this.isAssemblyManagement
            ? "Asamblea List"
            : "Public Asamblea List",
        params: { backAction: true },
      });
    },
    edit() {
      if (!(this.isAdmin || this.isAssemblyManagement)) return;
      this.$router.push({
        name: "Asamblea FormForm",
        params: { id: this.asamblea.id, backPrevious: true },
      });
    },
    deleteEntity() {
      if (!(this.isAdmin || this.isAssemblyManagement)) return;
      this.loading = true;
      return AsambleaEntityRepository.delete(this.asamblea.id)
        .then(() => {
          this.deleteDialog = false;
          this.$router.push({ name: "Asamblea List" });
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style scoped>
.img-container {
  text-align: center;
}
.img {
  max-width: 100%;
  height: 500px;
  object-fit: contain;
}
</style>
