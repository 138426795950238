<template>
  <v-card flat class="transparent">
    <span v-if="!isLoading">
      <v-card-title>
        <v-row align="center" justify="space-between" no-gutters>
          <v-col class="d-none d-md-block">
            <span class="headline no-split-words">
              {{ $t($route.meta.label) }}
            </span>
          </v-col>
          <v-col class="text-right">
            <v-btn @click="back()">
              <v-icon>mdi-arrow-left</v-icon>
              <span class="d-none d-sm-block"> {{ $t("cancel") }} </span>
            </v-btn>
            <v-btn class="success ml-2" @click="save(entity)">
              <v-icon>save</v-icon>
              <span class="d-none d-sm-block"> {{ $t("save") }} </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-row dense>
            <v-col cols="12" md="auto">
              <drag-and-drop-image
                :thumbnail-file="thumbnailFile"
                :thumbnail-url="thumbnailUrl"
                @thumbnail-file="thumbnailFile = $event"
                @thumbnail-url="thumbnailUrl = $event"
              ></drag-and-drop-image>
            </v-col>
            <v-col>
              <v-row dense>
                <v-col cols="12" md="5">
                  <v-text-field
                    v-model="entity.nombre"
                    class="required"
                    dense
                    prepend-inner-icon="mdi-account"
                    type="text"
                    :rules="[
                      (v) => (v && v.trim() !== '') || $t('error.required'),
                    ]"
                    :label="$t('t_socio.prop.nombre')"
                    :disabled="!isAdmin && !isPartnerManagement"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="7">
                  <v-text-field
                    v-model="entity.apellidos"
                    class="required"
                    dense
                    prepend-inner-icon="mdi-account"
                    type="text"
                    :rules="[
                      (v) => (v && v.trim() !== '') || $t('error.required'),
                    ]"
                    :label="$t('t_socio.prop.apellidos')"
                    :disabled="!isAdmin && !isPartnerManagement"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="entity.dni"
                    class="required"
                    dense
                    prepend-inner-icon="mdi-id-card"
                    type="text"
                    :rules="[
                      (v) =>
                        !v ||
                        regex.NIF_REGEX.test(v) ||
                        $t('error.regex.nifPattern'),
                      (v) => !!v || $t('error.required'),
                    ]"
                    :label="$t('t_socio.prop.dni')"
                    @input="entity.dni = entity.dni.toUpperCase()"
                    :disabled="!isAdmin && !isPartnerManagement"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="entity.telefono"
                    class="required"
                    dense
                    prepend-inner-icon="mdi-phone"
                    type="text"
                    :rules="[
                      (v) => (v && v.trim() !== '') || $t('error.required'),
                    ]"
                    :label="$t('t_socio.prop.telefono')"
                    :disabled="!isAdmin && !isPartnerManagement"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="entity.email"
                    class="required"
                    dense
                    prepend-inner-icon="mdi-email"
                    type="text"
                    :rules="[
                      (v) =>
                        !v ||
                        regex.EMAIL_REGEX.test(v) ||
                        $t('error.regex.emailPattern'),
                      (v) => !!v || $t('error.required'),
                    ]"
                    :label="$t('t_socio.prop.email')"
                    :disabled="!isAdmin && !isPartnerManagement"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <date-and-hour-picker
                    :datePickerProp="{
                      data: birthDate,
                      label: $t('t_socio.prop.fechaNac'),
                    }"
                    :max-date="today"
                    @update-time="updateBirthDate(...arguments)"
                    :disabled="!isAdmin && !isPartnerManagement"
                  ></date-and-hour-picker>
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="entity.profesion"
                    dense
                    prepend-inner-icon="mdi-briefcase"
                    :label="$t('t_socio.prop.profesion')"
                    :rules="[]"
                    :disabled="!isAdmin && !isPartnerManagement"
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <v-select
                    v-model="entity.sexo"
                    dense
                    :items="sexoProperty"
                    :item-text="(item) => $t(item.text)"
                    item-value="value"
                    prepend-inner-icon="mdi-gender-male-female"
                    :menu-props="{ offsetY: true }"
                    :label="$t('t_socio.prop.sexo')"
                    :rules="[]"
                    :disabled="!isAdmin && !isPartnerManagement"
                  >
                  </v-select>
                </v-col>

                <v-col cols="12" md="7">
                  <v-text-field
                    v-model.trim="entity.direccion.direccion"
                    dense
                    prepend-inner-icon="mdi-home"
                    type="text"
                    :rules="[]"
                    :label="$t('t_socio.prop.direccion.direccion')"
                    :disabled="!isAdmin && !isPartnerManagement"
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" md="5">
                  <number-field
                    v-model.trim="entity.direccion.codigoPostal"
                    type="integer"
                    :rules="[]"
                    prepend-inner-icon="mdi-home"
                    :label="$t('t_socio.prop.direccion.cp')"
                    :disabled="!isAdmin && !isPartnerManagement"
                  >
                  </number-field>
                </v-col>

                <v-col cols="12" md="7">
                  <v-text-field
                    v-model.trim="entity.direccion.localidad"
                    dense
                    prepend-inner-icon="mdi-home"
                    type="text"
                    :rules="[]"
                    :label="$t('t_socio.prop.direccion.localidad')"
                    :disabled="!isAdmin && !isPartnerManagement"
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" md="5">
                  <v-text-field
                    v-model.trim="entity.direccion.provincia"
                    dense
                    prepend-inner-icon="mdi-home"
                    type="text"
                    :rules="[]"
                    :label="$t('t_socio.prop.direccion.provincia')"
                    :disabled="!isAdmin && !isPartnerManagement"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-tabs class="mt-4" grow background-color="transparent">
            <v-tab>{{ $t("t_socio.prop.documentos") }}</v-tab>
            <v-tab>{{ $t("t_socio.prop.pagos") }}</v-tab>
            <v-tab-item class="pa-4">
              <socio-form-document
                :content="documentos"
                @update-documents="updateDocuments"
                @preview-document="previewDocument"
                @download-document="downloadDocument"
              ></socio-form-document>
            </v-tab-item>

            <v-tab-item class="pa-4">
              <socio-form-payment
                :content="pagos"
                @update-payments="updatePayments"
                @preview-document="previewDocument"
                @download-document="downloadDocument"
              ></socio-form-payment>
            </v-tab-item>
          </v-tabs>
        </v-form> </v-card-text
    ></span>
    <loading-page v-else></loading-page>

    <document-dialog
      :content="previewedDocument"
      :title="previewedDocumentTitle"
    ></document-dialog>

    <a ref="hiddenDownloader" class="d-none" />
  </v-card>
</template>

<script>
import LoadingPage from "@/components/loading-page/LoadingPage.vue";
import checkInvalidID from "@/common/checkInvalidID";
import regex from "@/common/regex-validation";
import sexoProperty from "@/enumerates/sexo";

import DateAndHourPicker from "@/components/calendar/DateAndHourPicker.vue";

import { downloadFile, resizeThumbnail, uploadFile } from "@/common/file-utils";
import { getTodayDateAsArray } from "@/common/conversion-utils";
import NumberField from "@/components/number-field/NumberField.vue";
import DocumentDialog from "@/components/modal_dialog/DocumentDialog.vue";
import SocioFormDocument from "@/modules/socio/components/socioFormDocument.vue";
import SocioFormPayment from "@/modules/socio/components/socioFormPayment.vue";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import extensions from "@/common/file-extensions";
import { mapAuthGetter } from "@/common/mapAuthGetter";
import DragAndDropImage from "@/components/drag-and-drop/DragAndDropImage.vue";

const SocioEntityRepository = RepositoryFactory.get("SocioEntityRepository");

export default {
  name: "socioFormForm",
  components: {
    DragAndDropImage,
    SocioFormPayment,
    SocioFormDocument,
    DocumentDialog,
    NumberField,
    LoadingPage,
    DateAndHourPicker,
  },
  data() {
    return {
      entity: {},
      birthDate: null,
      documentos: [],
      pagos: [],
      loading: false,
      thumbnailFile: null,
      thumbnailUrl: null,
      extensions,
      regex,
      sexoProperty,
      today: new Date().toISOString(),
      previewedDocument: null,
      previewedDocumentTitle: null,
    };
  },
  beforeRouteUpdate(to, from, next) {
    // si se accede a la misma ruta con diferentes parámetros, se cargará el nuevo objeto
    if (to.params.id) this._fetchData(to.params.id);
    next();
  },
  created() {
    if (this.$route.params.id) {
      this._fetchData(this.$route.params.id);
    } else {
      this.entity.direccion = {};
    }
  },
  computed: {
    ...mapAuthGetter(["isAdmin", "isPartnerManagement", "userId"]),
    isLoading() {
      return this.loading;
    },
  },
  methods: {
    _fetchData(id) {
      this.loading = true;
      return SocioEntityRepository.get(id)
        .then((response) => {
          this.entity = response;
          this.entity.direccion = this.entity.direccion ?? {};
          if (
            this.entity.anoNac !== null &&
            this.entity.mesNac !== null &&
            this.entity.diaNac !== null
          ) {
            this.birthDate = [
              this.entity.anoNac,
              this.entity.mesNac,
              this.entity.diaNac,
            ];
          }
          if (this.entity.thumbnail) {
            SocioEntityRepository.getThumbnail(id).then((savedBlob) => {
              this.thumbnailFile = JSON.parse(
                JSON.stringify(this.entity.thumbnail)
              );
              this.thumbnailUrl = URL.createObjectURL(savedBlob);
            });
          }
          if (this.entity.documentos) {
            this.entity.documentos.forEach((documento, i) => {
              this.addEmptyDocument();
              this.documentos[i].data = { ...documento };
              SocioEntityRepository.getDocumentFile(
                id,
                this.entity.documentos[i].id
              ).then((savedBlob) => {
                this.documentos[i].fichero = new File(
                  [savedBlob],
                  this.entity.documentos[i].fichero.fileName,
                  {
                    type: savedBlob.type,
                  }
                );
              });
            });
          }
          if (this.entity.pagos) {
            this.entity.pagos.forEach((pago, i) => {
              this.addEmptyPayment();
              this.pagos[i].data = { ...pago };
              this.pagos[i].data.documentos.forEach((doc, j) => {
                SocioEntityRepository.getPaymentFile(
                  id,
                  pago.id,
                  doc.uuid
                ).then((savedBlob) => {
                  this.pagos[i].ficheros.push(
                    new File(
                      [savedBlob],
                      this.pagos[i].data.documentos[j].fileName,
                      {
                        type: savedBlob.type,
                      }
                    )
                  );
                });
              });
            });
          }
        })
        .catch((err) => {
          console.log(err);
          checkInvalidID(err);
        })
        .finally(() => (this.loading = false));
    },
    back() {
      if (this.$route.params.id) {
        this.$router.push({
          name: "Socio FormDetail",
          params: { backAction: true },
        });
      } else {
        this.$router.push({ name: "Socio List" });
      }
    },
    updateBirthDate(data) {
      this.birthDate = data.date;
      this.entity.anoNac = data.date ? data.date[0] : null;
      this.entity.mesNac = data.date ? data.date[1] : null;
      this.entity.diaNac = data.date ? data.date[2] : null;
    },
    updateDocuments(documents) {
      this.documentos = documents;
    },
    updatePayments(payments) {
      this.pagos = payments;
    },
    async save() {
      if (!this.$refs.form.validate()) {
        this.$notify({
          type: "error",
          text: this.$t("t_socio.error.form-errors"),
        });
        return;
      }
      this.loading = true;
      this.entity.thumbnail = this.thumbnailFile;
      this.entity.documentos = this.documentos.map((el) => el.data);
      this.entity.pagos = this.pagos.map((el) => el.data);
      SocioEntityRepository.save(this.entity)
        .then(() => {
          if (this.$route.params.id) {
            this.$router.push({
              name: "Socio FormDetail",
              params: {
                id: this.entity.id,
                backAction: this.$route.params.backPrevious,
              },
            });
          } else {
            this.$router.push({ name: "Socio List" });
          }
        })
        .finally(() => (this.loading = false));
    },
    addEmptyDocument() {
      this.documentos.push({
        fichero: null,
        data: {
          titulo: "",
          fecha: getTodayDateAsArray(),
          tipo: null,
          fichero: null,
        },
      });
    },
    addEmptyPayment() {
      this.pagos.push({
        ficheros: [],
        data: {
          tipo: null,
          importe: null,
          fecha: getTodayDateAsArray(),
          documentos: [],
        },
      });
    },
    previewDocument(file) {
      this.previewedDocument = new Promise((resolve) => {
        resolve(new Blob([file], { type: file.type }));
      });
      this.previewedDocumentTitle = file.name;
    },
    downloadDocument(file) {
      downloadFile(
        new Blob([file], { type: file.type }),
        file.name,
        this.$refs.hiddenDownloader
      );
    },
  },
};
</script>
