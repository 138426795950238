<template>
  <v-dialog :value="showDialog" persistent fullscreen>
    <v-card>
      <div v-if="showDialog">
        <v-card-title>
          <span>{{ title }}</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeDialog"><v-icon>close</v-icon></v-btn>
        </v-card-title>
        <v-card-text v-if="!isLoading">
          <vue-doc-preview
            v-if="documentText && document.type === 'text/plain'"
            :value="documentText"
            type="text"
            style="overflow: auto"
          ></vue-doc-preview>
          <embed
            v-else-if="document && document.type === 'application/pdf'"
            ref="embed"
            :src="documentUrl"
            style="width: 100%"
            @load="resizeEmbed"
          />
          <p v-else>
            {{
              $t("error.preview_file", { value: title.match(/\.[^/.]+$/)[0] })
            }}
          </p>
        </v-card-text>
        <loading-page v-else></loading-page>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import LoadingPage from "@/components/loading-page/LoadingPage.vue";
import VueDocPreview from "vue-doc-preview";
import officeMimeTypes from "@/enumerates/documentviewertypes";

export default {
  name: "DocumentDialog",
  components: { LoadingPage, VueDocPreview },
  props: {
    title: {
      type: String,
      default: "",
    },
    content: {
      type: Promise,
      default: null,
    },
  },
  data() {
    return {
      showDialog: false,
      isLoading: true,
      document: null,
      documentText: "",
      officeMimeTypes,
    };
  },
  computed: {
    documentUrl() {
      return this.document ? URL.createObjectURL(this.document) : "";
    },
  },
  watch: {
    content(newValue) {
      if (newValue) {
        this.showDialog = true;
        this.isLoading = true;
        this.content
          .then((response) => {
            this.document = response;
            if (this.document.type === "text/plain") {
              response.text().then((text) => {
                this.documentText = text;
              });
            }
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
  },
  methods: {
    closeDialog() {
      this.showDialog = false;
    },
    resizeEmbed() {
      const embed = this.$refs.embed;
      if (embed) {
        embed.style.height =
          "calc(" + window.document.body.scrollHeight + "px - 90px)";
      }
    },
  },
};
</script>
