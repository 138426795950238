var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.items)?_c('v-card',{staticClass:"transparent",attrs:{"flat":""}},[_c('v-card-title',[_c('v-row',{attrs:{"align":"center","justify":"space-between","no-gutters":""}},[_c('v-col',{staticClass:"d-none d-md-block"},[_c('span',{staticClass:"headline no-split-words"},[_vm._v(" "+_vm._s(_vm.$t(_vm.$route.meta.label))+" ")])]),_c('v-col',{staticClass:"d-md-inline-block text-right mt-4 mt-md-0",attrs:{"cols":"12","md":"4","lg":"6","xl":"8","order":"2","order-md":"1"}},[_c('debounced-text-field',{staticClass:"d-md-inline-block",attrs:{"append-icon":"search","dense":"","hide-details":"","label":_vm.$t('search')},on:{"input":_vm.redirect},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"10","offset-md":"1"}},[_c('v-row',{attrs:{"dense":"","align":"stretch"}},_vm._l((_vm.items),function(item,index){return _c('v-col',{key:index,attrs:{"cols":"12","sm":"6","md":"4","lg":"2"}},[_c('v-card',{attrs:{"outlined":"","height":"100%"}},[_c('v-img',{class:{
                  'align-center': true,
                  'mx-auto': true,
                  'row-pointer': item.thumbnail,
                },attrs:{"contain":"","src":item.thumbnail
                    ? item.thumbnail
                    : require('@/assets/profile-placeholder.webp'),"width":"150px","height":"150px","alt":"Perfil"},on:{"click":function($event){$event.stopPropagation();return _vm.previewImage(item)}}}),_c('v-card-title',{staticClass:"text-body-1 px-2 py-1",staticStyle:{"word-break":"break-word"}},[_c('v-row',{attrs:{"dense":"","align":"center"}},[_c('v-col',[_c('span',[_vm._v(_vm._s(item.apellidos)+", "+_vm._s(item.nombre))])]),(_vm.userId === item.id)?_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":"primary","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.entityDetail(item)}}},[_c('v-icon',[_vm._v("description")])],1)],1):_vm._e()],1)],1)],1)],1)}),1)],1)],1)],1),_c('v-dialog',{attrs:{"value":_vm.previewDialog,"persistent":"","fullscreen":""}},[_c('v-card',[(_vm.previewDialog)?_c('div',[_c('v-card-title',[_c('v-row',{attrs:{"dense":"","no-gutters":"","justify":"end"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.closePreviewDialog}},[_c('v-icon',[_vm._v("close")])],1)],1)],1),_c('v-card-text',[_c('v-img',{attrs:{"src":_vm.selectedImage,"max-height":"90vh","contain":""}})],1)],1):_vm._e()])],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }