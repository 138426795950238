<template>
  <v-card flat class="transparent">
    <v-card-title class="pb-0 pt-0 pt-md-4">
      <v-row align="center" no-gutters>
        <v-col cols="6" class="d-none d-md-block">
          <span class="headline no-split-words">
            {{ $t($route.meta.label) }}
          </span>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text class="mt-6">
      <v-form ref="form">
        <v-row align="center" justify="center">
          <v-col cols="12" sm="6">
            <v-row>
              <v-autocomplete
                v-model="user"
                class="required"
                dense
                prepend-inner-icon="mdi-id-card"
                :loading="partnersLoading"
                :items="partnersDNI"
                item-text="fullName"
                :label="$t('t_socio.prop.dni')"
                :menu-props="{ offsetY: true }"
                return-object
                :rules="[(v) => !!v || $t('error.required')]"
              ></v-autocomplete>
            </v-row>
            <v-row>
              <v-text-field
                class="form-control required"
                v-model="newPassword"
                name="newPassword"
                :disabled="!user.dni"
                prepend-inner-icon="vpn_key"
                :append-icon="hideNewPassword ? 'visibility' : 'visibility_off'"
                :label="$t('account.newPassword')"
                :rules="[
                  (v) => !!v || $t('account.passwordRequired'),
                  newBetweenMinAndMax,
                ]"
                :type="hideNewPassword ? 'password' : 'text'"
                @click:append="() => (hideNewPassword = !hideNewPassword)"
                required
                @input="validatePasswordMatch"
              ></v-text-field>
            </v-row>
            <v-row>
              <v-text-field
                class="form-control required"
                v-model="newPassword2"
                ref="newPassword2"
                name="newPassword2"
                :disabled="!user.dni"
                prepend-inner-icon="vpn_key"
                :append-icon="
                  hideNewPassword2 ? 'visibility' : 'visibility_off'
                "
                :label="$t('account.repeatPassword')"
                :rules="[
                  (v) => !!v || $t('account.passwordRequired'),
                  passwordConfirmationRule,
                ]"
                :type="hideNewPassword2 ? 'password' : 'text'"
                @click:append="() => (hideNewPassword2 = !hideNewPassword2)"
                @keypress.enter="submitNewPassword"
                required
                @input="validatePasswordMatch"
              ></v-text-field>
            </v-row>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-btn
            class="success mt-4"
            :loading="loading"
            :disabled="!user.dni"
            @click="submitNewPassword"
          >
            {{ $t("account.resetPassword") }}
          </v-btn>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import RepositoryFactory from "@/repositories/RepositoryFactory";
const SocioEntityRepository = RepositoryFactory.get("SocioEntityRepository");
const UserManagementRepository = RepositoryFactory.get(
  "UserManagementRepository"
);

export default {
  name: "ManagePassword",
  data() {
    return {
      loading: false,
      partners: [],
      partnersLoading: false,
      user: {
        dni: null,
      },
      newPassword: "",
      newPassword2: "",
      hideNewPassword: true,
      hideNewPassword2: true,
    };
  },
  computed: {
    partnersDNI() {
      return this.partners.map((p) => ({
        fullName: `${p.dni} (${p.nombre} ${p.apellidos})`,
        ...p,
      }));
    },
    passwordConfirmationRule() {
      return () =>
        this.newPassword === this.newPassword2 ||
        this.$t("account.passwordMatch");
    },
    newBetweenMinAndMax() {
      return () =>
        (this.newPassword.length > 4 && this.newPassword.length < 100) ||
        this.$t("account.passwordMoreChars");
    },
  },
  created() {
    this.getPartners();
  },
  methods: {
    getPartners() {
      this.partnersLoading = true;
      SocioEntityRepository.getAllWithAuthorities({
        params: { getPartners: true, getSuspended: false },
      })
        .then((res) => (this.partners = res))
        .finally(() => (this.partnersLoading = false));
    },
    validatePasswordMatch() {
      if (this.newPassword2 !== "") {
        if (this.newPassword !== this.newPassword2) {
          this.$refs.newPassword2.validate(true);
        } else {
          this.$refs.newPassword2.validate(false);
        }
      }
    },
    submitNewPassword() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        UserManagementRepository.changePassword({
          login: this.user.dni,
          password: this.newPassword,
        })
          .then((res) => {
            if (!res.isAxiosError) {
              this.$notify({
                title: this.$t("account.changePassword"),
                text: this.$t("account.passwordChanged"),
                type: "success",
              });
            }
            this.$router.back();
          })
          .finally(() => (this.loading = false));
      }
    },
  },
};
</script>
