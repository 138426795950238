<template>
  <v-app>
    <notifications :max="3" :width="350" position="top center">
      <template v-slot:body="props">
        <div class="notification d-inline-flex">
          <v-col cols="2" align-self="center" class="px-0 py-0">
            <v-icon :color="props.item.type || 'info'" large>
              {{ getIcon(props.item.type) }}
            </v-icon>
          </v-col>
          <v-col cols="10">
            <v-row>
              <span class="title">
                {{ props.item.title }}
              </span>
            </v-row>
            <v-row>
              <div v-html="props.item.text"></div>
            </v-row>
          </v-col>
        </div>
      </template>
    </notifications>

    <v-card flat style="border-radius: 0; height: 100%">
      <v-card-title class="pb-0" style="cursor: pointer" @click="goToHomePage">
        <v-row align="center" justify="center">
          <v-img src="@/assets/logolar.webp" class="logo"></v-img>
          <h1
            class="font-weight-bold text-sm-h1 app-title ml-8"
            style="color: #5f4770"
          >
            LarLilás
          </h1>
        </v-row>
      </v-card-title>
      <v-card-subtitle class="mt-8 px-0">
        <MenuBar>
          <!-- -->
        </MenuBar>
      </v-card-subtitle>
      <v-card-text class="cuerpo">
        <v-main style="padding: 0 !important">
          <router-view>
            <!-- -->
          </router-view>
        </v-main>
      </v-card-text>
    </v-card>
  </v-app>
</template>

<script>
import MenuBar from "./components/MenuBar";

export default {
  name: "App",
  components: { MenuBar },
  methods: {
    goToHomePage() {
      if (this.$route.name !== "Presentación") {
        this.$router.push({ name: "Presentación" });
      }
    },
    getIcon(type) {
      const icons = {
        success: "done",
        warning: "warning",
        error: "error",
        info: "info",
      };
      return icons[type] || "info";
    },
  },
};
</script>
<style>
.app-title {
  white-space: pre-wrap;
  word-break: break-word;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.logo {
  max-width: 80px;
  min-width: 80px;
}
.cuerpo {
  padding: 0 20px;
  min-height: calc(100vh - 180px);
  background-image: url("/fondo-casa.webp");
  background-size: 1000px;
  background-position: top right;
}
</style>
