<template>
  <v-card flat class="transparent">
    <span v-if="!isLoading">
      <v-card-title class="text-center">
        <v-row no-gutters>
          <v-col>
            <span class="headline no-split-words" v-if="static_page">
              {{ static_page.definedId }}
            </span>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-subtitle v-if="canEdit" class="text-right">
        <v-btn class="warning ml-2" @click="edit(static_page)">
          <v-icon class="material-icons details-close-button">edit</v-icon>
          <span class="d-none d-sm-block"> {{ $t("edit") }} </span>
        </v-btn>
      </v-card-subtitle>
      <v-card-text style="max-width: 1280px; margin: auto">
        <span
          class="page-content"
          v-html="translation.body"
        ></span> </v-card-text
    ></span>
    <loading-page v-else></loading-page>
  </v-card>
</template>

<script>
import checkInvalidID from "@/common/checkInvalidID";
import RepositoryFactory from "@/repositories/RepositoryFactory";
const StaticPageRepository = RepositoryFactory.get("Static_PageRepository");
import { mapAuthGetter } from "@/common/mapAuthGetter";
import LoadingPage from "@/components/loading-page/LoadingPage.vue";
import UserPermissionsInSP from "@/components/static_editor/UserPermissionsInSP";
import replaceOembeds from "@/common/replaceOembeds";

export default {
  name: "Static_PageDetail",
  components: { LoadingPage },
  data() {
    return {
      static_page: null,
      loading: false,
    };
  },
  computed: {
    ...mapAuthGetter(["isAdmin", "isSpProjectAssociation", "isSpFinances"]),
    isLoading() {
      return this.loading;
    },
    translation() {
      if (!this.static_page || !this.static_page.translations)
        return { body: null, description: null };
      else {
        const translation = this.static_page.translations.find(
          (el) => el.language.toLowerCase() === this.$i18n.locale.toLowerCase()
        );
        if (translation) {
          translation.body = replaceOembeds(translation.body);
        }
        return translation;
      }
    },
    canEdit() {
      return (
        this.isAdmin ||
        (this.isSpProjectAssociation &&
          UserPermissionsInSP.SP_PROJECT_ASSOCIATION_PAGES.includes(
            this.$route.name
          )) ||
        (this.isSpFinances &&
          UserPermissionsInSP.SP_FINANCES_PAGES.includes(this.$route.name))
      );
    },
  },
  watch: {
    "$i18n.locale"() {
      this._fetchData();
    },
    "$route.name"() {
      this._fetchData();
    },
  },
  created() {
    this._fetchData();
  },
  methods: {
    edit() {
      this.$router.push({
        name: "Static_PageForm",
        params: { id: this.static_page.definedId, backPrevious: true },
      });
    },
    _fetchData() {
      this.loading = true;
      const page = this.$route.name ? this.$route.name : "Presentación";
      StaticPageRepository.get(page)
        .then((res) => (this.static_page = res))
        .catch((err) => checkInvalidID(err))
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style>
.page-content p {
  margin: 0;
}
.page-content table,
.page-content th,
.page-content td {
  border: 1px solid #ccc;
  border-collapse: collapse;
  padding: 0.5em;
}
.page-content th {
  background-color: #eee;
}
.page-content > blockquote {
  overflow: hidden;
  padding-right: 1.5em;
  padding-left: 1.5em;
  font-style: italic;
  border-left: 5px solid #ccc;
}
</style>
