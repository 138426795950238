import documentoFormForm from "./components/documentoFormForm";
import DocumentoList from "./components/DocumentoList.vue";

const routes = [
  {
    path: "/documentos",
    name: "Documento List",
    component: DocumentoList,
    meta: {
      userIsLogged: true,
      label: "t_documento.headers.documentoList",
    },
  },
  {
    path: "/documentos/:id/editar",
    name: "Documento FormForm",
    component: documentoFormForm,
    meta: {
      authority: ["ROLE_ADMIN", "ASSEMBLY_MANAGEMENT"],
      label: "t_documento.headers.update",
    },
  },
  {
    path: "/documentos/novo",
    name: "Documento FormCreate",
    component: documentoFormForm,
    meta: {
      authority: ["ROLE_ADMIN", "ASSEMBLY_MANAGEMENT"],
      label: "t_documento.headers.create",
    },
  },
];

export default routes;
