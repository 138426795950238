import eventoListList from "./components/eventoListList";
import eventoFormForm from "./components/eventoFormForm";
import PublicEventoList from "./components/PublicEventoList.vue";
import PublicEventoDetail from "@/modules/evento/components/PublicEventoDetail.vue";

const routes = [
  {
    path: "/eventos-admin",
    name: "Admin Evento List",
    component: eventoListList,
    meta: {
      authority: ["ROLE_ADMIN", "EVENT_MANAGEMENT"],
      label: "t_evento.headers.adminEventoList",
    },
  },
  {
    path: "/eventos",
    name: "Public Evento List",
    component: PublicEventoList,
    meta: {
      public: true,
      label: "t_evento.headers.publicEventoList",
    },
  },
  {
    path: "/eventos-socios",
    name: "Partner Evento List",
    component: PublicEventoList,
    meta: {
      userIsLogged: true,
      label: "t_evento.headers.partnerEventoList",
    },
  },
  {
    path: "/eventos/novo",
    name: "Evento FormCreate",
    component: eventoFormForm,
    meta: {
      authority: ["ROLE_ADMIN", "EVENT_MANAGEMENT"],
      label: "t_evento.headers.new_evento",
    },
  },
  {
    path: "/eventos/:id",
    name: "Public Evento Detail",
    component: PublicEventoDetail,
    meta: {
      public: true,
    },
  },
  {
    path: "/eventos/:id/editar",
    name: "Evento FormForm",
    component: eventoFormForm,
    meta: {
      authority: ["ROLE_ADMIN", "EVENT_MANAGEMENT"],
      label: "t_evento.headers.update_evento",
    },
  },
];

export default routes;
