<template>
  <v-card flat class="transparent">
    <div class="card-wrapper">
      <v-card-title class="pb-0 pt-0 pt-md-4 text-center">
        <v-row align="center" no-gutters>
          <v-col class="d-none d-md-block">
            <span class="headline no-split-words">
              {{ $t($route.meta.label) }}
            </span>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="mt-4">
        <v-form v-model="validForm">
          <v-row align="center" justify="center" dense no-gutters>
            <v-col cols="12" sm="6">
              <v-row justify="center" class="text-center" dense no-gutters>
                <span>
                  {{ $t("account.enterDNI") }}
                </span>
              </v-row>
              <v-row dense no-gutters>
                <v-text-field
                  v-model="login"
                  class="mt-4 required"
                  dense
                  name="login"
                  prepend-inner-icon="person"
                  type="email"
                  :label="$t('account.DNI')"
                  :rules="[
                    (v) => (v && v.trim() !== '') || $t('account.DNIRequired'),
                  ]"
                  @keydown.enter="resetPassword"
                  required
                ></v-text-field>
              </v-row>
              <v-row justify="center">
                <v-btn
                  class="primary mt-4"
                  :loading="loading"
                  @click="resetPassword"
                >
                  {{ $t("account.resetPassword") }}
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </div>
  </v-card>
</template>

<script>
import RepositoryFactory from "@/repositories/RepositoryFactory";
const AccountRepository = RepositoryFactory.get("AccountRepository");

export default {
  data() {
    return {
      loading: false,
      validForm: true,
      login: "",
    };
  },
  methods: {
    resetPassword() {
      if (this.validForm) {
        this.loading = true;
        AccountRepository.resetPasswordInit(this.login)
          .then(() => {
            this.$router.replace({ name: "Login" });
            this.$notify({
              type: "success",
              title: this.$t("account.resetPassword"),
              text: this.$t("account.resetPasswordInitiated"),
            });
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>
