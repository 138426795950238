<template>
  <!-- Payment dialog -->
  <v-dialog persistent v-model="paymentDialog" max-width="1000">
    <div v-if="paymentDialog">
      <v-card>
        <v-card-title>{{
          isEditingPayment
            ? $t("t_pago.actions.edit")
            : $t("t_pago.actions.add")
        }}</v-card-title>

        <v-card-text>
          <div v-if="currentPayment" class="mt-4">
            <v-form ref="paymentForm">
              <v-row dense>
                <v-col cols="12" md="4" class="pt-1">
                  <v-select
                    v-model="currentPayment.data.tipo"
                    class="required"
                    dense
                    :items="tiposPago"
                    :item-text="(item) => $t(item.text)"
                    item-value="value"
                    :menu-props="{ offsetY: true }"
                    :rules="[(v) => !!v || $t('error.required')]"
                    :label="$t('t_pago.prop.tipo')"
                  >
                  </v-select>
                </v-col>
                <v-col cols="6" md="4" class="pt-1">
                  <number-field
                    v-model.number="currentPayment.data.importe"
                    class="ml-2 required"
                    :rules="[(v) => !!v || $t('error.required')]"
                    type="double"
                    suffix="€"
                    :label="$t('t_pago.prop.importe')"
                  ></number-field>
                </v-col>

                <v-col cols="6" md="4" class="pt-1">
                  <date-and-hour-picker
                    class="ml-2 required"
                    :datePickerProp="{
                      data: currentPayment.data.fecha,
                      label: $t('t_pago.prop.fecha'),
                      mandatory: true,
                    }"
                    :rules="[(v) => !!v || $t('error.required')]"
                    @update-time="setPaymentDate(...arguments)"
                  ></date-and-hour-picker>
                </v-col>
              </v-row>
            </v-form>
            <v-row dense no-gutters align="center" class="mt-2">
              <div class="d-flex" style="width: 100%">
                <input
                  :ref="'paymentFileLoader'"
                  type="file"
                  hidden
                  :accept="extensions.document"
                  @change="(e) => addPaymentDocument(e)"
                />
                <v-btn color="primary" small @click="choosePaymentDocument()">
                  <v-icon>mdi-upload</v-icon>
                  <span class="d-none d-sm-block">
                    {{ $t("t_pago.actions.add_document") }}
                  </span>
                </v-btn>

                <div class="ml-4" style="overflow: auto">
                  <span
                    v-for="(documento, j) in currentPayment.ficheros"
                    :key="j"
                  >
                    <div>
                      <v-tooltip
                        v-if="documentViewerTypes.includes(documento.type)"
                        top
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            @click="previewDocument(documento)"
                            v-bind="attrs"
                            v-on="on"
                            ><v-icon color="primary">mdi-eye</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t("preview_file") }}</span>
                      </v-tooltip>
                      <span
                        v-if="documentViewerTypes.includes(documento.type)"
                        class="link"
                        @click="previewDocument(documento)"
                        >{{ documento.name }}</span
                      >
                      <span v-else>{{ documento.name }}</span>
                      <v-btn
                        icon
                        color="error"
                        @click="deletePaymentDocument(j)"
                        ><v-icon>delete</v-icon></v-btn
                      >
                    </div>
                  </span>
                </div>
              </div>
            </v-row>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="closePaymentDialog()">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn color="success" @click="savePayment()">
            <v-icon>save</v-icon>
            <span class="d-none d-sm-block">
              {{ $t("save") }}
            </span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
import DateAndHourPicker from "@/components/calendar/DateAndHourPicker.vue";
import NumberField from "@/components/number-field/NumberField.vue";
import tiposPago from "@/enumerates/tipopago";
import extensions from "@/common/file-extensions";
import documentViewerTypes from "@/enumerates/documentviewertypes";

export default {
  name: "socioFormPaymentDialog",
  components: { NumberField, DateAndHourPicker },
  props: {
    content: {
      type: Object,
      default: null,
    },
    isEditingPayment: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      paymentDialog: false,
      currentPayment: null,
      tiposPago,
      extensions,
      documentViewerTypes,
    };
  },
  watch: {
    content(newValue) {
      if (newValue) {
        this.currentPayment = newValue;
        this.paymentDialog = true;
      }
    },
  },
  methods: {
    setPaymentDate(data) {
      this.currentPayment.data.fecha = data.date;
    },
    choosePaymentDocument() {
      this.$refs["paymentFileLoader"].click();
    },
    addPaymentDocument(document) {
      if (!document.target.files[0]) return;
      this.currentPayment.ficheros.push(document.target.files[0]);
    },
    deletePaymentDocument(index) {
      this.currentPayment.ficheros.splice(index, 1);
    },
    closePaymentDialog() {
      this.paymentDialog = false;
    },
    savePayment() {
      if (!this.$refs.paymentForm.validate()) {
        this.$notify({
          type: "error",
          text: this.$t("t_socio.error.form-errors"),
        });
        return;
      }
      this.$emit("save-payment", this.currentPayment);
      this.closePaymentDialog();
    },
    previewDocument(document) {
      this.$emit("preview-document", document);
    },
  },
};
</script>
