<template>
  <v-card flat class="transparent">
    <div class="card-wrapper">
      <v-card-title class="text-center">
        <v-row align="center">
          <v-col class="d-none d-md-block">
            <span class="headline no-split-words">
              {{ $t($route.meta.label) }}
            </span>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-row align="center" justify="center">
            <v-col cols="12" sm="6">
              <v-row>
                <v-text-field
                  class="form-control"
                  v-model="user.currentPassword"
                  name="currentPassword"
                  prepend-inner-icon="vpn_key"
                  :append-icon="
                    hideCurrentPassword ? 'visibility' : 'visibility_off'
                  "
                  :label="$t('account.currentPassword')"
                  :rules="[
                    (v) => !!v || $t('account.passwordRequired'),
                    currentBetweenMinAndMax,
                  ]"
                  :type="hideCurrentPassword ? 'password' : 'text'"
                  @click:append="
                    () => (hideCurrentPassword = !hideCurrentPassword)
                  "
                  required
                ></v-text-field>
              </v-row>
              <v-row class="mt-12">
                <v-text-field
                  class="form-control"
                  v-model="user.newPassword"
                  name="newPassword"
                  prepend-inner-icon="vpn_key"
                  :append-icon="
                    hideNewPassword ? 'visibility' : 'visibility_off'
                  "
                  :label="$t('account.newPassword')"
                  :rules="[
                    (v) => !!v || $t('account.passwordRequired'),
                    newBetweenMinAndMax,
                  ]"
                  :type="hideNewPassword ? 'password' : 'text'"
                  @click:append="() => (hideNewPassword = !hideNewPassword)"
                  required
                  @input="validatePasswordMatch"
                ></v-text-field>
              </v-row>
              <v-row>
                <v-text-field
                  class="form-control"
                  v-model="user.newPassword2"
                  ref="newPassword2"
                  name="newPassword2"
                  prepend-inner-icon="vpn_key"
                  :append-icon="
                    hideNewPassword2 ? 'visibility' : 'visibility_off'
                  "
                  :label="$t('account.repeatPassword')"
                  :rules="[
                    (v) => !!v || $t('account.passwordRequired'),
                    passwordConfirmationRule,
                  ]"
                  :type="hideNewPassword2 ? 'password' : 'text'"
                  @click:append="() => (hideNewPassword2 = !hideNewPassword2)"
                  @keypress.enter="submitNewPassword"
                  required
                  @input="validatePasswordMatch"
                ></v-text-field>
              </v-row>
              <v-row justify="center">
                <v-btn
                  class="primary mt-4"
                  :loading="loading"
                  @click="submitNewPassword"
                >
                  {{ $t("account.resetPassword") }}
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </div>
  </v-card>
</template>

<script>
import RepositoryFactory from "@/repositories/RepositoryFactory";
const AccountRepository = RepositoryFactory.get("AccountRepository");

export default {
  name: "ChangePassword",
  data() {
    return {
      loading: false,
      user: {
        currentPassword: "",
        newPassword: "",
        newPassword2: "",
      },
      hideCurrentPassword: true,
      hideNewPassword: true,
      hideNewPassword2: true,
    };
  },
  computed: {
    passwordConfirmationRule() {
      return () =>
        this.user.newPassword === this.user.newPassword2 ||
        this.$t("account.passwordMatch");
    },
    currentBetweenMinAndMax() {
      return () =>
        (this.user.currentPassword.length > 4 &&
          this.user.currentPassword.length < 100) ||
        this.$t("account.passwordMoreChars");
    },
    newBetweenMinAndMax() {
      return () =>
        (this.user.newPassword.length > 4 &&
          this.user.newPassword.length < 100) ||
        this.$t("account.passwordMoreChars");
    },
  },
  methods: {
    validatePasswordMatch() {
      if (this.user.newPassword2 !== "") {
        if (this.user.newPassword !== this.user.newPassword2) {
          this.$refs.newPassword2.validate(true);
        } else {
          this.$refs.newPassword2.validate(false);
        }
      }
    },
    submitNewPassword() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        AccountRepository.changePassword(
          this.user.currentPassword,
          this.user.newPassword
        )
          .then((res) => {
            if (!res.isAxiosError) {
              this.$notify({
                title: this.$t("account.changePassword"),
                text: this.$t("account.passwordChanged"),
                type: "success",
              });
            }
            this.$router.back();
          })
          .finally(() => (this.loading = false));
      }
    },
  },
};
</script>
