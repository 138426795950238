var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"transparent",attrs:{"flat":""}},[_c('v-card-title',{staticClass:"pb-0 pt-0 pt-md-4"},[_c('v-row',{attrs:{"align":"center","no-gutters":""}},[_c('v-col',{staticClass:"d-none d-md-block",attrs:{"cols":"6"}},[_c('span',{staticClass:"headline no-split-words"},[_vm._v(" "+_vm._s(_vm.$t(_vm.$route.meta.label))+" ")])])],1)],1),_c('v-card-text',{staticClass:"mt-6"},[_c('v-form',{ref:"form"},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-row',[_c('v-autocomplete',{staticClass:"required",attrs:{"dense":"","prepend-inner-icon":"mdi-id-card","loading":_vm.partnersLoading,"items":_vm.partnersDNI,"item-text":"fullName","label":_vm.$t('t_socio.prop.dni'),"menu-props":{ offsetY: true },"return-object":"","rules":[(v) => !!v || _vm.$t('error.required')]},model:{value:(_vm.user),callback:function ($$v) {_vm.user=$$v},expression:"user"}})],1),_c('v-row',[_c('v-text-field',{staticClass:"form-control required",attrs:{"name":"newPassword","disabled":!_vm.user.dni,"prepend-inner-icon":"vpn_key","append-icon":_vm.hideNewPassword ? 'visibility' : 'visibility_off',"label":_vm.$t('account.newPassword'),"rules":[
                (v) => !!v || _vm.$t('account.passwordRequired'),
                _vm.newBetweenMinAndMax,
              ],"type":_vm.hideNewPassword ? 'password' : 'text',"required":""},on:{"click:append":() => (_vm.hideNewPassword = !_vm.hideNewPassword),"input":_vm.validatePasswordMatch},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}})],1),_c('v-row',[_c('v-text-field',{ref:"newPassword2",staticClass:"form-control required",attrs:{"name":"newPassword2","disabled":!_vm.user.dni,"prepend-inner-icon":"vpn_key","append-icon":_vm.hideNewPassword2 ? 'visibility' : 'visibility_off',"label":_vm.$t('account.repeatPassword'),"rules":[
                (v) => !!v || _vm.$t('account.passwordRequired'),
                _vm.passwordConfirmationRule,
              ],"type":_vm.hideNewPassword2 ? 'password' : 'text',"required":""},on:{"click:append":() => (_vm.hideNewPassword2 = !_vm.hideNewPassword2),"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.submitNewPassword.apply(null, arguments)},"input":_vm.validatePasswordMatch},model:{value:(_vm.newPassword2),callback:function ($$v) {_vm.newPassword2=$$v},expression:"newPassword2"}})],1)],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-btn',{staticClass:"success mt-4",attrs:{"loading":_vm.loading,"disabled":!_vm.user.dni},on:{"click":_vm.submitNewPassword}},[_vm._v(" "+_vm._s(_vm.$t("account.resetPassword"))+" ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }