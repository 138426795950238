<template>
  <v-card flat class="transparent">
    <v-card-title>
      <v-row align="center" justify="space-between" no-gutters>
        <v-col class="d-none d-md-block col-auto">
          <span class="headline no-split-words">
            {{ $t($route.meta.label) }}
          </span>
        </v-col>
        <v-col cols="12" order="1" order-md="2" class="text-right col-md-auto">
          <v-btn
            class="ml-2"
            :disabled="
              !validForm ||
              (selectedSocios.length === 0 && selectedGrupos.length === 0) ||
              emailBody === '' ||
              lengthExceeded
            "
            @click="previewEmail"
          >
            <v-icon class="mr-1">preview</v-icon>
            <span class="d-none d-sm-block"> {{ $t("preview") }} </span>
          </v-btn>
          <v-btn
            color="success"
            class="ml-2"
            :loading="loading"
            :disabled="
              !validForm ||
              (selectedSocios.length === 0 && selectedGrupos.length === 0) ||
              emailBody === '' ||
              lengthExceeded
            "
            @click="sendEmail"
          >
            <v-icon class="mr-1">send</v-icon>
            <span class="d-none d-sm-block"> {{ $t("send") }} </span>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="validForm">
        <v-row justify="center">
          <v-col cols="12" md="4">
            <v-row dense>
              <v-col>
                <v-tabs grow background-color="transparent">
                  <v-tab @change="partnersSelected">{{
                    $t("t_socio.headers.socioList")
                  }}</v-tab>
                  <v-tab @change="groupsSelected">{{
                    $t("t_grupo.headers.grupos")
                  }}</v-tab>
                  <v-tab-item class="mt-4">
                    <v-data-table
                      height="calc(100vh - 370px)"
                      class="transparent"
                      dense
                      show-select
                      hide-default-footer
                      disable-sort
                      v-model="selectedSocios"
                      :headers="headersSocio"
                      :items="socios"
                      :items-per-page="-1"
                      :loading="sociosLoading"
                      :search="search"
                    >
                      <template v-slot:top>
                        <v-text-field
                          v-model="search"
                          append-icon="search"
                          dense
                          :label="$t('search')"
                        ></v-text-field>
                      </template>
                    </v-data-table>
                  </v-tab-item>
                  <v-tab-item class="mt-4">
                    <v-data-table
                      height="calc(100vh - 370px)"
                      class="transparent"
                      dense
                      show-select
                      hide-default-footer
                      disable-sort
                      v-model="selectedGrupos"
                      :headers="headersGrupo"
                      :items="grupos"
                      :items-per-page="-1"
                      :loading="gruposLoading"
                      :search="search"
                    >
                      <template v-slot:top>
                        <v-text-field
                          v-model="search"
                          append-icon="search"
                          dense
                          :label="$t('search')"
                        ></v-text-field>
                      </template>
                    </v-data-table>
                  </v-tab-item>
                </v-tabs>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="8">
            <v-card>
              <v-card-text class="px-8">
                <v-row>
                  <v-row class="mt-1">
                    <v-col>
                      <span>
                        {{ $t("t_email.field.recipients") }}
                      </span>
                    </v-col>
                  </v-row>
                  <v-col cols="12" class="pt-0 px-0">
                    <v-chip-group
                      v-if="selectedSocios.length > 0 && !isGruposSelected"
                    >
                      <v-chip
                        v-for="(user, index) in selectedSocios"
                        :key="index"
                        close
                        class="mr-2"
                        @click:close="selectedSocios.splice(index, 1)"
                      >
                        {{ user.nombre + " " + user.apellidos }}
                      </v-chip>
                    </v-chip-group>
                    <v-chip-group
                      v-else-if="selectedGrupos.length > 0 && isGruposSelected"
                    >
                      <v-chip
                        v-for="(grupo, index) in selectedGrupos"
                        :key="index"
                        close
                        class="mr-2"
                        @click="showGrupoDialog(grupo)"
                        @click:close="selectedGrupos.splice(index, 1)"
                      >
                        <v-icon left> group </v-icon>
                        {{ grupo.name }}
                      </v-chip>
                    </v-chip-group>
                    <span v-else>—</span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="pt-0 px-0">
                    <v-text-field
                      dense
                      v-model="emailSubject"
                      class="mt-3"
                      :label="$t('t_email.field.subject')"
                      :rules="[(v) => !!v || $t('error.required')]"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-row>
                    <v-col>
                      <div>
                        {{ $t("t_email.field.message") }}
                      </div>
                    </v-col>
                  </v-row>
                  <v-col cols="12" class="pt-2 px-0">
                    <ckeditor
                      v-model="emailBody"
                      :editor="editor"
                      :config="editorConfig"
                      @input="updateBody"
                    >
                    </ckeditor>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>

    <group-dialog :grupo="currentGrupo"></group-dialog>

    <email-preview
      :recipients="getRecipients()"
      :subject="emailSubject"
      :content="currentEmail"
    ></email-preview>
  </v-card>
</template>

<script>
import Vue from "vue";

import RepositoryFactory from "@/repositories/RepositoryFactory";
import ClassicEditor from "@/plugins/ckeditor";
import { editorConfig } from "@/common/ckeditor-config";
import EmailPreview from "@/modules/email/components/emailPreview.vue";
import GroupDialog from "@/modules/email/components/groupDialog.vue";

const SocioEntityRepository = RepositoryFactory.get("SocioEntityRepository");
const GrupoEntityRepository = RepositoryFactory.get("GrupoEntityRepository");
const EmailEntityRepository = RepositoryFactory.get("EmailEntityRepository");

export default {
  name: "emailForm",
  components: { GroupDialog, EmailPreview },
  data() {
    return {
      search: "",
      loading: false,
      socios: [],
      selectedSocios: [],
      sociosLoading: false,
      grupos: [],
      selectedGrupos: [],
      gruposLoading: false,
      isGruposSelected: false,
      currentGrupo: null,
      validForm: true,
      editor: ClassicEditor,
      emailSubject: "",
      emailBody: "",
      editorConfig: editorConfig,
      lengthExceeded: false,
      currentEmail: null,
    };
  },
  computed: {
    headersSocio() {
      return [
        {
          text: this.$t("t_socio.prop.apellidos"),
          value: "apellidos",
        },
        {
          text: this.$t("t_socio.prop.nombre"),
          value: "nombre",
        },
      ];
    },
    headersGrupo() {
      return [
        {
          text: this.$t("t_grupo.prop.name"),
          value: "name",
        },
      ];
    },
  },
  created() {
    this.getPartners();
    this.getGroups();
  },
  methods: {
    getPartners() {
      this.sociosLoading = true;
      SocioEntityRepository.getAllWithAuthorities({
        params: { getPartners: true },
      })
        .then((res) => {
          res.sort((a, b) => a.apellidos.localeCompare(b.apellidos));
          this.socios = res;
        })
        .finally(() => (this.sociosLoading = false));
    },
    getGroups() {
      this.gruposLoading = true;
      GrupoEntityRepository.getAll()
        .then((res) => {
          res.content.sort((a, b) => a.name.localeCompare(b.name));
          this.grupos = res.content;
        })
        .finally(() => (this.gruposLoading = false));
    },
    groupsSelected() {
      this.selectedSocios = [];
      this.search = "";
      this.isGruposSelected = true;
    },
    partnersSelected() {
      this.selectedGrupos = [];
      this.search = "";
      this.isGruposSelected = false;
    },
    updateBody(val) {
      this.lengthExceeded = val.length > 10485760;
    },
    getRecipients() {
      let recipients = [];
      if (this.isGruposSelected) {
        this.selectedGrupos.forEach((grupo) => {
          grupo.socios.forEach((socio) => {
            if (!recipients.some((recipient) => recipient.id === socio.id)) {
              recipients.push(socio);
            }
          });
        });
      } else {
        recipients = this.selectedSocios;
      }
      return recipients;
    },
    showGrupoDialog(grupo) {
      this.currentGrupo = JSON.parse(JSON.stringify(grupo));
    },
    previewEmail() {
      if (!this.validForm) {
        this.$notify({
          type: "error",
          text: this.$t("t_email.error.form-errors"),
        });
        return;
      }
      this.currentEmail = EmailEntityRepository.previewEmail({
        recipients: this.getRecipients(),
        subject: this.emailSubject,
        body: this.emailBody,
      });
    },
    sendEmail() {
      if (!this.validForm) {
        this.$notify({
          type: "error",
          text: this.$t("t_email.error.form-errors"),
        });
        return;
      }
      this.loading = true;
      EmailEntityRepository.sendEmail({
        recipients: this.getRecipients(),
        subject: this.emailSubject,
        body: this.emailBody,
      })
        .then(() => {
          this.$notify({
            type: "success",
            text: this.$t("t_email.messages.send-success"),
          });
          this.clearForm();
        })
        .finally(() => (this.loading = false));
    },
    clearForm() {
      this.selectAllSocios = false;
      this.search = "";
      this.selectedSocios = [];
      this.emailSubject = "";
      this.emailBody = "";
      this.$refs.form.resetValidation();
    },
  },
};
</script>
