import Static_PageForm from "../Static_PageForm";
import Static_PageDetail from "../Static_PageDetail";

const routes = [
  {
    path: "/statics/:id/edit",
    name: "Static_PageForm",
    component: Static_PageForm,
    meta: {
      authority: ["ROLE_ADMIN", "SP_PROJECT_ASSOCIATION", "SP_FINANCES"],
      label: "static_editor.staticPageForm",
      labelArguments: (route) => ({ id: route.params.id }),
    },
  },
  {
    path: "/",
    name: "Presentación",
    component: Static_PageDetail,
    meta: {
      public: true,
    },
  },
  {
    path: "/instalacions",
    name: "Instalacións",
    component: Static_PageDetail,
    meta: {
      public: true,
      label: "menu.project.facilities",
    },
  },
  {
    path: "/servizos",
    name: "Servizos",
    component: Static_PageDetail,
    meta: {
      public: true,
      label: "menu.project.services",
    },
  },
  {
    path: "/ideario",
    name: "Ideario",
    component: Static_PageDetail,
    meta: {
      public: true,
      label: "menu.project.ideology",
    },
  },
  {
    path: "/xestion-de-idades",
    name: "Xestión de idades",
    component: Static_PageDetail,
    meta: {
      public: true,
      label: "menu.association.age_management",
    },
  },
  {
    path: "/como-asociarse",
    name: "Como asociarse",
    component: Static_PageDetail,
    meta: {
      public: true,
      label: "menu.association.associate",
    },
  },
  {
    path: "/organizacion",
    name: "Organización",
    component: Static_PageDetail,
    meta: {
      public: true,
      label: "menu.association.organization",
    },
  },
  {
    path: "/contactar",
    name: "Contactar",
    component: Static_PageDetail,
    meta: {
      public: true,
      label: "menu.association.contact",
    },
  },
  {
    path: "/prezos",
    name: "Prezos",
    component: Static_PageDetail,
    meta: {
      public: true,
      label: "menu.finances.prices",
    },
  },
  {
    path: "/contabilidade",
    name: "Contabilidade",
    component: Static_PageDetail,
    meta: {
      userIsLogged: true,
      label: "menu.finances.accounting",
    },
  },
];

export default routes;
