<template>
  <div>
    <v-row class="mb-2">
      <v-col>
        <v-btn
          v-if="isAdmin || isPartnerManagement"
          color="primary"
          @click="addPaymentDialog()"
        >
          <v-icon>mdi-cash-plus</v-icon>
          <span class="d-none d-sm-block">
            {{ $t("t_pago.actions.add") }}
          </span>
        </v-btn>
      </v-col>
    </v-row>
    <div v-if="pagos && pagos.length > 0">
      <v-expansion-panels accordion>
        <v-expansion-panel v-for="(pago, i) in pagos" :key="i">
          <v-expansion-panel-header class="text--secondary">
            <v-row dense no-gutters align="center">
              <v-col cols="12" md="4">
                <v-row>
                  <v-col cols="6" md="3" class="text-left font-weight-bold">
                    {{ $t("t_pago.prop.tipo") }}:
                  </v-col>
                  <v-col cols="6" md="9">
                    {{ $t(`tipopago.${pago.data.tipo}`) }}
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="4" class="mt-2 mt-md-0">
                <v-row>
                  <v-col cols="6" md="3" class="text-left font-weight-bold">
                    {{ $t("t_pago.prop.importe") }}:
                  </v-col>
                  <v-col cols="6" md="9"> {{ pago.data.importe }}€ </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="3" class="mt-2 mt-md-0">
                <v-row>
                  <v-col cols="6" md="3" class="text-left font-weight-bold">
                    {{ $t("t_pago.prop.fecha") }}:
                  </v-col>
                  <v-col cols="6" md="9">
                    {{ pago.data.fecha | dateFormat }}
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="1" class="mt-2 mt-md-0">
                <v-tooltip v-if="isAdmin || isPartnerManagement" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      color="orange"
                      @click="() => editPaymentDialog(i)"
                    >
                      edit
                    </v-icon>
                  </template>
                  <span>{{ $t("edit") }}</span>
                </v-tooltip>
                <v-tooltip v-if="isAdmin || isPartnerManagement" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      color="error"
                      @click="() => showDeleteDialog(i)"
                    >
                      delete
                    </v-icon>
                  </template>
                  <span>{{ $t("delete") }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="text--secondary">
            <span
              v-if="pago.data.documentos && pago.data.documentos.length > 0"
            >
              <div v-for="(doc, i) in pago.ficheros" :key="i">
                <span style="display: inline-block; width: 80px">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        @click="downloadDocument(doc)"
                        v-bind="attrs"
                        v-on="on"
                        ><v-icon color="primary">mdi-download</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t("download_file") }}</span>
                  </v-tooltip>
                  <v-tooltip v-if="documentViewerTypes.includes(doc.type)" top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        @click="previewDocument(doc)"
                        v-bind="attrs"
                        v-on="on"
                        ><v-icon color="primary">mdi-eye</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t("preview_file") }}</span>
                  </v-tooltip>
                </span>
                <span
                  v-if="documentViewerTypes.includes(doc.type)"
                  class="link"
                  @click="previewDocument(doc)"
                  >{{ doc.name }}</span
                >
                <span v-else class="link" @click="downloadDocument(doc)">{{
                  doc.name
                }}</span>
              </div>
            </span>
            <span v-else>{{ $t("t_pago.headers.no_documentos") }}</span>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <span v-else>{{ $t("t_socio.headers.no_pagos") }}</span>
    <socio-form-payment-dialog
      :content="currentPayment"
      :is-editing-payment="isEditingPayment"
      @save-payment="savePayment"
      @preview-document="previewDocument"
    ></socio-form-payment-dialog>
    <delete-dialog
      v-if="deleteDialog"
      :dialog="deleteDialog"
      @cancel="deleteDialog = false"
      @submit="deleteFunction"
    ></delete-dialog>
  </div>
</template>

<script>
import DeleteDialog from "@/components/modal_dialog/DeleteDialog.vue";
import { uploadFile } from "@/common/file-utils";
import { getTodayDateAsArray } from "@/common/conversion-utils";
import SocioFormPaymentDialog from "@/modules/socio/components/socioFormPaymentDialog.vue";
import documentViewerTypes from "@/enumerates/documentviewertypes";
import { mapAuthGetter } from "@/common/mapAuthGetter";

export default {
  name: "socioFormPayment",
  components: { SocioFormPaymentDialog, DeleteDialog },
  props: {
    content: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      pagos: [],
      currentPayment: null,
      isEditingPayment: false,
      deleteDialog: false,
      deleteFunction: null,
      documentViewerTypes,
    };
  },
  created() {
    if (this.content) {
      this.pagos = this.content;
    }
  },
  computed: {
    ...mapAuthGetter(["isAdmin", "isPartnerManagement"]),
  },
  methods: {
    savePayment(payment) {
      this.currentPayment = payment;
      try {
        if (this.currentPayment) {
          // Upload documents
          this.currentPayment.data.documentos = [];
          this.currentPayment.ficheros.forEach((fichero) => {
            uploadFile(fichero).then((response) => {
              this.currentPayment.data.documentos.push(response);
            });
          });
          // Update payments array
          if (this.isEditingPayment) {
            this.pagos[this.currentPaymentIndex] = this.currentPayment;
          } else {
            this.pagos.push(this.currentPayment);
          }
        }
      } catch (e) {
        console.error(e);
        if (this.isEditingPayment) {
          this.pagos[this.currentPaymentIndex].data.documentos = [];
        }
      }

      // Sort payments by descending date
      this.pagos.sort((a, b) => {
        const dateA = new Date(a.data.fecha);
        const dateB = new Date(b.data.fecha);
        return dateB - dateA;
      });
      this.$emit("update-payments", this.pagos);
    },
    addPaymentDialog() {
      this.currentPayment = {
        ficheros: [],
        data: {
          tipo: null,
          importe: null,
          fecha: getTodayDateAsArray(),
          documentos: [],
        },
      };
      this.isEditingPayment = false;
    },
    editPaymentDialog(index) {
      this.currentPayment = structuredClone(this.pagos[index]);
      this.currentPaymentIndex = index;
      this.isEditingPayment = true;
    },
    deletePayment(index) {
      this.pagos.splice(index, 1);
      this.closeDeleteDialog();
    },
    showDeleteDialog(index) {
      this.deleteFunction = () => this.deletePayment(index);
      this.deleteDialog = true;
    },
    closeDeleteDialog() {
      this.deleteDialog = false;
    },
    previewDocument(file) {
      this.$emit("preview-document", file);
    },
    downloadDocument(file) {
      this.$emit("download-document", file);
    },
  },
};
</script>
